import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Outlet } from "react-router-dom"
import type { RouteObject } from "react-router-dom"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { LimitedAccessUserGuard } from "@/components/route/limited-access-user-guard"
import { AssistantPath, RootPath } from "@/models/route"

import { AssistantIndexRoute } from "./pages/assistant-index-route"

export const assistantRoutes: RouteObject[] = [
  {
    path: RootPath.Assistant,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}
      >
        <LimitedAccessUserGuard>
          <Outlet />
        </LimitedAccessUserGuard>
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <AssistantIndexRoute />,
      },
      {
        path: AssistantPath.Thread,
        element: <AssistantIndexRoute />,
      },
    ],
  },
]
