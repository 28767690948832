import type { Schemas } from "@/services/types"
import { useMutation } from "@tanstack/react-query"
import type { UseMutationOptions } from "@tanstack/react-query"

import type { UseUploadMutationVariables } from "../services.types"

type UseUploadUsageOptions = Omit<
  UseMutationOptions<unknown, unknown, UseUploadMutationVariables>,
  "mutationFn"
>

const uploadUsage = async ({ file }: UseUploadMutationVariables) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.warn("Upload usage not implemented: ", file)

      if (Math.random() < 0.5) {
        reject([
          {
            column: "C",
            description: "Invalid zip code",
            row: 1,
            value: "1234a",
          },
        ])
      } else {
        resolve({ success: true })
      }
    }, 1000)
  })
}

/**
 * Uploads a CSV file of usage data
 *
 * @example
 * const {
 *   error,
 *   isError,
 *   isLoading,
 *   mutateAsync
 * } = useUploadUsage(options)
 *
 * await mutateAsync(file)
 */
export const useUploadUsage = (options: UseUploadUsageOptions = {}) => {
  const mutationOptions = options ?? {}

  const mutation = useMutation<
    unknown,
    Schemas["EmissionProfileUploadError"][],
    UseUploadMutationVariables
  >(uploadUsage, mutationOptions)

  return mutation
}
