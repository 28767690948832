import React, { useCallback, useEffect, useMemo } from "react"

import { HStack } from "@/nzds/layout"
import { useLocales } from "@/services/locale/useLocales"
import { sortBy } from "lodash-es"

import { Box, Grid, Link, Paper, Stack } from "@mui/material"

import { useOptions } from "../../../../../../components/form/useOptions"
import { If } from "../../../../../../components/if/if"
import type {
  OrganizationCounts,
  Organization as OrganizationModel,
} from "../../../../../../services"
import {
  useOrganization,
  useOrganizationCounts,
  useUpdateOrganization,
} from "../../../../../../services"
import { lightGray } from "../../../../../../utils/colors"
import { getDataE2EAttribute } from "../../../../../../utils/e2e"
import { useIsFlexibleHierarchyEnabled } from "../../../../hooks/useIsFlexibleHierarchyEnabled/useIsFlexibleHierarchyEnabled"
import type { IOrgViewDisplayCount } from "../../../../models/onboarding/onboarding"
import { organizationDisplayCounts } from "../../../../models/onboarding/onboarding"
import { convertToDisplayCounts } from "../../../../util/onboarding/onboarding"
import {
  TamForm,
  TamResetButton,
  TamSubmitButton,
  useTamForm,
} from "../../../tamForm"
import {
  TamFormSelectField,
  TamFormTextField,
} from "../../../tamForm/formFields"
import type { Organization } from "../../../tamForm/schemas/organization"
import { schema } from "../../../tamForm/schemas/organization"
import OrgViewDetailFooter from "../orgViewDetail/orgViewDetailFooter/orgViewDetailFooter"

interface OrgViewOrganizationDetailProps {
  organizationId: OrganizationModel["id"]
}

const OrgViewOrganizationDetail = ({
  organizationId,
}: OrgViewOrganizationDetailProps) => {
  const { organization, isOrganizationLoading } =
    useOrganization(organizationId)
  const {
    updateOneOrganization,
    isUpdateOneOrganizationLoading,
    isUpdateOneOrganizationSuccess,
  } = useUpdateOrganization()
  const { organizationCounts, isOrganizationCountsLoading } =
    useOrganizationCounts(organizationId)

  const { isFlexibleHierarchyEnabled } = useIsFlexibleHierarchyEnabled()

  // TODO: Remove once flexible hierarchy is fully enabled
  if (isFlexibleHierarchyEnabled) {
    delete organizationCounts?.departments
  } else {
    delete organizationCounts?.organizationalUnits
  }

  const currentOrgDisplayCounts: IOrgViewDisplayCount<OrganizationCounts>[] =
    useMemo(
      () =>
        sortBy(
          convertToDisplayCounts<OrganizationCounts>(
            organizationCounts,
            organizationDisplayCounts
          ),
          "order"
        ),
      [organizationCounts]
    )

  const handleFormSubmit = useCallback(
    (newAttributes) => {
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      updateOneOrganization(organizationId, newAttributes)
    },
    [organizationId, updateOneOrganization]
  )

  const { locales, isLocalesLoading } = useLocales()
  const localeOptions = useOptions(locales, ({ name, id }) => ({
    label: name,
    value: id,
  }))

  const form = useTamForm({ schema, values: organization })

  useEffect(() => {
    if (isUpdateOneOrganizationSuccess) {
      form.reset({}, { keepValues: true })
    }
  }, [form, isUpdateOneOrganizationSuccess])

  if (
    isOrganizationLoading ||
    isOrganizationCountsLoading ||
    isLocalesLoading
  ) {
    return null
  }

  return (
    <TamForm form={form} name="organization" onSubmit={handleFormSubmit}>
      <HStack
        alignItems="flex-start"
        flexGrow={1}
        gap={0.5}
        sx={{
          overflowY: "auto",
          py: 1,
          pr: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<Organization>
                dataE2E="organization-name-input"
                label="Organization Name"
                name="name"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<Organization>
                dataE2E="organization-id-input"
                disabled
                label="ID"
                name="id"
                readOnly
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormSelectField<Organization>
                dataE2E="organization-locale-input"
                label="Locale"
                name="localeId"
                options={localeOptions}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={9}>
              <Paper
                sx={{
                  mt: 1,
                  mb: 0.5,
                }}
                variant="outlined"
              >
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: "wrap",
                  }}
                >
                  {currentOrgDisplayCounts.map((organizationCount) => (
                    <Box
                      key={organizationCount.key}
                      component="span"
                      sx={{
                        borderBottom: `1px solid ${lightGray.toString()}`,
                        paddingX: 1.5,
                        paddingY: 0.5,
                        width: "50%",
                        "&:nth-of-type(odd)": {
                          borderRight: `1px solid ${lightGray.toString()}`,
                        },
                        "&:last-of-type, &:nth-last-of-type(2):nth-of-type(odd)":
                          {
                            borderBottom: "0 none",
                          },
                      }}
                    >
                      <If condition={organizationCount.href}>
                        <Link
                          data-e2e={`${getDataE2EAttribute(
                            organizationCount?.name
                          )}-link`}
                          href={`${organizationCount.href}${organizationId}`}
                        >
                          {organizationCount.name}{" "}
                          {Number.isFinite(organizationCount.value) &&
                            `(${organizationCount.value})`}
                        </Link>
                      </If>
                      <If condition={!organizationCount.href}>
                        {organizationCount.name}{" "}
                        {Number.isFinite(organizationCount.value) &&
                          `(${organizationCount.value})`}
                      </If>
                    </Box>
                  ))}
                </Stack>
              </Paper>
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<Organization> label="Notes" name="notes" />
            </Grid>
          </Grid>
        </Grid>
      </HStack>
      <OrgViewDetailFooter>
        <TamResetButton dataE2E="organization-reset-button" />
        <TamSubmitButton
          dataE2E="organization-submit-button"
          submitting={isUpdateOneOrganizationLoading}
        />
      </OrgViewDetailFooter>
    </TamForm>
  )
}

export default OrgViewOrganizationDetail
