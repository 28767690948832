import React, { useState } from "react"
import type { FC, ReactNode } from "react"

import { If } from "@/components/if/if"
import { Page } from "@/components/page"
import { PageCard } from "@/components/page-card"
import { useOrganizationContext } from "@/contexts"
import { FONT_WEIGHT } from "@/models/typography"
import { HStack } from "@/nzds/layout"
import { useEntities } from "@/services/entities"
import { FeatureFlags, useFeature } from "@/services/feature"

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import {
  Box,
  Button,
  Divider,
  Fade,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material"

import { UploadStep } from "../../components/upload-step"
import { useUploadAdditionalUsage } from "../../services/use-upload-additional-usage"
import { useUploadEntities } from "../../services/use-upload-entities"
import { useUploadUsage } from "../../services/use-upload-usage"

interface UploadStep {
  content: ReactNode
  name: string
}

export const DataUploadRoute: FC = () => {
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const isDataUploadEnabled = isFeatureEnabled(
    FeatureFlags.DATA_UPLOAD,
    organization
  )

  const { entitiesHasData, entitiesIsFetched } = useEntities()
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)
  const isUsageAndEmissionsEnabled = entitiesIsFetched && entitiesHasData

  const steps: UploadStep[] = [
    {
      name: "Entities",
      content: (
        <UploadStep
          downloadTemplateButton={
            <Button
              color="secondary"
              component="a"
              download
              href="https://docs.google.com/spreadsheets/d/1sq7t8OovsIK3COyGyXI1dSnQdmYtqiuaiTuddHIz_Ig/export?format=xlsx"
              size="small"
              startIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
            >
              Download Entities Template
            </Button>
          }
          downloadTemplateDescription="Download our Excel template and enter your entities (sites, meters, etc.)"
          successMessage="Your entities have been created."
          type="entities"
          uploadDescription="Export your completed entities template to a CSV and upload it"
          uploadQuery={useUploadEntities}
        />
      ),
    },
    {
      name: "Usage",
      content: (
        <UploadStep
          downloadTemplateButton={
            <Button
              color="secondary"
              component="a"
              disabled={!isUsageAndEmissionsEnabled}
              download
              href="https://docs.google.com/spreadsheets/d/1lt8vPcL52BAQQOgbep8pcHi7vSdNe1WR31Np24l0Q2k/export?format=xlsx"
              size="small"
              startIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
            >
              Download Usage Template
            </Button>
          }
          downloadTemplateDescription="Download our Excel template and enter your usage"
          successMessage="Your usage data will be processed over night. Check back tomorrow."
          type="usage"
          uploadDescription="Export your completed usage template to a CSV and upload it"
          uploadQuery={useUploadUsage}
        />
      ),
    },
    {
      name: "Additional Usage",
      content: (
        <UploadStep
          downloadTemplateButton={
            <Button
              color="secondary"
              component="a"
              disabled={!isUsageAndEmissionsEnabled}
              download
              href="https://docs.google.com/spreadsheets/d/1lt8vPcL52BAQQOgbep8pcHi7vSdNe1WR31Np24l0Q2k/export?format=xlsx"
              size="small"
              startIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
            >
              Additional Usage Template
            </Button>
          }
          downloadTemplateDescription="Download our Excel template and enter your additional usage"
          successMessage="Your additional usage data will be processed over night. Check back tomorrow."
          type="additional usage"
          uploadDescription="Export your completed additional usage template to a CSV and upload it"
          uploadQuery={useUploadAdditionalUsage}
        />
      ),
    },
  ]

  const handleBackClick = (): void => {
    setActiveStepIndex((prevActiveStepIndex) => prevActiveStepIndex - 1)
  }

  const handleNextClick = (): void => {
    setActiveStepIndex((prevActiveStepIndex) => prevActiveStepIndex + 1)
  }

  const isStepEnabled = (step: UploadStep): boolean => {
    return steps.indexOf(step) === 0 || isUsageAndEmissionsEnabled
  }

  return isDataUploadEnabled ? (
    <Page>
      <PageCard>
        <Stack gap={0.5} justifyContent="center">
          <Typography fontWeight={FONT_WEIGHT.bold} variant="h2">
            Upload your data, gain insights
          </Typography>
          <Typography>
            Navigate through a step-by-step workflow to unlock the power of
            NZeroOS.
          </Typography>
        </Stack>
        <Divider sx={(theme) => ({ margin: `${theme.spacing(2)} 0` })} />
        <Box sx={{ maxWidth: 640 }}>
          <Stepper activeStep={activeStepIndex} sx={{ mb: 4, mt: 3 }}>
            {steps.map((step) => (
              <Step key={step.name} completed={false}>
                <StepButton
                  disabled={!isStepEnabled(step)}
                  disableRipple={true}
                  onClick={() => {
                    setActiveStepIndex(steps.indexOf(step))
                  }}
                >
                  {step.name}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Box mx={2}>
            {steps.map((step, index) => {
              return (
                <If key={step.name} condition={activeStepIndex === index}>
                  <div>{step.content}</div>
                </If>
              )
            })}
            <HStack gap={2} justifyContent="flex-end" mt={2}>
              <If condition={activeStepIndex !== 0}>
                <Fade in={true}>
                  <Button
                    disabled={!isUsageAndEmissionsEnabled}
                    onClick={handleBackClick}
                    variant="outlined"
                  >
                    Back
                  </Button>
                </Fade>
              </If>
              <If condition={activeStepIndex !== steps.length - 1}>
                <Fade in={true}>
                  <Button
                    disabled={!isUsageAndEmissionsEnabled}
                    onClick={handleNextClick}
                    variant="contained"
                  >
                    Next
                  </Button>
                </Fade>
              </If>
            </HStack>
          </Box>
        </Box>
      </PageCard>
    </Page>
  ) : (
    <Page>
      <PageCard>
        <Box mx="auto" my={12} sx={{ maxWidth: 480, textAlign: "center" }}>
          <Typography fontWeight={FONT_WEIGHT.bold} mb={3} variant="h2">
            Self service data uploads are coming soon!
          </Typography>
          <Typography>
            You&apos;ll be able to upload your building data, resource
            consumption, and emissions with the click of a button.
          </Typography>
        </Box>
      </PageCard>
    </Page>
  )
}
