import { createQueryKey } from "@/services/utils/createQueryKey"
import { useInvalidateQueries } from "@/services/utils/useInvalidateQueries"
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useMutation } from "@tanstack/react-query"

import { ApiQueryName } from "../../../../models/api"
import type OrganizationalUnit from "../../../../models/organizationalUnit"

/**
 * Hook that exposes a mutation function for updating multiple org units at once.
 * This ensures that queries for org units and organizational structure are not
 * invalidated until all org units have been updated.
 */
export const useBatchUpdateOrgUnits = () => {
  const mutationKey = useQueryKey(ApiQueryName.Site, "updateOne")
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({ orgUnits }: { orgUnits: OrganizationalUnit[] }) => {
      const promises: Promise<boolean>[] = []

      orgUnits.forEach((orgUnit) => {
        promises.push(orgUnit.save())
      })

      await Promise.all(promises).catch((e) => {
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        console.error(e.response)
      })
    },
    onSuccess: () => {
      invalidateQueries([
        createQueryKey(ApiQueryName.OrganizationalUnits, "getMany"),
        createQueryKey(ApiQueryName.OrganizationHierarchy, "getOne"),
      ])
    },
  })

  return mutationResult
}
