import React, { useCallback } from "react"
import type { FC } from "react"

import { HStack } from "@/nzds/layout"

import AddIcon from "@mui/icons-material/Add"
import CheckIcon from "@mui/icons-material/Check"
import { ToggleButton, Typography, styled } from "@mui/material"

import { getDataE2EAttribute } from "../../utils/e2e"
import { If } from "../if/if"

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.action.active}`,
  borderRadius: theme.spacing(2),
  height: theme.spacing(4),
  paddingLeft: theme.spacing(1),

  "&.Mui-selected": {
    border: "1px solid transparent !important",
  },

  "& .MuiSvgIcon-root": {
    color: theme.palette.action.active,
  },
}))

export interface IToggleButtonItem {
  id: string
  name: string
}

interface ToggleButtonListProps {
  items: IToggleButtonItem[]
  labelledBy: string
  onChange: (value: string[]) => void
  value: string[] | null
}

export const ToggleButtonList: FC<ToggleButtonListProps> = ({
  items,
  value,
  onChange,
  labelledBy,
}) => {
  const isSelected = (itemValue: string): boolean =>
    !!value?.includes(itemValue)

  const onButtonClick = useCallback(
    (itemValue: string): void => {
      const newValue: string[] = Array.isArray(value) ? [...value] : []
      const isItemSelected: boolean = newValue.includes(itemValue)

      if (!isItemSelected) {
        newValue.push(itemValue)
      } else {
        newValue.splice(newValue.indexOf(itemValue), 1)
      }

      onChange(newValue)
    },
    [onChange, value]
  )

  return (
    <HStack aria-labelledby={labelledBy} flexWrap="wrap" gap={1}>
      {items.map((item) => (
        <StyledToggleButton
          data-e2e={`toggle-button-${getDataE2EAttribute(item.name)}`}
          key={item.id}
          onChange={() => {
            onButtonClick(item.id)
          }}
          selected={isSelected(item.id)}
          value={item.id}
        >
          <HStack alignItems="center" gap={0.5}>
            <If condition={isSelected(item.id)}>
              <CheckIcon fontSize="small" />
            </If>
            <If condition={!isSelected(item.id)}>
              <AddIcon fontSize="small" />
            </If>
            <Typography>{item.name}</Typography>
          </HStack>
        </StyledToggleButton>
      ))}
    </HStack>
  )
}
