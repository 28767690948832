/* eslint-disable @typescript-eslint/unbound-method */
import type React from "react"
import { useCallback, useId, useState } from "react"

import { useCallbackRef } from "../use-callback-ref"

export interface UseDisclosureProps {
  defaultIsOpen?: boolean
  id?: string
  isOpen?: boolean
  onClose?: () => void
  onOpen?: () => void
}

type HTMLProps = React.HTMLAttributes<HTMLElement>

/**
 * `useDisclosure` is a custom hook used to help handle common open, close, or toggle scenarios.
 * It can be used to control feedback component such as `Modal`, `AlertDialog`, `Drawer`, etc.
 * Borrowed from @chakra/ui
 *
 * @see Docs https://chakra-ui.com/docs/hooks/use-disclosure
 */
export function useDisclosure(props: UseDisclosureProps = {}) {
  const { onClose, onOpen, isOpen: isOpenProp, id: idProp } = props

  const handleOpen = useCallbackRef(onOpen)
  const handleClose = useCallbackRef(onClose)

  const [isOpenState, setIsOpenState] = useState(props.defaultIsOpen ?? false)

  const isOpen = isOpenProp ?? isOpenState

  const isControlled = isOpenProp !== undefined

  const uid = useId()
  const id = idProp ?? `disclosure-${uid}`

  const close = useCallback(() => {
    if (!isControlled) {
      setIsOpenState(false)
    }
    handleClose()
  }, [isControlled, handleClose])

  const open = useCallback(() => {
    if (!isControlled) {
      setIsOpenState(true)
    }
    handleOpen()
  }, [isControlled, handleOpen])

  const toggle = useCallback(() => {
    if (isOpen) {
      close()
    } else {
      open()
    }
  }, [isOpen, open, close])

  function getButtonProps(buttonProps: HTMLProps = {}): HTMLProps {
    return {
      ...buttonProps,
      "aria-expanded": isOpen,
      "aria-controls": id,
      onClick(event) {
        buttonProps.onClick?.(event)
        toggle()
      },
    }
  }

  function getDisclosureProps(disclosureProps: HTMLProps = {}): HTMLProps {
    return {
      ...disclosureProps,
      hidden: !isOpen,
      id,
    }
  }

  return {
    close,
    getButtonProps,
    getDisclosureProps,
    isControlled,
    isOpen,
    open,
    toggle,
  }
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>
