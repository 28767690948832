import React from "react"
import { useNavigate } from "react-router-dom"

import { HStack } from "@/nzds/layout"

import { Link, Stack, Typography, useTheme } from "@mui/material"

const Page404 = (): JSX.Element => {
  const navigate = useNavigate()
  const theme = useTheme()

  const onLinkClick = (): void => {
    navigate("/")
  }

  return (
    <Stack alignItems="center" gap={1} mt={18}>
      <Typography
        color="grey.100"
        fontSize={theme.typography.pxToRem(128)}
        fontWeight="bold"
        lineHeight="normal"
      >
        404
      </Typography>
      <Typography variant="h1">
        Sorry! We couldn&apos;t find this page. 🦛
      </Typography>
      <HStack gap={0.5}>
        <Typography fontSize={theme.typography.pxToRem(16)} fontWeight="bold">
          Try going{" "}
        </Typography>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          component="button"
          onClick={onLinkClick}
          sx={{
            fontWeight: "bold",
            fontSize: theme.typography.pxToRem(16),
            textUnderlineOffset: theme.spacing(0.5),
          }}
        >
          Home
        </Link>
      </HStack>
    </Stack>
  )
}

export default Page404
