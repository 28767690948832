import { useMutation } from "@tanstack/react-query"

import { ApiQueryName } from "../../models/api"
import BillingGroup from "../../models/billingGroup"
import type { QueryMutation, UseCreateEntityOptions } from "../types"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useCreateBillingGroup = ({
  onSuccess,
}: UseCreateEntityOptions) => {
  const mutationKey = useQueryKey(ApiQueryName.BillingGroup, "createOne")
  const { create } = useSpraypaintModel(BillingGroup)
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      newAttributes,
    }: Parameters<
      QueryMutation<"createOne", ModelAttributes<BillingGroup>>
    >[0]) => {
      const billingGroup = create(newAttributes)
      return billingGroup
    },
    onSuccess: (billingGroup) => {
      onSuccess?.(billingGroup?.id)
      invalidateQueries([
        createQueryKey(ApiQueryName.BillingGroups, "getMany"),
        createQueryKey(ApiQueryName.OrganizationCounts, "getOne"),
        createQueryKey(ApiQueryName.SiteCounts, "getOne"),
      ])
    },
  })

  return useMutationReturnHandle(mutationKey, mutationResult)
}
