import type { FC } from "react"
import React from "react"

import { HStack } from "@/nzds/layout"

import EditRoundedIcon from "@mui/icons-material/EditRounded"
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { IconButton } from "@mui/material"

import { useDataGridContext } from "../../../contexts/data-grid-provider"
import type { OUDisplayCellProps } from "./ou-display-cell.types"

export const OUDisplayCell: FC<OUDisplayCellProps> = ({
  name,
  rowNode,
  onClick,
  model,
}) => {
  const { setEditedOU } = useDataGridContext()

  const handleEdit = () => {
    setEditedOU(model)
  }

  return (
    <HStack alignItems="center" justifyContent="space-between" spacing={1}>
      <HStack alignItems="center">
        <IconButton onClick={onClick} size="small">
          {rowNode.type === "group" && rowNode.childrenExpanded ? (
            <ExpandLessRoundedIcon fontSize="small" />
          ) : (
            <ExpandMoreRoundedIcon fontSize="small" />
          )}
        </IconButton>
        {name}
      </HStack>

      <IconButton onClick={handleEdit} size="small">
        <EditRoundedIcon fontSize="small" />
      </IconButton>
    </HStack>
  )
}
