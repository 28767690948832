import { useQuery } from "@tanstack/react-query"

import { ApiQueryName } from "../../models/api"
import NodeModel from "../../models/node"
import type { ServiceOptions } from "../types"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useNodes = ({
  // TODO: Limit by organization
  disabled: isDisabled,
}: ServiceOptions<"getMany"> = {}) => {
  const { getMany } = useSpraypaintModel(NodeModel)

  const queryKey = useQueryKey(ApiQueryName.Nodes, "getMany", null)

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => getMany(),
    enabled: !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type Node = ModelAttributes<NodeModel>
