import type { UserCreate } from "@/models/user"
import { httpClient } from "@/utils/api"
import type { SuccessData } from "@/utils/api"
import { useMutation } from "@tanstack/react-query"
import type { UseMutationOptions } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

const createUserApi = async (
  newUser: UserCreate
): Promise<AxiosResponse<SuccessData>> => {
  try {
    const response = await httpClient.post<SuccessData>("/api/v1/users", {
      user: newUser,
    })

    return response
  } catch {
    console.error("Error creating user")
  }
}

export interface CreateUserError extends SuccessData {
  errors: string[]
}

export const useCreateUser = (
  options: UseMutationOptions<
    AxiosResponse<SuccessData>,
    AxiosError<CreateUserError>,
    UserCreate
  >
) => {
  const useMutationOptions = {
    mutationFn: async (newUser: UserCreate) => createUserApi(newUser),
    ...options,
  }

  const { data, isError, isLoading, isSuccess, mutateAsync } =
    useMutation(useMutationOptions)

  return {
    createUser: mutateAsync,
    createUserData: data,
    createUserIsError: isError,
    createUserIsLoading: isLoading,
    createUserIsSuccess: isSuccess,
  }
}
