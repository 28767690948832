import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"

import { ApiQueryName } from "../../../../models/api"
import Organization from "../../../../models/organization"
import type { OUTreeNode } from "./types"

/**
 * Returns the organizational hierarchy for a given organization. `hierarchy` is an
 * extra attribute on the organization model since it can be computationally expensive to fetch.
 * The hierarchy attribute is a JSON string representing the organization's OU structure, but it does not
 * contain the sites associated with the OUs or the OU models themselves. The sites are fetched separately and added
 * to the hierarchy in the `useHierarchyTreeData` hook along with the OU spraypaint models.
 * @param organizationId
 * @returns An object representing the root OU of the organization along with all of its children
 */
export const useOrganizationalHierarchy = (organizationId: string) => {
  const queryKey = useQueryKey(
    ApiQueryName.OrganizationHierarchy,
    "getOne",
    organizationId
  )

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      try {
        const { data } = await Organization.where({ id: organizationId })
          .selectExtra(["hierarchy"])
          .first()

        if (!data.hierarchy) {
          throw new Error("Organization hierarchy not found")
        }

        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const hierarchy: OUTreeNode = JSON.parse(data.hierarchy)
        return hierarchy
      } catch (e) {
        throw new Error(`Error fetching organizational hierarchy: ${e}`)
      }
    },
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
