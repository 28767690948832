import React from "react"
import type { FC } from "react"

import { DataGuard } from "@/components/data-guard"
import { PageHeader } from "@/components/nav/page-header"
import { Page } from "@/components/page"
import { useAuthentication } from "@/services/authentication"
import {
  allConsultantsWorkspaceName,
  useSigmaWorkbooks,
} from "@/services/sigma"
import type { SigmaWorkbook } from "@/services/sigma"
import { useUserAccess } from "@/services/user"
import { gray200 } from "@/utils/colors"

import { Box, Divider, Skeleton, Typography } from "@mui/material"

import { ReportGroup, reportCards } from "../../models/report"
import type { ReportCard } from "../../models/report"
import { ReportingCards } from "../reporting-cards"

const workbooksToReportCards = (workbooks: SigmaWorkbook[]): ReportCard[] => {
  return workbooks.map((workbook) => ({
    buttonLink: `${workbook.workbookId}`,
    buttonText: `Start Report`,
    description: "",
    group: ReportGroup.Consultant,
    title: workbook.name,
  }))
}

export const Reporting: FC = () => {
  const { isConsultantUser } = useAuthentication()
  const { isLimitedAccessUser } = useUserAccess()

  const {
    sigmaWorkbooksData: consultantWorkbooks,
    sigmaWorkbooksIsPlaceholderData: consultantWorkbooksIsPlaceholderData,
  } = useSigmaWorkbooks({
    workspaceNames: [allConsultantsWorkspaceName],
  })

  const consultantReportCards = workbooksToReportCards(
    consultantWorkbooks?.entries ?? []
  )

  const group1 = reportCards.filter(
    (card) =>
      card.group === ReportGroup.One &&
      (!isLimitedAccessUser ||
        (isLimitedAccessUser && card.isVisibleToLimitedAccessUser))
  )

  const group2 = reportCards.filter(
    (card) =>
      card.group === ReportGroup.Two &&
      (!isLimitedAccessUser ||
        (isLimitedAccessUser && card.isVisibleToLimitedAccessUser))
  )

  return (
    <>
      <PageHeader title={isConsultantUser ? "Analyze" : "Reporting"} />
      <DataGuard>
        <Page>
          <Box mb={2}>
            <Typography>Select report</Typography>
          </Box>
          {isConsultantUser && consultantReportCards.length ? (
            <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
              {consultantWorkbooksIsPlaceholderData ? (
                consultantReportCards.map((card) => (
                  <Skeleton
                    key={card.title}
                    height={188}
                    variant="rounded"
                    width={320}
                  />
                ))
              ) : (
                <ReportingCards cards={consultantReportCards} />
              )}
            </Box>
          ) : null}
          {!isConsultantUser && group1.length ? (
            <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
              <ReportingCards cards={group1} />
            </Box>
          ) : null}
          {((isConsultantUser && consultantReportCards.length) ||
            (!isConsultantUser && group1.length)) &&
          group2.length ? (
            <Divider
              sx={{ marginBottom: 3, borderColor: gray200.toString() }}
            />
          ) : null}
          {group2.length ? (
            <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
              <ReportingCards cards={group2} />
            </Box>
          ) : null}
        </Page>
      </DataGuard>
    </>
  )
}
