import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"

import { App } from "@/components/app/app"
import I18nResolver from "@/components/i18nResolver/i18nResolver"
import { NotificationProvider, OrganizationProvider } from "@/contexts"
import type { EnvironmentName } from "@/models/environment.types"
import createStore from "@/state/store"
import MainTheme from "@/styles/theme"
import { createI18nService, initializeI18nService } from "@/utils/i18n"
import { queryClient } from "@/utils/queryClient"
import { datadogRum } from "@datadog/browser-rum"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import type { i18n } from "i18next"
import { ConfirmProvider } from "material-ui-confirm"

import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material"
import { LicenseInfo } from "@mui/x-license-pro"

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)

// Initialize RUM
if (
  process.env.REACT_APP_ENV === ("development" satisfies EnvironmentName) ||
  process.env.REACT_APP_ENV === ("acceptance" satisfies EnvironmentName) ||
  process.env.REACT_APP_ENV === ("fed" satisfies EnvironmentName) ||
  process.env.REACT_APP_ENV === ("production" satisfies EnvironmentName)
) {
  datadogRum.init({
    actionNameAttribute: "data-analytics-action-name",
    allowedTracingUrls: [/https:\/\/.*\.(nzero|ledger8760)\.com/],
    applicationId: process.env.REACT_APP_DD_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN,
    defaultPrivacyLevel: "mask-user-input",
    env: process.env.REACT_APP_ENV,
    service: "puma",
    sessionReplaySampleRate:
      process.env.REACT_APP_ENV === ("production" satisfies EnvironmentName)
        ? 100
        : 0,
    sessionSampleRate: 100,
    site: "datadoghq.com",
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: APP_VERSION,
  })
}

// Set up internationalization service
const i18nService: i18n = createI18nService()

initializeI18nService(i18nService).catch((e) => {
  console.error(e)
})

// Create Redux store
const store = createStore({
  sagaMiddlewareContext: {
    i18n: i18nService,
  },
})

// Create root and render app
const root = createRoot(document.getElementById("root"))

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <I18nResolver i18nService={i18nService}>
        <ReduxProvider store={store}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={MainTheme}>
              <NotificationProvider>
                <ConfirmProvider>
                  <CssBaseline />
                  <OrganizationProvider>
                    <App />
                  </OrganizationProvider>
                </ConfirmProvider>
              </NotificationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ReduxProvider>
      </I18nResolver>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>
)
