/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { FC } from "react"
import React, { useEffect, useMemo, useState } from "react"
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useParams,
} from "react-router-dom"

import type { DashboardContext } from "@/components/dashboard/types"
import type { DateRangeWithType } from "@/components/graph/dateRangeWithType"
import Page404 from "@/components/nav/page404/page404"
import { PageHeader } from "@/components/nav/page-header"
import { useOrganizationContext } from "@/contexts"
import type { IRange } from "@/models/range"
import { SitePath } from "@/models/route"
import Site from "@/models/site"
import { useAuthentication } from "@/services/authentication"
import { useSite } from "@/services/site"
import { useToggle } from "@/services/toggle"
import { TIME_RANGES } from "@/utils"
import { useUrl } from "@/utils/url"
import { kebabCase } from "lodash-es"
import type { Moment } from "moment"
import moment from "moment"

import { Box, Slide, Tab, Tabs, useTheme } from "@mui/material"

import SiteInfo from "../../components/siteInfo/siteInfo"
import SiteInfoButton from "../../components/siteInfo/siteInfoButton/siteInfoButton"
import {
  useMonthSummariesMinMaxDates,
  useNaturalGasAvailableMonths,
  useServiceHourMinMaxDates,
  useSiteExtraAttributes,
} from "../../services"

interface SiteTab {
  disabled?: boolean
  label: string
  path: string
}

const currentTimeRange = (start: Moment, end: Moment): TIME_RANGES => {
  return Math.abs(start.diff(end, "hours")) >= 48
    ? TIME_RANGES.MONTH
    : TIME_RANGES.DAY
}

export const SiteIndexRoute: FC = () => {
  const theme = useTheme()
  const { search: locationSearch } = useLocation()
  const { buildSiteUrl } = useUrl()
  const { isConsultantUser } = useAuthentication()
  const { organization } = useOrganizationContext()
  const params = useParams()
  const { site, isSiteFetched } = useSite(params.siteId ?? "")
  // Create a Spraypaint site instance
  // We shouldn't be doing this, but our entity services and use of Spraypaint need to be fixed
  const activeSite: Site | null = useMemo(
    () => (site ? new Site(site) : null),
    [site]
  )

  const [isSiteInActiveOrg, setIsSiteInActiveOrg] = useState<
    boolean | undefined
  >(undefined)

  const siteDetailRootMatch = useMatch(buildSiteUrl(SitePath.Site))
  const electricityMatch = useMatch(
    buildSiteUrl(SitePath.Site, SitePath.Electricity)
  )
  const emissionsMatch = useMatch(
    buildSiteUrl(SitePath.Site, SitePath.Emissions)
  )
  const naturalGasMatch = useMatch(
    buildSiteUrl(SitePath.Site, SitePath.NaturalGas)
  )
  const overviewMatch = useMatch(buildSiteUrl(SitePath.Site, SitePath.Overview))
  const detailsMatch = useMatch(
    buildSiteUrl(SitePath.Site, SitePath.SiteDetails)
  )

  let activeTabPath: SitePath | undefined = undefined

  if (electricityMatch) {
    activeTabPath = SitePath.Electricity
  } else if (emissionsMatch) {
    activeTabPath = SitePath.Emissions
  } else if (naturalGasMatch) {
    activeTabPath = SitePath.NaturalGas
  } else if (overviewMatch) {
    activeTabPath = SitePath.Overview
  } else if (detailsMatch) {
    activeTabPath = SitePath.SiteDetails
  }

  const urlSearchParams = new URLSearchParams(locationSearch)
  const urlStart = urlSearchParams.get("start")
  const urlEnd = urlSearchParams.get("end")

  const searchStartDate: moment.Moment = moment(
    urlStart,
    "YYYY-MM-DD"
  ).isValid()
    ? moment(urlStart, "YYYY-MM-DD")
    : moment(moment().startOf("month").format("YYYY-MM-DD"))

  const searchEndDate: moment.Moment = moment(urlEnd, "YYYY-MM-DD")
    .endOf("day")
    .isValid()
    ? moment(urlEnd, "YYYY-MM-DD").endOf("day")
    : moment(moment().endOf("month").endOf("day").format("YYYY-MM-DD"))

  const [timeWindow, setTimeWindow] = useState<DateRangeWithType>({
    startDate: searchStartDate,
    endDate: searchEndDate,
    timeRange: currentTimeRange(searchStartDate, searchEndDate),
  })

  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars
  const [energyAllocations, setEnergyAllocations] = useState(null)

  const {
    siteExtraAttributes: {
      hasAmiMeter,
      hasAmrMeter,
      hasSubMeter,
      probeMeter,
    } = {
      hasAmiMeter: null,
      hasAmrMeter: null,
      hasSubMeter: null,
      probeMeter: null,
    },
    isSiteExtraAttributesFetched,
  } = useSiteExtraAttributes(activeSite?.id)

  const isMonthlySummaryVisible: boolean =
    isSiteExtraAttributesFetched &&
    Boolean(hasAmrMeter) &&
    Boolean(!hasAmiMeter)

  const { isOn: isSiteInfoOpen, toggle: toggleSiteInfo } = useToggle()

  // Does site track natural gas?
  const { naturalGasAvailableMonths, isNaturalGasAvailableMonthsFetched } =
    useNaturalGasAvailableMonths(activeSite?.id)

  const siteTracksAndHasGas =
    isNaturalGasAvailableMonthsFetched &&
    naturalGasAvailableMonths &&
    (naturalGasAvailableMonths as Moment[]).length >= 1

  // Does site track electricity?
  const { serviceHourMinMaxDates, isServiceHourMinMaxDatesFetched } =
    useServiceHourMinMaxDates(activeSite)

  const { monthSummariesMinMaxDates, isMonthSummariesMinMaxDatesFetched } =
    useMonthSummariesMinMaxDates(activeSite?.id, {
      isDisabled: !isMonthlySummaryVisible,
    })

  // TODO: Fix typing (especially in useRenamedQueryResult) to avoid casting
  const monthSummariesDateRange = monthSummariesMinMaxDates as
    | IRange<Moment>
    | undefined

  const siteHasElectricityServiceHours: boolean =
    isServiceHourMinMaxDatesFetched &&
    serviceHourMinMaxDates?.end !== null &&
    serviceHourMinMaxDates?.start !== null

  const siteHasElectricityMonthlySummary: boolean = isMonthlySummaryVisible
    ? Boolean(monthSummariesDateRange) &&
      Boolean(monthSummariesDateRange?.end?.isValid()) &&
      Boolean(monthSummariesDateRange?.start?.isValid())
    : true

  const siteTracksAndHasElectricity: boolean =
    siteHasElectricityServiceHours && siteHasElectricityMonthlySummary

  const siteHasNoData: boolean =
    !siteTracksAndHasElectricity &&
    !siteTracksAndHasGas &&
    isServiceHourMinMaxDatesFetched &&
    (!isMonthlySummaryVisible ||
      (isMonthlySummaryVisible && isMonthSummariesMinMaxDatesFetched)) &&
    isNaturalGasAvailableMonthsFetched

  const naturalGasMatchNoData: boolean =
    Boolean(naturalGasMatch) &&
    !siteTracksAndHasGas &&
    isNaturalGasAvailableMonthsFetched

  const electricityMatchNoData: boolean =
    Boolean(electricityMatch) &&
    !siteTracksAndHasElectricity &&
    isServiceHourMinMaxDatesFetched &&
    (!isMonthlySummaryVisible ||
      (isMonthlySummaryVisible && isMonthSummariesMinMaxDatesFetched))

  const isReadyToRender: boolean =
    (Boolean(activeSite) &&
      // Avoid flashing and processing of previous site data when switching between sites
      // TODO: Need to refactor to use new site service to avoid this (useSite not working properly)
      activeSite?.id === params.siteId &&
      hasAmrMeter !== null &&
      isServiceHourMinMaxDatesFetched &&
      (!isMonthlySummaryVisible ||
        (isMonthlySummaryVisible && isMonthSummariesMinMaxDatesFetched)) &&
      isNaturalGasAvailableMonthsFetched) ||
    isSiteInActiveOrg === false

  // Set details based on the active site
  useEffect(() => {
    if (isSiteFetched) {
      setIsSiteInActiveOrg(activeSite?.organization.id === organization?.id)

      if (activeSite?.timezone) {
        moment.tz.setDefault(activeSite.timezone)
      }
    }
  }, [activeSite, isSiteFetched, organization])

  // Redirect to correct tab if necessary
  if (
    !isConsultantUser &&
    (siteHasNoData || isSiteInActiveOrg === false) &&
    !emissionsMatch
  ) {
    return (
      <Navigate replace={true} to={`${SitePath.Emissions}${locationSearch}`} />
    )
  }

  if (siteDetailRootMatch) {
    return isConsultantUser ? (
      <Navigate replace={true} to={SitePath.Overview} />
    ) : (
      <Navigate replace={true} to={SitePath.Electricity} />
    )
  }

  if (!isConsultantUser) {
    if (electricityMatchNoData) {
      return <Navigate replace={true} to={SitePath.NaturalGas} />
    }

    if (naturalGasMatchNoData) {
      if (!siteTracksAndHasElectricity) {
        return <Navigate replace={true} to={SitePath.Emissions} />
      }

      return <Navigate replace={true} to={SitePath.Electricity} />
    }
  }

  const outletContext: DashboardContext = {
    dateRange: {
      start: timeWindow.startDate,
      end: timeWindow.endDate,
    },
    isSiteInfoOpen,
    orgId: organization?.id,
    site: activeSite,
    siteHasNoData,
    siteTracksAndHasGas,
    siteTracksAndHasElectricity,
    siteInfoButton: (
      <SiteInfoButton isOpen={isSiteInfoOpen} onClick={toggleSiteInfo} />
    ),
    electricity: {
      isMonthlySummaryVisible,
      siteHasSubMeter: hasSubMeter,
      siteIsAmi: hasAmiMeter,
      siteIsAmr: hasAmrMeter,
      siteIsProbe: probeMeter,
      setEnergyAllocations,
      setTimeWindow,
    },
  }

  const consultantUserTabs: SiteTab[] = [
    { label: "Overview", path: SitePath.Overview },
    { label: "Details", path: SitePath.SiteDetails },
  ]
  const standardUserTabs: SiteTab[] = [
    { disabled: siteHasNoData, label: "Emissions", path: SitePath.Emissions },
  ]

  if (siteHasNoData || siteTracksAndHasElectricity) {
    standardUserTabs.push({
      disabled: siteHasNoData,
      label: "Electricity",
      path: SitePath.Electricity,
    })
  }

  if (siteHasNoData || siteTracksAndHasGas) {
    standardUserTabs.push({
      disabled: siteHasNoData,
      label: "Natural Gas",
      path: SitePath.NaturalGas,
    })
  }

  const tabs: SiteTab[] = [
    ...(isConsultantUser ? consultantUserTabs : standardUserTabs),
  ]

  return isReadyToRender ? (
    <>
      <PageHeader
        navigation={
          <Tabs
            aria-label="Site navigation"
            role="navigation"
            sx={{ px: 3 }}
            value={buildSiteUrl(SitePath.Site, activeTabPath)}
          >
            {tabs.map((tab) => (
              <Tab
                component={Link}
                data-e2e={`${kebabCase(tab.label)}-tab`}
                disabled={tab.disabled}
                key={tab.label}
                label={tab.label}
                to={tab.path}
                value={buildSiteUrl(SitePath.Site, tab.path)}
              />
            ))}
          </Tabs>
        }
        title={`${activeSite?.name ?? "Unknown Site"}${
          activeSite?.departmentName ? ", " + activeSite.departmentName : ""
        }`}
      />
      {isSiteInActiveOrg === false ? (
        <Page404 />
      ) : (
        <Outlet context={outletContext} />
      )}
      <Slide direction="left" in={isSiteInfoOpen}>
        <Box
          sx={{
            bottom: "24px",
            width: theme.typography.pxToRem(320),
            top: "72px",
            right: 0,
            position: "fixed",
            zIndex: 1200,
          }}
        >
          <SiteInfo onClose={toggleSiteInfo} site={activeSite} />
        </Box>
      </Slide>
    </>
  ) : null
}
