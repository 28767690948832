import { useMemo } from "react"

import type { UseQueryResult } from "@tanstack/react-query"

import type {
  QueryData,
  QueryInputData,
  ServiceAction,
  ServiceName,
  ServiceValue,
} from "../../types"
import createRenamedQueryData from "../createRenamedQueryData/createRenamedQueryData"
import { createRenamedQueryProps } from "../createRenamedQueryProps/createRenamedQueryProps"

/**
 * @deprecated Use {@link UseRenameQueryResult} instead
 *
 * Create a renamed and memoized return object for a particular service.
 * Renames "data" to "serviceName" and "isLoading" to "isServiceNameGetOneLoading", etc.
 * @param serviceName The name of the service.
 * @param serviceAction The action of the service.
 * @param inputObject The input object to be remapped.
 * @returns The return object.
 * @example
 * useRenamedQueryResult(["test", "getOne"], {
 * isIdle: false,
 * isLoading: false,
 * isSuccess: true,
 * isError: false,
 * })
 */
export const useRenamedQueryResult = <
  NameT extends ServiceName,
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
>(
  // Accept a query key, but allow any other tuple as well:
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [serviceName]: [NameT, ActionT, ...any],
  // Formatted to accept the return value of react-query's useQuery.
  inputObject: UseQueryResult & QueryInputData<ActionT, ValueT>
) => {
  const renamedQueryProps = useMemo(
    () => createRenamedQueryProps(serviceName, inputObject),
    [inputObject, serviceName]
  )

  const renamedQueryData: QueryData<NameT, ActionT, ValueT> = useMemo(
    () => createRenamedQueryData(serviceName, inputObject),
    [inputObject, serviceName]
  )

  return useMemo(
    () => ({
      // TODO: Remove this when we update existing services to use the new output status.
      ...inputObject,
      ...renamedQueryProps,
      ...renamedQueryData,
    }),
    [inputObject, renamedQueryData, renamedQueryProps]
  )
}
