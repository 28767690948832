import React from "react"
import type { FC } from "react"

import { WhiteBox } from "@/components/whiteBox"

import { About } from "../../components/about"

export const AboutIndex: FC = () => (
  <WhiteBox>
    <div style={{ textAlign: "center" }}>
      <About />
    </div>
  </WhiteBox>
)
