// @ts-strict-ignore
import { useCallback, useMemo } from "react"

import { useQuery, useQueryClient } from "@tanstack/react-query"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"

interface EmissionsDateRangeValue {
  emissionsDateRange: IRange<Moment> | null
  setEmissionsDateRange: (dateRange: IRange<Moment>) => void
}

/**
 * A service for saving and providing the date range for a site's emissions
 *
 * @returns - The emissions date range service
 * @example
 * const { emissionsDateRange, setEmissionsDateRange } = useEmissionsDateRange()
 */
export const useEmissionsDateRange = (): EmissionsDateRangeValue => {
  const queryClient = useQueryClient()
  const queryKey = useMemo(() => [ApiQueryName.EmissionsDateRange], [])

  const { data } = useQuery({
    cacheTime: Infinity,
    queryFn: (): Promise<IRange<Moment>> =>
      Promise.resolve(queryClient.getQueryData(queryKey) ?? null),
    queryKey,
    staleTime: Infinity,
  })

  const emissionsDateRange = useMemo(
    () => data,
    [data]
  ) as unknown as IRange<Moment>

  const setEmissionsDateRange = useCallback(
    (dateRange: IRange<Moment>) => {
      queryClient.setQueryData(queryKey, dateRange)
    },
    [queryClient, queryKey]
  )

  return useMemo(
    () => ({
      emissionsDateRange,
      setEmissionsDateRange,
    }),
    [emissionsDateRange, setEmissionsDateRange]
  )
}
