import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import type { RouteObject } from "react-router-dom"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { OrganizationSummary } from "@/components/organizationSummary/organizationSummary"
import { RoleGuard } from "@/components/route/role-guard"
import { RootPath } from "@/models/route"

export const financialRoutes: RouteObject[] = [
  {
    path: RootPath.Financial,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}
      >
        <RoleGuard disallowedRoles={["consultant_user"]}>
          <div className="authorized-organization">
            <OrganizationSummary />
          </div>
        </RoleGuard>
      </ErrorBoundary>
    ),
  },
]
