import React, { useEffect, useState } from "react"

import moment from "moment"
import "moment-timezone"
import type { StatsScope, WhereClause } from "spraypaint"

import { ExpandMore } from "@mui/icons-material"
import type { SelectChangeEvent } from "@mui/material"
import {
  Box,
  Divider,
  FormControl,
  Select,
  Stack,
  Typography,
} from "@mui/material"

import type Site from "../models/site"
import SiteEmissionDay from "../models/siteEmissionDay"
import { TYPOGRAPHY_VARIANT } from "../models/typography"
import { UnitName } from "../models/unit"
import { useNaturalGasAvailableMonths } from "../modules/site/services/useNaturalGasAvailableMonths/useNaturalGasAvailableMonths"
import { solidGray } from "../utils/colors"
import { KG_PER_METRIC_TON, THERMS_PER_MMBTU } from "../utils/constants"
import { sharedDropdownFormat as ngDateFormat } from "../utils/formatters"
import { pxToRem } from "../utils/unit"
import { DecimalWithPlaceholder } from "./decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { UsageRateDecimal } from "./decimal/usageRateDecimal/usageRateDecimal"

export const thermsReducer = (
  previousValue: number,
  currentValue: SiteEmissionDay | typeof SiteEmissionDay.attributeList,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentIndex: number,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  array: SiteEmissionDay[] | (typeof SiteEmissionDay.attributeList)[]
) => {
  previousValue += Number(currentValue.amount) * THERMS_PER_MMBTU
  return previousValue
}
export const kgCo2eReducer = (
  previousValue: number,
  currentValue: SiteEmissionDay | typeof SiteEmissionDay.attributeList,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentIndex: number,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  array: SiteEmissionDay[] | (typeof SiteEmissionDay.attributeList)[]
) => {
  if (!("parsedKgCo2e" in currentValue)) {
    currentValue = new SiteEmissionDay(currentValue)
  }
  previousValue += Number(currentValue.parsedKgCo2e)
  return previousValue
}

const NaturalGasCard = ({
  availableMonths,
  dateGetAndSet,
  therms,
  avg,
  data,
  kgCo2e,
  beenSelectedGetAndSet,
}: {
  availableMonths: moment.Moment[] | undefined
  avg: number
  beenSelectedGetAndSet: {
    get: boolean
    set: React.Dispatch<React.SetStateAction<boolean>>
  }
  data
  dateGetAndSet: {
    get: moment.Moment
    set: React.Dispatch<React.SetStateAction<[moment.Moment, moment.Moment]>>
  }
  kgCo2e: number
  therms: number
}) => {
  const mtCo2e = kgCo2e / KG_PER_METRIC_TON

  const handleChange = (event: SelectChangeEvent) => {
    beenSelectedGetAndSet.set(true)
    const date = moment(event.target.value, ngDateFormat)
    dateGetAndSet.set([date, date.clone().endOf("month")])
  }

  const defaultSelect = "Select a month"

  return (
    <Box {...data} sx={{ width: 0.5 }}>
      <Box mb={3}>
        <FormControl
          data-name="natural-gas-date-picker"
          sx={{ width: pxToRem(204) }}
          variant="outlined"
        >
          <Typography color={solidGray.toString()} mb={0.5}>
            Month
          </Typography>
          <Select
            IconComponent={ExpandMore}
            inputProps={{
              name: "date",
              id: "outlined-date",
            }}
            native
            onChange={handleChange}
            value={
              beenSelectedGetAndSet.get
                ? dateGetAndSet.get.format(ngDateFormat)
                : defaultSelect
            }
          >
            <option
              aria-label="None"
              disabled={beenSelectedGetAndSet.get}
              value={defaultSelect}
            >
              {defaultSelect}
            </option>
            {availableMonths
              ?.map((month) => {
                const formattedMonth = month.format(ngDateFormat)
                return React.createElement(
                  "option",
                  {
                    value: formattedMonth,
                    key: formattedMonth,
                  },
                  formattedMonth
                )
              })
              .reverse() || <option />}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Box mb={3}>
          <Typography data-name="gas-date" variant={TYPOGRAPHY_VARIANT.h3}>
            {dateGetAndSet.get.format(ngDateFormat)} Natural Gas
          </Typography>
          <Divider
            sx={{
              my: 1,
            }}
          />
        </Box>
        <Stack direction="row" mb={0}>
          <Box sx={{ width: 1 / 3 }}>
            <Typography>Total Volume</Typography>
            <Typography
              data-name="total-therms"
              variant={TYPOGRAPHY_VARIANT.body2}
            >
              <DecimalWithPlaceholder
                format={{
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 2,
                }}
                unit={UnitName.Therm}
                value={therms}
              />
            </Typography>
          </Box>
          <Box sx={{ width: 1 / 3 }}>
            <Typography>Avg Daily Use</Typography>
            <Typography
              data-name="avg-therms"
              variant={TYPOGRAPHY_VARIANT.body2}
            >
              <DecimalWithPlaceholder
                format={{
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 2,
                }}
                unit={UnitName.Therm}
                value={avg}
              />
            </Typography>
          </Box>
          <Box sx={{ width: 1 / 3 }}>
            <Typography>Total Carbon</Typography>
            <Typography
              data-name="total-carbon"
              variant={TYPOGRAPHY_VARIANT.body2}
            >
              <UsageRateDecimal
                unit={UnitName.MetricTonsOfCarbonDioxideEquivalent}
                value={mtCo2e}
              />
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

export const NaturalGasPanel = ({ site }: { site: Site }) => {
  const [dateRangeLeft, setDateRangeLeft] =
    useState<[moment.Moment, moment.Moment]>()
  const [dateRangeRight, setDateRangeRight] =
    useState<[moment.Moment, moment.Moment]>()
  const [thermsLeft, setThermsLeft] = useState<number>(null)
  const [thermsAvgLeft, setThermsAvgLeft] = useState<number>(null)
  const [kgCo2eLeft, setKgCo2eLeft] = useState<number>(null)
  const [thermsRight, setThermsRight] = useState<number>(null)
  const [thermsAvgRight, setThermsAvgRight] = useState<number>(null)
  const [kgCo2eRight, setKgCo2eRight] = useState<number>(null)
  const [hasBeenSet, setBeenSelected] = useState(false)

  const setInitialDateRanges = () => {
    setDateRangeLeft([moment().startOf("month"), moment()])
    setDateRangeRight([
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ])
  }
  const { naturalGasAvailableMonths } = useNaturalGasAvailableMonths(site.id)
  if (!dateRangeLeft && !dateRangeRight) {
    setInitialDateRanges()
  }
  const fetchEmissions = async ({
    hours,
    thermsSetter,
    setterAvg,
    kgCo2eSetter,
  }) => {
    const whereClause: WhereClause = {
      site_id: site.id,
      // migration to strict mode batch disable
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      day: { gte: hours[0].format(), lte: hours[1].format() },
      ghg_emissions_factor: {
        eq: {
          category: "Stationary Combustion",
          sub_category: "Natural Gas",
          unit: "mmbtu",
        },
      },
    }
    const statsScope: StatsScope = { metered_day: "count" }
    await SiteEmissionDay.where(whereClause)
      .stats(statsScope)
      .all()
      .then(({ data, meta }) => {
        const therms = data.reduce(thermsReducer, 0)
        const kgCo2e = data.reduce(kgCo2eReducer, 0)
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        thermsSetter(therms)
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        kgCo2eSetter(kgCo2e)
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        setterAvg(therms / meta.stats.metered_day.count)
      })
  }

  const refreshLeft = () => {
    setThermsLeft(null)
    setThermsAvgLeft(null)
    setKgCo2eLeft(null)
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchEmissions({
      hours: dateRangeLeft,
      thermsSetter: setThermsLeft,
      setterAvg: setThermsAvgLeft,
      kgCo2eSetter: setKgCo2eLeft,
    })
  }

  const refreshRight = () => {
    setThermsRight(null)
    setThermsAvgRight(null)
    setKgCo2eRight(null)
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchEmissions({
      hours: dateRangeRight,
      thermsSetter: setThermsRight,
      setterAvg: setThermsAvgRight,
      kgCo2eSetter: setKgCo2eRight,
    })
  }

  const setInitialState = () => {
    setThermsLeft(null)
    setThermsAvgLeft(null)
    setKgCo2eLeft(null)
    setThermsRight(null)
    setThermsAvgRight(null)
    setKgCo2eRight(null)
    setInitialDateRanges()
    setBeenSelected(false)
  }

  useEffect(() => {
    if (dateRangeLeft?.length > 0) {
      refreshLeft()
    }
  }, [dateRangeLeft])

  useEffect(() => {
    if (dateRangeRight?.length > 0) {
      refreshRight()
    }
  }, [dateRangeRight])

  useEffect(() => {
    setInitialState()
  }, [site])

  if (!(naturalGasAvailableMonths?.length >= 1)) {
    return null
  }

  return (
    <>
      <Typography mb={2} variant={TYPOGRAPHY_VARIANT.h2}>
        Month Comparison
      </Typography>
      <Stack direction="row" spacing={3}>
        <NaturalGasCard
          availableMonths={naturalGasAvailableMonths}
          avg={thermsAvgLeft}
          beenSelectedGetAndSet={{ get: hasBeenSet, set: setBeenSelected }}
          data={{ "data-name": "natural-gas-left" }}
          dateGetAndSet={{ get: dateRangeLeft?.[0], set: setDateRangeLeft }}
          kgCo2e={kgCo2eLeft}
          therms={thermsLeft}
        />

        <NaturalGasCard
          availableMonths={naturalGasAvailableMonths}
          avg={thermsAvgRight}
          beenSelectedGetAndSet={{ get: hasBeenSet, set: setBeenSelected }}
          data={{ "data-name": "natural-gas-right" }}
          dateGetAndSet={{ get: dateRangeRight?.[0], set: setDateRangeRight }}
          kgCo2e={kgCo2eRight}
          therms={thermsRight}
        />
      </Stack>
    </>
  )
}
