import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Outlet } from "react-router-dom"
import type { RouteObject } from "react-router-dom"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { ConsultantDataGuard } from "@/components/route/consultant-data-guard"
import { RoleGuard } from "@/components/route/role-guard"
import { RootPath, SitePath } from "@/models/route"

import Electricity from "./components/electricity/electricity"
import NaturalGas from "./components/naturalGas/naturalGas"
import SiteAnalysis from "./components/siteAnalysis/siteAnalysis"
import { SiteDetailsRoute } from "./pages/site-details-route"
import { SiteIndexRoute } from "./pages/site-index-route/site-index-route"
import { SiteOverviewRoute } from "./pages/site-overview-route/site-overview-route"
import { SitesComplianceRoute } from "./pages/sites-compliance-route/sites-compliance-route"
import { SitesIndexRoute } from "./pages/sites-index-route/sites-index-route"
import { SitesOverviewRoute } from "./pages/sites-overview-route/sites-overview-route"
import { SitesPerformanceRoute } from "./pages/sites-performance-route/sites-performance-route"

export const siteRoutes: RouteObject[] = [
  {
    path: RootPath.Site,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}
      >
        <ConsultantDataGuard>
          <Outlet />
        </ConsultantDataGuard>
      </ErrorBoundary>
    ),
    children: [
      {
        path: SitePath.Site,
        element: <SiteIndexRoute />,
        children: [
          {
            path: SitePath.Overview,
            element: (
              <RoleGuard allowedRoles={["consultant_user"]}>
                <SiteOverviewRoute />
              </RoleGuard>
            ),
          },
          {
            path: SitePath.SiteDetails,
            element: (
              <RoleGuard allowedRoles={["consultant_user"]}>
                <SiteDetailsRoute />
              </RoleGuard>
            ),
          },
          {
            path: SitePath.Emissions,
            element: (
              <RoleGuard disallowedRoles={["consultant_user"]}>
                <SiteAnalysis />
              </RoleGuard>
            ),
          },
          {
            path: SitePath.Electricity,
            element: (
              <RoleGuard disallowedRoles={["consultant_user"]}>
                <Electricity />
              </RoleGuard>
            ),
          },
          {
            path: SitePath.NaturalGas,
            element: (
              <RoleGuard disallowedRoles={["consultant_user"]}>
                <NaturalGas />
              </RoleGuard>
            ),
          },
        ],
      },
      {
        index: true,
        element: <SitesIndexRoute />,
      },
      {
        element: <SitesIndexRoute />,
        children: [
          {
            path: SitePath.Overview,
            element: <SitesOverviewRoute />,
          },
          {
            path: SitePath.Performance,
            element: <SitesPerformanceRoute />,
          },
          {
            path: SitePath.Compliance,
            element: <SitesComplianceRoute />,
          },
        ],
      },
    ],
  },
]
