import React from "react"
import {
  Link,
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"

import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"
import { StyledPageCard } from "@/components/page-card/styled-page-card"
import { PageLoading } from "@/components/page-loading/page-loading"
import { HStack } from "@/nzds/layout"
import { useDisclosure } from "@/nzds/utils"
import { useUrl } from "@/utils/url"

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded"
import { IconButton } from "@mui/material"

import SiteInfoButton from "../../../site/components/siteInfo/siteInfoButton/siteInfoButton"
import { EditSiteConfirmation } from "../../components/edit-site-information/edit-site-confirmation"
import { PlanningSteps } from "../../components/planning-steps/planning-steps"
import { SiteGoalsForm } from "../../components/site-goals-form/site-goals-form"
import { SiteInfoDrawer } from "../../components/site-info-drawer/site-info-drawer"
import {
  drawerContainerGap,
  drawerWidth,
} from "../../components/site-info-drawer/site-info-drawer.constants"
import { RecommendationsPath } from "../../enums"
import { useGetGoals } from "../../services/use-get-goals/use-get-goals"
import { useGetPhysicalDetailTypes } from "../../services/useGetPhysicalDetailTypes/use-get-physical-detail-types"
import { useGetSite } from "../../services/useGetSite/use-get-site"

export const SiteGoalsRoute = () => {
  const { recommendationsPath } = useUrl()
  const { siteId } = useParams<{ siteId: string }>()

  const {
    close: drawerClose,
    isOpen: drawerIsOpen,
    toggle: drawerToggle,
  } = useDisclosure()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: site, isLoading: isSiteLoading } = useGetSite(siteId!)

  const { data: goals, isLoading: isGoalsLoading } = useGetGoals({
    query: {
      site_id: siteId,
    },
    useQueryOptions: {
      enabled: Boolean(siteId),
    },
  })

  const { data: physicalDetailTypes, isLoading: isPhysicalDetailTypesLoading } =
    useGetPhysicalDetailTypes()

  const { close, isOpen, open } = useDisclosure()

  const isLoading =
    isSiteLoading || isPhysicalDetailTypesLoading || isGoalsLoading

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const siteRecommendationsHref = generatePath(
    `${recommendationsPath}/${RecommendationsPath.Site}`,
    { siteId: siteId ?? null }
  )

  return (
    <PageLoading isLoading={isLoading}>
      {site && goals ? (
        <>
          <PageHeader title={`Set goals for ${site.name}`} />
          <PageHeaderActionBar hasTabs={false}>
            <HStack gap={2} justifyContent="space-between">
              <IconButton
                aria-label="Back to plan a site"
                color="neutral"
                component={Link}
                to={`${recommendationsPath}/${RecommendationsPath.Plan}`}
              >
                <ArrowBackIosNewRoundedIcon />
              </IconButton>
              <SiteInfoButton isOpen={drawerIsOpen} onClick={drawerToggle} />
            </HStack>
          </PageHeaderActionBar>
          <Page>
            <HStack gap={2}>
              <StyledPageCard
                offset={drawerWidth + drawerContainerGap}
                open={drawerIsOpen}
              >
                <PlanningSteps activeStep={1} siteId={siteId} sx={{ mb: 3 }} />
                <EditSiteConfirmation
                  onClose={close}
                  onContinue={() => {
                    navigate(
                      `${siteRecommendationsHref}?${searchParams.toString()}`
                    )
                  }}
                  open={isOpen}
                />
                {physicalDetailTypes ? (
                  <SiteGoalsForm
                    goals={goals}
                    onSubmitSuccess={() => {
                      open()
                    }}
                    physicalDetailTypes={physicalDetailTypes}
                    site={site}
                  />
                ) : null}
              </StyledPageCard>
              <SiteInfoDrawer
                onClose={drawerClose}
                open={drawerIsOpen}
                site={site}
              />
            </HStack>
          </Page>
        </>
      ) : null}
    </PageLoading>
  )
}
