import { useCallback } from "react"

import { ApiQueryName } from "@/models/api"
import type User from "@/models/user"
import { UserRoleName } from "@/models/user"
import { httpClient } from "@/utils/api"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import type { UseMutateAsyncFunction } from "@tanstack/react-query"

import { getSessionTimeout } from "./useAuthenticationUtils"

export const isSessionValidQueryKey: string[] = [
  ApiQueryName.IsSessionValid,
] as const

export const getAuthenticatedUserQueryKey: string[] = [
  ApiQueryName.Authentication,
  "getAuthenticatedUser",
] as const

const signInMutationKey = [ApiQueryName.Authentication, "signIn"] as const
const signOutMutationKey = [ApiQueryName.Authentication, "signOut"] as const

interface GetUserResponse {
  data: {
    attributes: User
    id: string
    type: "users"
  }
}

// We are not returning a User class instance but instead a pojo that represents a user
export const getUser = async (params = {}): Promise<User | null> => {
  try {
    const response = await httpClient.get<GetUserResponse>("/api/v1/user", {
      params,
    })

    return {
      ...response.data.data.attributes,
      id: response.data.data.id,
      type: response.data.data.type,
    } as unknown as User
  } catch {
    return null
  }
}

interface LoginResponse {
  authenticationToken: string
  success: boolean
}

const doLogin = async ({
  data = {},
  params = {},
}): Promise<LoginResponse | null> => {
  try {
    const response = await httpClient.post<LoginResponse>(
      "/api/v1/sessions",
      data,
      params
    )

    return response.data
  } catch {
    return null
  }
}

const doLogout = async (params = {}): Promise<void> => {
  try {
    await httpClient.delete("/api/v1/sessions", params)

    return undefined
  } catch {
    return undefined
  }
}

export const fetchSessionValidity = async (): Promise<boolean> => {
  const sessionTimeout = await getSessionTimeout()

  if (sessionTimeout > 0) {
    return true
  }

  return false
}

interface UseAuthenticationValue {
  authenticatedUser: User | null
  canEditSite: boolean
  isAuthenticated: boolean
  isAuthenticatedUserFetched: boolean
  isAuthenticatedUserLoading: boolean
  isAuthenticating: boolean
  isConsultantUser: boolean
  isFreeUser: boolean
  isGetAuthenticatedUserLoading: boolean
  isLoading: boolean
  isSessionValidFetched: boolean
  isSessionValidLoading: boolean
  isUserLoading: boolean
  signIn: UseMutateAsyncFunction<
    boolean,
    unknown,
    {
      email: string
      password: string
    }
  >
  signOut: UseMutateAsyncFunction<void>
}

export const useAuthentication = (): UseAuthenticationValue => {
  const queryClient = useQueryClient()

  // TODO: Set automatic refresh time.

  const signOutMutation = useCallback(async () => {
    await queryClient.cancelQueries(getAuthenticatedUserQueryKey)
    queryClient.setQueryData(isSessionValidQueryKey, false)
    queryClient.setQueryData(getAuthenticatedUserQueryKey, null)
    await doLogout()
    // TODO: Implement window.location.replace("/session_timeout") within respective component(s).
  }, [queryClient])

  const {
    data: isSessionValid,
    isFetched: isSessionValidFetched,
    isLoading: isSessionValidLoading,
  } = useQuery({
    cacheTime: Infinity,
    queryKey: isSessionValidQueryKey,
    queryFn: fetchSessionValidity,
    staleTime: Infinity,
  })

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    data: authenticatedUser,
    isFetched: isAuthenticatedUserFetched,
    isLoading: isAuthenticatedUserLoading,
  } = useQuery({
    cacheTime: Infinity,
    queryKey: getAuthenticatedUserQueryKey,
    queryFn: async () => {
      const userResult: User = await getUser()
      if (!userResult) {
        // TODO: Are there any other checks we need here?
        // await signOutMutation()
        return null
      }
      return userResult
    },
    staleTime: Infinity,
  })

  const { mutateAsync: signOut } = useMutation({
    mutationKey: signOutMutationKey,
    mutationFn: signOutMutation,
  })

  const { mutateAsync: signIn, isLoading: isAuthenticating } = useMutation({
    mutationKey: signInMutationKey,
    mutationFn: async (credentials: { email: string; password: string }) => {
      await queryClient.cancelQueries(getAuthenticatedUserQueryKey) // TODO: Test this
      const loginResult = await doLogin({ data: credentials })
      if (!loginResult?.success) {
        return false
      }
      // Combine getUser and signIn so we don't have a re-render where both return isLoading: false
      const userResult: User = await getUser()
      if (!userResult) {
        // await signOutMutation()
        return false
      }
      queryClient.setQueryData(isSessionValidQueryKey, true)
      queryClient.setQueryData(getAuthenticatedUserQueryKey, userResult)
      return !!userResult
    },
  })

  const canEditSite: boolean = !authenticatedUser
    ? false
    : authenticatedUser.role === UserRoleName.Admin ||
      authenticatedUser.role === UserRoleName.BuildingManager ||
      authenticatedUser.role === UserRoleName.ConsultantUser

  const isGetAuthenticatedUserLoading =
    isSessionValidLoading || isAuthenticatedUserLoading || isAuthenticating

  // This is purposely a tri-state boolean
  // When the app first loads, we don't know if a user is authenticated until the api returns
  const isAuthenticated: boolean | undefined = isGetAuthenticatedUserLoading
    ? undefined
    : isSessionValid && !!authenticatedUser

  const isConsultantUser: boolean | undefined = !authenticatedUser
    ? undefined
    : authenticatedUser.consultantUser

  const isFreeUser: boolean | undefined = !authenticatedUser
    ? undefined
    : authenticatedUser.freeUser

  return {
    signIn,
    signOut,
    authenticatedUser,
    canEditSite,
    isAuthenticatedUserFetched,
    isAuthenticatedUserLoading,
    isConsultantUser,
    isFreeUser,
    isLoading: isGetAuthenticatedUserLoading,
    isUserLoading: isGetAuthenticatedUserLoading,
    isGetAuthenticatedUserLoading,
    isAuthenticated,
    isAuthenticating,
    isSessionValidFetched,
    isSessionValidLoading,
  }
}
