import React, { useState } from "react"
import type { FC } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"

import { PasswordTextField } from "@/components/form/password-text-field"
import { RootPath } from "@/models/route"
import { Form, FormTextField, SubmitButton, useForm } from "@/nzds/forms"
import { useAuthentication } from "@/services/authentication"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useGtag } from "@/services/gtag"

import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Fade,
  Icon,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"

import { If } from "../if/if"
import { WhiteBox } from "../whiteBox"
import { loginSchema } from "./login.schema"
import type { LoginSchema } from "./login.schema"

export const Login: FC = () => {
  const { gtagData } = useGtag()
  const theme = useTheme()
  const { isFeatureEnabled } = useFeature()
  const isSignUpEnabled = isFeatureEnabled(FeatureFlags.APP_SIGN_UP, null)
  const { signIn } = useAuthentication()
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [isAccountError, setIsAccountError] = useState<boolean>(false)

  const defaultFormValues: LoginSchema = {
    email: "",
    password: "",
  }

  const form = useForm({
    schema: loginSchema,
    defaultValues: defaultFormValues,
  })

  const handlePasswordVisibleClick = (): void => {
    setIsPasswordVisible((isVisible) => !isVisible)
  }

  const handleSubmit: SubmitHandler<LoginSchema> = async (
    value
  ): Promise<void> => {
    setIsAccountError(false)

    const result = await signIn(value as Required<LoginSchema>)

    if (result) {
      // Must set the user_id before sending the login event
      gtagData?.gtag("set", {
        user_id: value.email,
      })

      gtagData?.gtag("event", "login", {
        method: "nzero",
      })
    } else {
      setIsAccountError(true)
    }
  }

  return (
    <Fade in={true}>
      <div>
        <WhiteBox>
          <Typography mb={4} textAlign="center" variant="h1">
            Log In
          </Typography>

          <Collapse in={isAccountError}>
            <Box pb={2}>
              <Alert severity="warning">No account found.</Alert>
            </Box>
          </Collapse>
          <Form<LoginSchema> form={form} onSubmit={handleSubmit}>
            <Box mb={1.5}>
              <FormTextField<LoginSchema>
                fullWidth
                id="email"
                label="Email"
                name="email"
              />
              <PasswordTextField<LoginSchema>
                id="password"
                isPasswordVisible={isPasswordVisible}
                label="Password"
                onPasswordVisibleClick={handlePasswordVisibleClick}
              />
              <Typography variant="caption" ml={0.5}>
                <Link
                  color="inherit"
                  component={RouterLink}
                  to={RootPath.ForgotPassword}
                  underline="hover"
                >
                  Forgot password?
                </Link>
              </Typography>
            </Box>

            <SubmitButton fullWidth>Log In with NZero</SubmitButton>
          </Form>

          <Typography
            mb={3.5}
            mt={3.5}
            sx={{ color: theme.palette.grey[400] }}
            variant="body2"
          >
            <Divider component="span">Or</Divider>
          </Typography>
          <Stack alignItems="center">
            <Button
              color="primary"
              endIcon={<Icon fontSize="small">key</Icon>}
              href="/sso_login"
              variant="outlined"
            >
              Log In with SSO
            </Button>
          </Stack>

          <If condition={isSignUpEnabled}>
            <Box mb={4.5} mt={4.5}>
              <Divider />
            </Box>
            <Box textAlign="center">
              <Typography mb={1} variant="body2">
                Don&rsquo;t have an account?
              </Typography>
              <Button
                color="primary"
                component={RouterLink}
                size="small"
                to={RootPath.SignUp}
                variant="outlined"
              >
                Sign Up
              </Button>
            </Box>
          </If>
        </WhiteBox>
      </div>
    </Fade>
  )
}
