import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import type { RouteObject } from "react-router-dom"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { RoleGuard } from "@/components/route/role-guard"
import { RootPath } from "@/models/route"

import Settings from "./components/settings/settings"

export const settingsRoutes: RouteObject[] = [
  {
    path: RootPath.Settings,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}
      >
        <RoleGuard disallowedRoles={["consultant_user"]}>
          <Settings />
        </RoleGuard>
      </ErrorBoundary>
    ),
  },
]
