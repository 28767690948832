import React from "react"
import type { FC } from "react"

import { useOrganizationContext } from "@/contexts"
import { useFeature } from "@/services/feature"

import { ReportingCard } from "../reportingCard/reportingCard"
import type { ReportingCardsProps } from "./reporting-cards.types"

export const ReportingCards: FC<ReportingCardsProps> = ({ cards }) => {
  const { organization } = useOrganizationContext()
  const { isFeatureEnabled } = useFeature()

  return cards
    .filter((card) => {
      return (
        card.featureFlag === undefined ||
        isFeatureEnabled(card.featureFlag, organization)
      )
    })
    .map((card) => (
      <ReportingCard key={card.title} card={card} organization={organization} />
    ))
}
