/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { FC } from "react"
import React, { useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import type { SubmitHandler } from "react-hook-form"

import {
  Form,
  FormCheckbox,
  FormTextField,
  SubmitButton,
  useForm,
} from "@/nzds"
import { HStack } from "@/nzds/layout"
import { passwordRequirementsMessage } from "@/schemas"
import { useCreateUser } from "@/services"
import type { CreateUserError } from "@/services"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useGtag } from "@/services/gtag"
import type { AxiosError } from "axios"

import { OpenInNew } from "@mui/icons-material"
import { Box, Fade, FormHelperText, Link, Typography } from "@mui/material"

import { PasswordTextField } from "../form/password-text-field"
import { If } from "../if/if"
import Page404 from "../nav/page404/page404"
import { WhiteBox } from "../whiteBox"
import type { SignUpSchema } from "./sign-up.schema"
import { signUpSchema } from "./sign-up.schema"
import { VerifyEmail } from "./verify-email/verify-email"

const hasServerError = (
  error: AxiosError<CreateUserError>,
  regex: RegExp
): boolean => {
  return !!error.response.data.errors.find((errorMessage) =>
    regex.test(errorMessage)
  )
}

export const SignUp: FC = () => {
  const { isFeatureEnabled } = useFeature()
  const { gtagData } = useGtag()

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const isSignUpEnabled = isFeatureEnabled(FeatureFlags.APP_SIGN_UP, null)

  const recaptchaRef = useRef<ReCAPTCHA>()

  const defaultFormValues: SignUpSchema = {
    confirmPassword: "",
    email: "",
    firstName: "",
    lastName: "",
    organizationName: "",
    password: "",
    recaptcha: "",
    title: "",
    termsAndConditions: false,
  }

  const form = useForm({
    schema: signUpSchema,
    defaultValues: defaultFormValues,
  })

  const { createUser, createUserIsLoading } = useCreateUser({
    onError: (error): void => {
      const emailDisallowedErrorMessage: string = hasServerError(
        error,
        /blacklisted email/i
      )
        ? "Must be a valid business email address"
        : ""

      const emailTakenErrorMessage: string = hasServerError(
        error,
        /email has already been taken/i
      )
        ? "Email is already taken"
        : ""

      const organizationNameTakenErrorMessage: string = hasServerError(
        error,
        /name/i
      )
        ? "Organization Name is already taken"
        : ""

      const recaptchaErrorMessage: string = hasServerError(error, /recaptcha/i)
        ? "reCAPTCHA verification failed. Please try again."
        : ""

      if (emailDisallowedErrorMessage) {
        form.setError("root.emailServerError", {
          message: emailDisallowedErrorMessage,
          type: error.response.statusText,
        })
      }

      if (emailTakenErrorMessage) {
        form.setError("root.emailServerError", {
          message: emailTakenErrorMessage,
          type: error.response.statusText,
        })
      }

      if (organizationNameTakenErrorMessage) {
        form.setError("root.organizationNameServerError", {
          message: organizationNameTakenErrorMessage,
          type: error.response.statusText,
        })
      }

      if (recaptchaErrorMessage) {
        form.setError("root.recaptchaServerError", {
          message: recaptchaErrorMessage,
          type: error.response.statusText,
        })
      }

      // reCAPTCHA is a one-time use, so must reset for any error
      recaptchaRef.current?.reset()
    },
    onSuccess: () => {
      setIsEmailSent(true)
      gtagData?.gtag("event", "sign_up")
    },
  })

  const handlePasswordVisibleClick = (): void => {
    setIsPasswordVisible((isVisible) => !isVisible)
  }

  const signUp = async (value: SignUpSchema): Promise<void> => {
    await createUser({
      company_name: value.organizationName,
      email: value.email,
      first_name: value.firstName,
      last_name: value.lastName,
      password: value.password,
      recaptcha: value.recaptcha,
      role: "free_user",
      title: value.title,
    })
  }

  const handleRecaptchaChange = (value: string | null): void => {
    form.setValue("recaptcha", value ?? "", {
      shouldDirty: true,
      shouldValidate: true,
    })

    form.clearErrors("root.recaptchaServerError")
  }

  const handleSubmit: SubmitHandler<SignUpSchema> = (value): void => {
    void signUp(value)
  }

  return isSignUpEnabled ? (
    <Fade in={true}>
      <div>
        <WhiteBox>
          <If condition={!isEmailSent}>
            <Typography mb={1.5} textAlign="center" variant="h1">
              Welcome to NZero
            </Typography>
            <Typography mb={4.5} textAlign="center">
              Get started&mdash;it&rsquo;s free. No credit card needed.
            </Typography>
            <Form<SignUpSchema> form={form} onSubmit={handleSubmit}>
              <Box mb={4}>
                <HStack spacing={2}>
                  <FormTextField<SignUpSchema>
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                  />
                  <FormTextField<SignUpSchema>
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                  />
                </HStack>

                <HStack spacing={2}>
                  <FormTextField<SignUpSchema>
                    error={Boolean(
                      form.formState.errors.root?.organizationNameServerError
                    )}
                    fullWidth
                    helperText={
                      form.formState.errors.root?.organizationNameServerError
                        ?.message
                    }
                    id="organizationName"
                    label="Organization Name"
                    name="organizationName"
                  />
                  <FormTextField<SignUpSchema>
                    fullWidth
                    id="title"
                    label="Role (Optional)"
                    name="title"
                  />
                </HStack>

                <FormTextField<SignUpSchema>
                  fullWidth
                  error={Boolean(form.formState.errors.root?.emailServerError)}
                  helperText={
                    form.formState.errors.root?.emailServerError?.message
                  }
                  id="email"
                  label="Email"
                  name="email"
                />

                <HStack spacing={2}>
                  <PasswordTextField<SignUpSchema>
                    helperText={passwordRequirementsMessage}
                    id="password"
                    isPasswordVisible={isPasswordVisible}
                    label="Password"
                    onPasswordVisibleClick={handlePasswordVisibleClick}
                  />
                  <PasswordTextField<SignUpSchema>
                    id="confirmPassword"
                    isPasswordVisible={isPasswordVisible}
                    label="Confirm Password"
                    onPasswordVisibleClick={handlePasswordVisibleClick}
                  />
                </HStack>

                <Box mb={1.5}>
                  <FormCheckbox<SignUpSchema>
                    id="termsAndConditions"
                    label={
                      <Typography component="span" variant="body2">
                        I accept the{" "}
                        <Link
                          href="https://nzero.com/privacy-policy/"
                          target="_blank"
                          underline="none"
                        >
                          <HStack
                            alignItems="center"
                            component="span"
                            display="inline-flex"
                            gap={0.25}
                          >
                            Terms and Service{" "}
                            <OpenInNew
                              sx={{
                                fontSize: 16,
                                position: "relative",
                                top: 1,
                              }}
                            />
                          </HStack>
                        </Link>{" "}
                        and I&rsquo;m authorized to accept for my company.
                      </Typography>
                    }
                    name="termsAndConditions"
                    size="small"
                  />
                </Box>

                <ReCAPTCHA
                  onChange={handleRecaptchaChange}
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY || ""}
                  size="normal"
                />
                <FormHelperText error>
                  {form.formState.errors.root?.recaptchaServerError?.message ??
                    " "}
                </FormHelperText>
              </Box>

              <SubmitButton
                disabled={createUserIsLoading}
                fullWidth
                loading={createUserIsLoading}
              >
                Sign Up
              </SubmitButton>
            </Form>
          </If>
          <If condition={isEmailSent}>
            <Fade in={true}>
              <div>
                <VerifyEmail email={form.getValues("email")} />
              </div>
            </Fade>
          </If>
        </WhiteBox>
      </div>
    </Fade>
  ) : (
    <Page404 />
  )
}
