import React from "react"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom"

import { AppLoader } from "@/components/route/app-loader"
import type { Organization } from "@/models/organization"
import { RootPath } from "@/models/route"
import type { User } from "@/models/user"
import { aboutRoutes } from "@/modules/about"
import { assistantRoutes } from "@/modules/assistant"
import { dashboardRoutes } from "@/modules/dashboard"
import { dataRoutes } from "@/modules/data"
import { financialRoutes } from "@/modules/financialSummary"
import { recommendationsRoutes } from "@/modules/recommendations"
import { reportRoutes } from "@/modules/report"
import { settingsRoutes } from "@/modules/settings/routes"
import { siteRoutes } from "@/modules/site"
import TamAdmin from "@/modules/tamAdmin/module"
import {
  fetchSessionValidity,
  getAuthenticatedUserQueryKey,
  getUser,
  isSessionValidQueryKey,
} from "@/services/authentication"
import { useGtag } from "@/services/gtag"
import {
  fetchAuthorizedOrganizations,
  useAuthorizedOrganizationsQueryKey,
} from "@/services/organization"
import { queryClient } from "@/utils/queryClient"

import { ForgotPassword } from "../forgot-password/forgot-password"
import { NewLayout } from "../nav/appLayout/newLayout"
import Page404 from "../nav/page404/page404"
import { RedirectToOrgRoute } from "../nav/redirect-to-org-route"
import { OrganizationGuard } from "../organization-guard"
import { ResetPassword } from "../reset-password/reset-password"
import { AuthenticatedRoute } from "../route/authenticated-route/authenticated-route"
import { OrgGuard } from "../route/orgGuard"
import { RoleGuard } from "../route/role-guard"
import { UnauthenticatedRoute } from "../route/unauthenticated-route/unauthenticated-route"
import { SignUp } from "../sign-up/sign-up"
import { SignIn } from "../signIn/signIn"
import { ValidateAccount } from "../validate-account/validate-account"

import "@/styles/application.scss"

interface AuthenticatedRouteLoaderValue {
  authorizedOrganizations: Organization[]
  isSessionValid: boolean
  user: User | undefined
}

const authenticatedRouteLoader =
  async (): Promise<AuthenticatedRouteLoaderValue> => {
    try {
      const isSessionValid = await queryClient.fetchQuery(
        isSessionValidQueryKey,
        fetchSessionValidity
      )

      if (isSessionValid) {
        const [user, authorizedOrganizations] = await Promise.all([
          queryClient.fetchQuery(getAuthenticatedUserQueryKey, getUser),
          queryClient.fetchQuery(
            useAuthorizedOrganizationsQueryKey,
            fetchAuthorizedOrganizations
          ),
        ])

        return {
          authorizedOrganizations,
          isSessionValid,
          user,
        }
      }

      return {
        authorizedOrganizations: [],
        isSessionValid,
        user: undefined,
      }
    } catch {
      console.error("Error preloading authenticated route data")
    }
  }

const router = createBrowserRouter([
  {
    element: <AppLoader />,
    children: [
      // Unauthenticated routes
      ...aboutRoutes,
      {
        path: RootPath.Root,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <SignIn />,
          },
        ],
      },
      {
        path: RootPath.SignUp,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <SignUp />,
          },
        ],
      },
      {
        path: `${RootPath.ValidateAccount}/:token`,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ValidateAccount />,
          },
        ],
      },
      {
        path: RootPath.ForgotPassword,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ForgotPassword />,
          },
        ],
      },
      {
        path: `${RootPath.ResetPassword}/:token`,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ResetPassword />,
          },
        ],
      },
      // Authenticated routes
      {
        element: <AuthenticatedRoute />,
        loader: authenticatedRouteLoader,
        children: [
          // TAM Admin pages
          {
            path: RootPath.TamAdmin,
            element: (
              <RoleGuard allowedRoles={["admin"]}>
                <TamAdmin />
              </RoleGuard>
            ),
          },
          {
            path: `${RootPath.TamAdmin}/*`,
            element: (
              <RoleGuard allowedRoles={["admin"]}>
                <TamAdmin />
              </RoleGuard>
            ),
          },
          // Main authenticated pages
          {
            element: <NewLayout />,
            children: [
              ...settingsRoutes,
              // Old routes that do not start with :orgId
              // Redirect to new routes
              {
                path: RootPath.Dashboard,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <RedirectToOrgRoute path={`/${RootPath.Dashboard}`} />
                    ),
                  },
                  {
                    path: "*",
                    element: (
                      <RedirectToOrgRoute path={`/${RootPath.Dashboard}`} />
                    ),
                  },
                ],
              },
              {
                path: RootPath.Organization,
                element: <RedirectToOrgRoute path={`/${RootPath.Financial}`} />,
              },
              {
                path: `${RootPath.Organization}/:orgId`,
                element: (
                  <OrgGuard>
                    <RedirectToOrgRoute path={`/${RootPath.Financial}`} />
                  </OrgGuard>
                ),
              },
              {
                path: RootPath.Recommendations,
                element: <RedirectToOrgRoute />,
              },
              {
                path: `${RootPath.Recommendations}/*`,
                element: <RedirectToOrgRoute />,
              },
              {
                path: RootPath.Report,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <RedirectToOrgRoute />,
                  },
                  {
                    path: ":orgId",
                    element: (
                      <OrgGuard>
                        <Outlet />
                      </OrgGuard>
                    ),
                    children: [
                      {
                        index: true,
                        element: <RedirectToOrgRoute />,
                      },
                      {
                        path: "*",
                        element: <RedirectToOrgRoute />,
                      },
                    ],
                  },
                ],
              },
              {
                path: RootPath.Site,
                element: <RedirectToOrgRoute />,
              },
              {
                path: `${RootPath.Site}/*`,
                element: <RedirectToOrgRoute />,
              },
              // New routing approach starting with :orgId
              {
                path: ":orgId",
                element: <OrganizationGuard />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="/" replace={true} />,
                  },
                  ...assistantRoutes,
                  ...dashboardRoutes,
                  ...dataRoutes,
                  ...financialRoutes,
                  ...recommendationsRoutes,
                  ...reportRoutes,
                  ...siteRoutes,
                ],
              },
              // Ensures authenticated route layout is rendered
              // when no matching route is found
              {
                path: "*",
                element: <Page404 />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
])

export const App: FC = () => {
  useGtag()
  const { i18n } = useTranslation()

  return i18n.isInitialized ? <RouterProvider router={router} /> : null
}
