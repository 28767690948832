// @ts-strict-ignore
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import { fetchMonthSummaries } from "./useMonthSummariesUtils"

export const useMonthSummaries = (
  siteId: string,
  dateRange: IRange<string>,
  options: { isDisabled?: boolean } = {}
) => {
  const queryKey = useQueryKey(ApiQueryName.MonthSummaries, "getMany", null, {
    siteId,
    dateRange,
  })

  const queryResult = useQuery({
    keepPreviousData: true,
    queryKey,
    queryFn: async () => fetchMonthSummaries(siteId, dateRange),
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    enabled: !!siteId && !!dateRange && !options.isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
