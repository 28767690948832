import { httpClient } from "@/utils/api"
import type { SuccessData } from "@/utils/api"

export interface TokenUser {
  email: string
  id: string
}

export interface GetUserByPasswordResetTokenData extends SuccessData {
  user: TokenUser
}

export const getUserByPasswordResetToken = async (
  token
): Promise<TokenUser | null> => {
  try {
    const response = await httpClient.get<GetUserByPasswordResetTokenData>(
      `/api/v1/password_reset/${token}`
    )

    return response.data.success ? response.data.user : null
  } catch {
    console.error("Error getting user by password reset token")
    return null
  }
}

export const updateUserPassword = async (
  token: string,
  params: { password: string }
): Promise<SuccessData | null> => {
  try {
    const response = await httpClient.patch<SuccessData>(
      `/api/v1/password_reset/${token}`,
      params
    )

    return response.data
  } catch {
    console.error("Error updating user password")
    return null
  }
}
