import React from "react"
import type { FC } from "react"

import { useAuthentication } from "@/services/authentication"
import { useData } from "@/services/data"

import Page404 from "../../nav/page404/page404"
import type { ConsultantDataGuardProps } from "./consultant-data-guard.types"

/**
 * A guard that displays a 404 UI when the user is a consultant and no data is available
 */
export const ConsultantDataGuard: FC<ConsultantDataGuardProps> = ({
  children,
}) => {
  const { isConsultantUser } = useAuthentication()
  const { dataHasData } = useData()

  // Hide UI from consultants
  return <>{isConsultantUser && !dataHasData ? <Page404 /> : children}</>
}
