import { useActiveOrganizationId } from "../organization"
import { useAvailableReportDates } from "../useAvailableReportDates"

interface UseDataValue {
  dataHasData: boolean
  dataIsFetched: boolean
}

/**
 * Determines if the active organization has the necessary data for the app to display
 *
 * @returns The data status
 */
export const useData = (): UseDataValue => {
  const { activeOrganizationId } = useActiveOrganizationId()
  const { availableReportDatesData, availableReportDatesIsFetched } =
    useAvailableReportDates(activeOrganizationId)

  return {
    dataHasData:
      availableReportDatesData?.start.isValid() &&
      availableReportDatesData?.end.isValid(),
    dataIsFetched: availableReportDatesIsFetched,
  }
}
