import { useMutation } from "@tanstack/react-query"

import { ApiQueryName } from "../../models/api"
import Meter from "../../models/meter"
import type { QueryMutation, UseCreateEntityOptions } from "../types"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useCreateMeter = ({
  onSuccess,
  onError,
}: UseCreateEntityOptions) => {
  const mutationKey = useQueryKey(ApiQueryName.Meter, "createOne")
  const { create } = useSpraypaintModel(Meter)
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      newAttributes,
    }: Parameters<QueryMutation<"createOne", ModelAttributes<Meter>>>[0]) => {
      const meter = await create(newAttributes)
      return meter
    },
    onSuccess: (meter) => {
      onSuccess?.(meter?.id)
      invalidateQueries([
        createQueryKey(ApiQueryName.OrganizationCounts, "getOne"),
        createQueryKey(ApiQueryName.BillingGroupCounts, "getOne"),
        createQueryKey(ApiQueryName.Meters, "getMany"),
      ])
    },
    onError: (error: Error) => {
      onError?.(error.message)
    },
  })

  return useMutationReturnHandle(mutationKey, mutationResult)
}
