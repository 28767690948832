import React, { useCallback, useEffect } from "react"
import type { FC } from "react"

import { ErrorDialog } from "@/components/error-dialog"
import { ErrorDialogContent } from "@/components/error-dialog-content"
import { UploadCsvDialog } from "@/components/upload-csv-dialog"
import { useDisclosure } from "@/nzds/utils"
import { startCase } from "lodash-es"

import CloseIcon from "@mui/icons-material/Close"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"

import type { UploadStepProps } from "./upload-step.types"

export const UploadStep: FC<UploadStepProps> = ({
  downloadTemplateButton,
  downloadTemplateDescription,
  successMessage,
  type,
  uploadDescription,
  uploadQuery,
}) => {
  const displayType = startCase(type)

  const { error, isLoading, isSuccess, mutate, reset } = uploadQuery()

  const {
    close: uploadClose,
    isOpen: uploadIsOpen,
    open: uploadOpen,
  } = useDisclosure()

  const {
    close: uploadSuccessClose,
    isOpen: uploadSuccessIsOpen,
    open: uploadSuccessOpen,
  } = useDisclosure()

  const {
    close: uploadErrorClose,
    isOpen: uploadErrorIsOpen,
    open: uploadErrorOpen,
  } = useDisclosure()

  const handleUploadClick = useCallback(() => {
    reset()
    uploadSuccessClose()
    uploadOpen()
  }, [reset, uploadOpen, uploadSuccessClose])

  const handleUpload = useCallback(
    (file: File): void => {
      mutate({ file })
    },
    [mutate]
  )

  // Handle upload success
  useEffect(() => {
    if (isSuccess) {
      uploadClose()
      uploadSuccessOpen()
    }
  }, [isSuccess, uploadClose, uploadSuccessOpen])

  // Handle upload error
  useEffect(() => {
    if (error) {
      uploadClose()
      uploadErrorOpen()
    }
  }, [error, uploadClose, uploadErrorOpen])

  return (
    <Stack gap={4}>
      <Stack gap={1}>
        <Typography>{downloadTemplateDescription}</Typography>
        <div>{downloadTemplateButton}</div>
      </Stack>
      <Stack gap={1}>
        <Typography>{uploadDescription}</Typography>
        <div>
          <Button
            color="primary"
            onClick={handleUploadClick}
            size="small"
            startIcon={<FileUploadOutlinedIcon />}
            variant="outlined"
          >
            {`Upload ${displayType} CSV`}
          </Button>
        </div>
        <Collapse in={uploadSuccessIsOpen} sx={{ pt: 1, width: "100%" }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={uploadSuccessClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity="success"
          >
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        </Collapse>
      </Stack>

      <UploadCsvDialog
        isSubmitting={isLoading}
        onClose={uploadClose}
        onUpload={handleUpload}
        open={uploadIsOpen}
        title={`Upload ${displayType} CSV`}
      />

      <ErrorDialog
        confirmText="Re-upload"
        details={<ErrorDialogContent error={error} />}
        id={`upload-${type}-error`}
        isOpen={uploadErrorIsOpen}
        message={"Error"}
        onClose={() => {
          uploadErrorClose()
          reset()
        }}
        onConfirm={uploadOpen}
        title={`Upload ${displayType} Error`}
      />
    </Stack>
  )
}
