import { useOrganizationContext } from "@/contexts"
import { ApiQueryName } from "@/models/api"
import { useQueryKey } from "@/services/utils/useQueryKey"
import type { UseQueryOptions } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"

import { getInterventions } from "./get-interventions"

interface UseGetInterventionsOptions {
  queryOptions?: Omit<UseQueryOptions, "queryKey" | "queryFn">
}

/**
 * Returns a list of intervention types
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetInterventions(227);
 */
export const useGetInterventions = (
  options: UseGetInterventionsOptions = {}
) => {
  const { organization } = useOrganizationContext()

  const queryKey = useQueryKey(
    ApiQueryName.RecommendationsSite,
    "getMany",
    null
  )

  let queryParams = {
    queryKey,
    queryFn: () => getInterventions(organization?.id),
  }

  if (options.queryOptions) {
    queryParams = {
      ...options.queryOptions,
      ...queryParams,
    }
  }

  const query = useQuery(queryParams)

  return query
}
