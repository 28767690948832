import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Outlet } from "react-router-dom"
import type { RouteObject } from "react-router-dom"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { ConsultantDataGuard } from "@/components/route/consultant-data-guard"
import { FeatureGuard } from "@/components/route/featureGuard/featureGuard"
import { LimitedAccessUserGuard } from "@/components/route/limited-access-user-guard"
import { RecommendationsPath, RootPath } from "@/models/route"
import { FeatureFlags } from "@/services/feature"

import { RecommendationsIndexRouteGuard } from "./pages/recommendations-index-route-guard"
import { RecommendationsPlanRoute } from "./pages/recommendations-plan-route/recommendations-plan-route"
import { SiteGoalsRoute } from "./pages/site-goals-route/site-goals-route"
import { SitePlanDetailsEditRoute } from "./pages/site-plan-details-edit-route/site-plan-details-edit-route"
import { SitePlanDetailsRoute } from "./pages/site-plan-details-route/site-plan-details-route"
import { SiteRecommendationsRoute } from "./pages/site-recommendations-route/site-recommendations-route"

export const recommendationsRoutes: RouteObject[] = [
  {
    path: RootPath.Recommendations,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}
      >
        <ConsultantDataGuard>
          <LimitedAccessUserGuard>
            <Outlet />
          </LimitedAccessUserGuard>
        </ConsultantDataGuard>
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <RecommendationsIndexRouteGuard />,
      },
      {
        path: RecommendationsPath.Plan,
        element: (
          <FeatureGuard feature={FeatureFlags.PLANNING}>
            <RecommendationsPlanRoute />
          </FeatureGuard>
        ),
      },
      {
        path: RecommendationsPath.Site,
        element: (
          <FeatureGuard feature={FeatureFlags.PLANNING}>
            <SiteRecommendationsRoute />
          </FeatureGuard>
        ),
      },
      {
        path: RecommendationsPath.SiteGoals,
        element: (
          <FeatureGuard feature={FeatureFlags.PLANNING}>
            <SiteGoalsRoute />
          </FeatureGuard>
        ),
      },
      {
        path: RecommendationsPath.SitePlan,
        element: (
          <FeatureGuard feature={FeatureFlags.PLANNING}>
            <SitePlanDetailsRoute />
          </FeatureGuard>
        ),
      },
      {
        path: RecommendationsPath.SitePlanEdit,
        element: (
          <FeatureGuard feature={FeatureFlags.PLANNING}>
            <SitePlanDetailsEditRoute />
          </FeatureGuard>
        ),
      },
    ],
  },
]
