// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from "react"

import { Page, PageHeaderActionBar } from "@/components"
import { HStack } from "@/nzds/layout"
import { useAvailableReportDates } from "@/services"
import type { Moment } from "moment"

import { Box, Collapse, Paper, Typography, useTheme } from "@mui/material"

import { useDashboardContext } from "../../../../components/dashboard/useDashboardContext"
import Legend from "../../../../components/graph/legend/legend"
import { MonthlyStackedAreaGraph } from "../../../../components/graph/monthly-stacked-area-graph/monthly-stacked-area-graph"
import { buildLegendItems } from "../../../../components/graph/monthly-stacked-area-graph/monthly-stacked-area-graph-util"
import type { AreaGraphMap } from "../../../../components/graph/types"
import useFilters from "../../../../hooks/useFilters/useFilters"
import { MetricType } from "../../../../models/decimal/decimal"
import DateFilter from "../../../../models/filter/dateFilter"
import SingleSelectStringFilter from "../../../../models/filter/singleSelectStringFilter"
import type { IRange } from "../../../../models/range"
import { UnitName } from "../../../../models/unit"
import { MonthRangePicker } from "../../../../nzds/inputs/month-range-picker"
import { MAX_MONTHS_IN_RANGE } from "../../../../utils/constants"
import {
  clampDateRange,
  getMonthsInRange,
  isValidCrossYearDateRange,
} from "../../../../utils/date"
import { pxToRem } from "../../../../utils/unit"
import { useEmissionsDateRange } from "../../services/useEmissionsDateRange/useEmissionsDateRange"
import { useMonthlyEmissionsGraphData } from "../../services/useMonthlyEmissionsGraphData/useMonthlyEmissionsGraphData"
import EmptyChart, {
  DATE_PICKER_ERROR_MESSAGE,
  DATE_RANGE_ERROR_SUBHEADER,
  DEFAULT_HEADER,
  EMPTY_HEADER,
} from "../site/emptyChart"
import type { SiteAnalysisFilters } from "../site/site.types"

const emissionsTooltipLabelsMap = {
  electricity: {
    label: "Electricity",
    metricFormattingComponentId: MetricType.Emission,
  },
  naturalGas: {
    label: "Natural Gas",
    metricFormattingComponentId: MetricType.Emission,
  },
}

const SiteAnalysis: React.FC = () => {
  const theme = useTheme()
  const { isSiteInfoOpen, orgId, site, siteHasNoData, siteInfoButton } =
    useDashboardContext()
  const { emissionsDateRange, setEmissionsDateRange } = useEmissionsDateRange()

  const { filters, setFilters } = useFilters<SiteAnalysisFilters>({
    start: DateFilter,
    end: DateFilter,
    energyUnit: SingleSelectStringFilter,
  })

  const [datePickerError, setDatePickerError] = useState<boolean>(false)

  const colors: string[] = [
    theme.palette.spectrum.purple[500],
    theme.palette.spectrum.cerulean[300],
  ]

  const emissionsMap: AreaGraphMap = {
    electricity: {
      label: "Electricity",
      color: colors[1],
    },
    naturalGas: {
      label: "Natural Gas",
      color: colors[0],
    },
  }

  const dateRange: IRange<Moment> = useMemo(
    () => ({
      start: filters.start.value,
      end: filters.end.value,
    }),
    [filters.start.value, filters.end.value]
  )

  const { availableReportDatesData } = useAvailableReportDates(orgId)

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { monthlyEmissionsGraphData, isMonthlyEmissionsGraphDataLoading } =
    useMonthlyEmissionsGraphData(orgId, dateRange, { siteId: site.id })

  const onDateChange = useCallback(
    (value: IRange<Moment>): void => {
      setFilters({
        start: new DateFilter(value.start),
        end: new DateFilter(value.end),
      })
      setEmissionsDateRange(value)
    },
    [setEmissionsDateRange, setFilters]
  )

  // Ensure query params are valid values
  useEffect(() => {
    if (
      availableReportDatesData?.start.isValid() &&
      availableReportDatesData.end.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !emissionsDateRange?.start?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !emissionsDateRange?.end?.isValid()
    ) {
      // Clamp the start and end dates to the availableReportDates
      if (!isValidCrossYearDateRange(dateRange, availableReportDatesData)) {
        const defaultStart = availableReportDatesData.end
          .clone()
          .startOf("year")
        const defaultEnd = availableReportDatesData.end.clone().endOf("month")
        setFilters(
          {
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            start: new DateFilter(defaultStart),
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            end: new DateFilter(defaultEnd),
          },
          { replace: true }
        )
        setEmissionsDateRange({
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          start: defaultStart,
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          end: defaultEnd,
        })
      } else {
        setEmissionsDateRange(dateRange)
      }
    } else if (
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      emissionsDateRange?.start?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      emissionsDateRange?.end?.isValid()
    ) {
      // Show error if date range is greater than 36 months
      const monthsInRange = getMonthsInRange(
        emissionsDateRange.start,
        emissionsDateRange.end
      )
      if (monthsInRange >= MAX_MONTHS_IN_RANGE) {
        setDatePickerError(true)
      } else {
        setDatePickerError(false)
      }
    }
    // migrate directories to strict mode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableReportDatesData,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    emissionsDateRange?.end,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    emissionsDateRange?.start,
    filters.end.value,
    filters.start.value,
    setEmissionsDateRange,
    emissionsDateRange,
    setFilters,
    setDatePickerError,
  ])

  // Set start and end date if they are not set in the url
  useEffect(() => {
    if (
      (!filters.start.value || !filters.end.value) &&
      availableReportDatesData?.end.isValid()
    ) {
      // Set filters to default if not stored in our emissionsDateRange service
      if (
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !emissionsDateRange?.start?.isValid() ||
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !emissionsDateRange?.end?.isValid() ||
        !isValidCrossYearDateRange(
          {
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            start: emissionsDateRange?.start,
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            end: emissionsDateRange?.end,
          },
          availableReportDatesData
        )
      ) {
        let reportDates = {
          start: availableReportDatesData.end.clone().startOf("year"),
          end: availableReportDatesData.end.clone().endOf("month"),
        }

        // Clamp date range if start date is before service hour min date
        reportDates = clampDateRange(reportDates, {
          start: availableReportDatesData.start.clone().startOf("month"),
          end: availableReportDatesData.end.clone().endOf("month"),
        })

        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const startDate = reportDates.start
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const endDate = reportDates.end

        setFilters(
          {
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            start: new DateFilter(startDate),
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            end: new DateFilter(endDate),
          },
          { replace: true }
        )
        setEmissionsDateRange({
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          start: startDate,
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          end: endDate,
        })
      }
      // Set filters to stored emissionsDateRange values
      else {
        setFilters(
          {
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            start: new DateFilter(emissionsDateRange?.start),
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            end: new DateFilter(emissionsDateRange?.end),
          },
          { replace: true }
        )
      }
    }
  }, [
    availableReportDatesData,
    availableReportDatesData?.end,
    availableReportDatesData?.start,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    emissionsDateRange?.end,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    emissionsDateRange?.start,
    filters.end.value,
    filters.start.value,
    setEmissionsDateRange,
    setFilters,
  ])

  const siteAnalysisOverviewTitle = "Emissions Overview"
  return (
    <>
      <PageHeaderActionBar>
        <HStack alignItems="center" justifyContent="space-between">
          <HStack alignItems="center" spacing={1}>
            <MonthRangePicker
              availableMaxMinMonths={availableReportDatesData}
              disabled={siteHasNoData}
              error={datePickerError}
              errorMessage={DATE_PICKER_ERROR_MESSAGE}
              onChange={onDateChange}
              value={dateRange}
            />
            <Typography
              component="span"
              sx={{ color: theme.palette.grey[400] }}
              variant="caption"
            >
              {MAX_MONTHS_IN_RANGE} months max
            </Typography>
          </HStack>
          <HStack>
            {siteInfoButton}
            <Collapse in={isSiteInfoOpen} orientation="horizontal">
              <Box width={pxToRem(320)} />
            </Collapse>
          </HStack>
        </HStack>
      </PageHeaderActionBar>
      <Page>
        <Paper sx={{ p: 3 }}>
          {siteHasNoData || datePickerError ? (
            <EmptyChart
              header={
                siteHasNoData ? EMPTY_HEADER : `Emissions ${DEFAULT_HEADER}`
              }
              subHeader={siteHasNoData ? undefined : DATE_RANGE_ERROR_SUBHEADER}
            />
          ) : (
            <>
              <Typography
                data-e2e="emissions-overview-title"
                mb={2}
                variant="h2"
              >
                {siteAnalysisOverviewTitle}
              </Typography>
              <Box height={theme.typography.pxToRem(344)}>
                {/* Mass lint disable */}
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                {monthlyEmissionsGraphData?.data?.length > 0 &&
                  !isMonthlyEmissionsGraphDataLoading && (
                    <MonthlyStackedAreaGraph
                      colors={colors}
                      keys={["naturalGas", "electricity"]}
                      legendAlign="right"
                      legendLayout="vertical"
                      legendVerticalAlign="top"
                      // migration to strict mode batch disable
                      // Mass lint disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                      rawData={monthlyEmissionsGraphData.data}
                      title={siteAnalysisOverviewTitle}
                      tooltipLabelsMap={emissionsTooltipLabelsMap}
                      unit={UnitName.MetricTonsOfCarbonDioxideEquivalent}
                    >
                      <Box ml={3}>
                        <Legend
                          items={buildLegendItems(
                            // Mass lint disable
                            // Mass lint disable
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                            monthlyEmissionsGraphData.totals,
                            emissionsMap
                          )}
                          itemsTitle="Emissions"
                          unit={UnitName.MetricTonsOfCarbonDioxideEquivalent}
                        />
                      </Box>
                    </MonthlyStackedAreaGraph>
                  )}
              </Box>
            </>
          )}
        </Paper>
      </Page>
    </>
  )
}

export default SiteAnalysis
