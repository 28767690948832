import type { Schemas } from "@/services/types"
import { useMutation } from "@tanstack/react-query"
import type { UseMutationOptions } from "@tanstack/react-query"

import type { UseUploadMutationVariables } from "../services.types"

type UseUploadAdditionalUsageOptions = Omit<
  UseMutationOptions<unknown, unknown, UseUploadMutationVariables>,
  "mutationFn"
>

const uploadAdditionalUsage = async ({ file }: UseUploadMutationVariables) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.warn("Upload additional usage not implemented: ", file)

      if (Math.random() < 0.5) {
        reject([
          {
            column: "C",
            description: "Invalid zip code",
            row: 1,
            value: "1234a",
          },
        ])
      } else {
        resolve({ success: true })
      }
    }, 1000)
  })
}

/**
 * Uploads a CSV file of additional usage data
 *
 * @example
 * const {
 *   error,
 *   isError,
 *   isLoading,
 *   mutateAsync
 * } = useUploadEmissions(options)
 *
 * await mutateAsync(file)
 */
export const useUploadAdditionalUsage = (
  options: UseUploadAdditionalUsageOptions = {}
) => {
  const mutationOptions = options ?? {}

  const mutation = useMutation<
    unknown,
    Schemas["EmissionProfileUploadError"][],
    UseUploadMutationVariables
  >(uploadAdditionalUsage, mutationOptions)

  return mutation
}
