import { useQuery } from "@tanstack/react-query"
import type { WhereClause } from "spraypaint"

import { ApiQueryName } from "../../models/api"
import type { IFetchApplicationRecordOptions } from "../../models/applicationRecord/applicationRecord"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"
import { fetchOrganizationalUnits } from "./utils"

/**
 * Fetches OrganizationalUnits and provides info on the state of the request from react-query.
 * @param organizationId - The organizationId to retrieve OUs for
 * @param options.clause - Used to construct graphiti filter query params https://www.graphiti.dev/js/reads/filtering
 * @param options.fetchOptions - Used to configure sorting and pagination for the response.
 * @example
 * const options = {clause: {level: 1}, fetchOptions:{order: "desc"}}
 * const { organizationalUnits, isOrganizationalUnitsLoading } = useOrganizationalUnits(5, options)
 */
export const useOrganizationalUnits = (
  organizationId: string,
  options?: {
    clause?: WhereClause
    disabled?: boolean
    fetchOptions?: Partial<IFetchApplicationRecordOptions>
  }
) => {
  const queryKey = useQueryKey(
    ApiQueryName.OrganizationalUnits,
    "getMany",
    null,
    {
      organizationId,
      options,
    }
  )

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => fetchOrganizationalUnits(organizationId, options),
    onError: (error) => {
      console.error("Error fetching organizational units:", error)
    },
    enabled: !options?.disabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
