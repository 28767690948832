import React, { useCallback, useEffect, useState } from "react"

import { useCountryOptions } from "@/nzds/utils"
import { State } from "country-state-city"

import { Divider, Grid, Typography } from "@mui/material"

import {
  CancelButton,
  CreateEntityButton,
  useOptions,
} from "../../../../../../../components/form"
import { TYPOGRAPHY_VARIANT } from "../../../../../../../models/typography"
import { useForm } from "../../../../../../../nzds/forms/use-form/use-form"
import {
  useBaCodes,
  useCreateSiteWithAddress,
  useCurrencies,
  useLocations,
} from "../../../../../../../services"
import type { OrgViewDialogCreateContentProps } from "../../../../../models/onboarding/onboarding"
import { ELECTRICITY_TYPE_OPTIONS } from "../../../../../models/onboarding/onboarding"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TamForm, useTamForm } from "../../../../tamForm"
import {
  TamFormSelectField,
  TamFormTextField,
} from "../../../../tamForm/formFields"
import type { Site } from "../../../../tamForm/schemas/site"
import { isAddressEmpty, schema } from "../../../../tamForm/schemas/site"

const OrgViewDialogSiteCreateContent = ({
  objectName,
  parentObjectIds,
  onClose,
  onSuccess,
  onError,
}: OrgViewDialogCreateContentProps): JSX.Element => {
  const { createOneSite } = useCreateSiteWithAddress({
    onSuccess,
    onError,
  })

  const [stateOptions, setStateOptions] = useState([])

  const countryOptions = useCountryOptions()

  const { currencies, isCurrenciesLoading } = useCurrencies()
  const currencyOptions = useOptions(currencies, ({ name, alphaCode, id }) => ({
    label: `${name} (${alphaCode})`,
    value: id,
  }))

  const { locations, isLocationsLoading } = useLocations()
  const locationOptions = useOptions(locations, ({ name, id }) => ({
    label: name,
    value: id,
  }))

  const { baCodes, isBaCodesLoading } = useBaCodes()
  const baCodeOptions = useOptions(
    baCodes,
    ({ name }) => ({
      label: name,
      value: name,
    }),
    null
  )

  const handleFormSubmit = (newAttributes) => {
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { address, currencyId, ...rest } = newAttributes
    // migration to strict mode batch disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const currency = currencies.find(({ id }) => id === currencyId)
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const attributes = {
      ...rest,
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
      address: isAddressEmpty(address) ? null : address,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currency,
      organizationId: parentObjectIds.organizationId,
      departmentId: parentObjectIds.departmentId ?? null,
    }
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    createOneSite(null, attributes)
    onClose()
  }

  const form = useForm({
    schema,
    mode: "all",
    reValidateMode: "onChange",
  })

  const addressDirty = form.getFieldState("address").isDirty
  const watchCountry = form.watch("address.country")
  const countryIsUSOrCA = ["US", "CA"].includes(watchCountry)

  const filterStatesAndProvinces = useCallback(
    (country: string) => {
      const states = State.getStatesOfCountry(country)?.map(
        ({ name, isoCode }) => ({
          label: name,
          value: isoCode,
        })
      )
      // Filters out minor territories which conveniently have an ISO code of 3 or more characters.
      if (countryIsUSOrCA) {
        return states.filter(({ value }) => value.length === 2)
      }
      return states
    },
    [countryIsUSOrCA]
  )

  // This effect updates the state select field options when the country changes.
  // If there is a selected state that is not in the new list of states, it is
  // reset to null.
  useEffect(() => {
    if (!watchCountry) {
      return
    }
    const selectedState = form.getValues("address.state")
    const states = filterStatesAndProvinces(watchCountry)
    setStateOptions(states)

    if (
      selectedState &&
      !states?.find(({ value }) => value === selectedState)
    ) {
      form.resetField("address.state", { defaultValue: "" })
    }
  }, [watchCountry, form, filterStatesAndProvinces])

  if (isCurrenciesLoading || isLocationsLoading || isBaCodesLoading) {
    return null
  }

  return (
    <TamForm
      data-testid="siteCreateForm"
      form={form}
      name="site"
      onSubmit={handleFormSubmit}
    >
      {/* This styling is needed since the actions will be part of the content */}
      <Grid container direction="row" sx={{ mb: 3 }}>
        {/* This styling is needed to provide space between the form and required text */}
        <Grid container direction="row" item spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <TamFormTextField<Site>
              dataE2E="site-name-input"
              label="Site Name"
              name="name"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TamFormSelectField<Site>
              dataE2E="site-location-input"
              label="Location"
              name="locationId"
              options={locationOptions}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TamFormTextField<Site>
              dataE2E="site-alias-input"
              label="Site Alias"
              name="siteAlias"
            />
          </Grid>

          <Grid item xs={6}>
            <TamFormSelectField<Site>
              dataE2E="ba-code-input"
              label="BA Code"
              name="baCode"
              options={baCodeOptions}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TamFormSelectField<Site>
              dataE2E="electricity-type-input"
              label="Electricity"
              name="electricityType"
              options={ELECTRICITY_TYPE_OPTIONS}
              required
            />
          </Grid>
          <Grid item xs={6}>
            {/* [Leaving space here for sinks and nodes.] */}
          </Grid>
          <Grid item xs={3}>
            <TamFormSelectField<Site>
              dataE2E="currency-input"
              label="Currency"
              name="currencyId"
              options={currencyOptions}
            />
          </Grid>
          <Grid item xs={3}>
            <TamFormTextField<Site>
              dataE2E="tariff-major-input"
              label="Tariff Major"
              name="tariffMajor"
            />
          </Grid>
          <Grid item xs={3}>
            <TamFormTextField<Site>
              dataE2E="year-built-input"
              label="Year Built"
              name="yearBuilt"
            />
          </Grid>
          <Grid item xs={3}>
            <TamFormTextField<Site> label="Square Feet" name="sqFt" />
          </Grid>
          <Grid item xs={6}>
            <TamFormTextField<Site>
              dataE2E="notes-input"
              label="Notes"
              name="notes"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography>Address (Optional)</Typography>
          </Grid>
          <Grid item xs={4}>
            <TamFormSelectField<Site>
              dataE2E="site-country-input"
              label="Country"
              name="address.country"
              options={countryOptions}
              required={addressDirty}
            />
          </Grid>
          <Grid item xs={4}>
            <TamFormTextField<Site>
              dataE2E="address-line1-input"
              label="Site Address Line 1"
              name="address.address_line_1"
              required={addressDirty}
            />
          </Grid>
          <Grid item xs={4}>
            <TamFormTextField<Site>
              dataE2E="address-line2-input"
              label="Site Address Line 2"
              name="address.address_line_2"
            />
          </Grid>

          <Grid item xs={4}>
            <TamFormTextField<Site>
              dataE2E="site-city-input"
              label="City"
              name="address.city"
              required={addressDirty}
            />
          </Grid>
          <Grid item xs={4}>
            <TamFormSelectField<Site>
              dataE2E="site-state-input"
              label="State"
              name="address.state"
              options={stateOptions}
              required={addressDirty && countryIsUSOrCA}
            />
          </Grid>
          <Grid item xs={4}>
            <TamFormTextField<Site>
              dataE2E="site-postal-input"
              label="Postal Code"
              name="address.postal_code"
              required={addressDirty}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={TYPOGRAPHY_VARIANT.body2}>*required</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        item
        justifyContent="flex-end"
        spacing={1}
      >
        <Grid item>
          <CancelButton onCancel={onClose} />
        </Grid>
        <Grid item>
          <CreateEntityButton entityName={objectName} />
        </Grid>
      </Grid>
    </TamForm>
  )
}

export default OrgViewDialogSiteCreateContent
