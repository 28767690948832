import { useMutation } from "@tanstack/react-query"

import { ApiQueryName } from "../../models/api"
import OrganizationalUnit from "../../models/organizationalUnit"
import { createQueryKey } from "../utils/createQueryKey"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useQueryKey } from "../utils/useQueryKey"

export const useDeleteOrganizationalUnit = () => {
  const mutationKey = useQueryKey(ApiQueryName.OrganizationalUnit, "deleteOne")
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async (id: string) => {
      const { data: orgUnitInstance } = await OrganizationalUnit.where({
        id,
      }).first()

      if (orgUnitInstance) {
        await orgUnitInstance.destroy()
      } else {
        throw new Error(`Organizational unit with id ${id} not found`)
      }
    },
    onSuccess: () => {
      invalidateQueries([
        createQueryKey(ApiQueryName.OrganizationalUnits, "getMany"),
        createQueryKey(ApiQueryName.OrganizationHierarchy, "getOne"),
      ])
    },
  })

  return mutationResult
}
