import React, { useCallback, useState } from "react"
import type { FC } from "react"

import { DialogTitle } from "@/nzds/feedback/dialog-title/dialog-title"
import { HStack } from "@/nzds/layout"
import { filesize } from "filesize"

import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material"

import { UploadFile } from "../upload-file"
import type { UploadCsvDialogProps } from "./upload-csv-dialog.types"

export const UploadCsvDialog: FC<UploadCsvDialogProps> = ({
  isSubmitting,
  onClose,
  onUpload,
  open,
  title,
}) => {
  const [file, setFile] = useState<File | undefined>(undefined)

  const fileType = "csv"

  const handleClose = useCallback((): void => {
    onClose()
  }, [onClose])

  const handleFileChange = useCallback((newFile: File | undefined): void => {
    setFile(newFile)
  }, [])

  const handleUpload = useCallback((): void => {
    if (file) {
      onUpload(file)
    }
  }, [file, onUpload])

  return (
    <Dialog
      aria-describedby="upload-dialog-description"
      aria-labelledby="upload-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="upload-dialog-title" onClose={handleClose}>
        {title ?? `Upload ${fileType.toUpperCase()}`}
      </DialogTitle>
      <DialogContent id="upload-dialog-description">
        <Stack gap={1}>
          <UploadFile
            fileType={fileType}
            isLoading={isSubmitting}
            onChange={handleFileChange}
          />
          <HStack justifyContent="space-between">
            <Typography variant="body2">
              Supported file type: {fileType.toUpperCase()}
            </Typography>
            <Typography variant="body2">
              Max file size: {filesize(20 * 1024 * 1024, { standard: "jedec" })}
            </Typography>
          </HStack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={!file || isSubmitting}
          loading={isSubmitting}
          onClick={handleUpload}
          variant="contained"
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
