import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import { useOrganizationContext } from "@/contexts"
import { FeatureFlags, useFeature } from "@/services/feature"
import type { i18n } from "i18next"
import { groupBy, max } from "lodash-es"
import moment from "moment"

import useMeasure from "../../hooks/useMeasure/useMeasure"
import { ElectricityType } from "../../models/energyAllocation"
import type MonthSummary from "../../models/monthSummary"
import type { UnitName } from "../../models/unit"
import { calculateMeteredKwhByProduct } from "../../utils"
import { gray500 } from "../../utils/colors"
import type { TIME_RANGES } from "../../utils/constants"
import {
  CENTS_PER_DOLLAR,
  GRAPH_COST_OR_CARBON_INTENSITY,
  GRAPH_DETAIL_MODAL_ORIENTATION,
  KWH_PER_MWH,
} from "../../utils/constants"
import { translateDate } from "../../utils/date"
import { sharedDropdownFormat } from "../../utils/formatters"
import { goToTimeRange } from "../../utils/urls"
import type { DateRangeWithType } from "../graph/dateRangeWithType"
import type { GraphData } from "../graph/graphData"
import Product, { ProductType } from "../graph/product"
import { GraphFlyout } from "../graphFlyout"
import { GraphLegend } from "../graphLegend"
import { LoadingSpinner } from "../loadingSpinner"
import type { Config } from "../tooltip"
import { Tooltip, emptyConfig } from "../tooltip"
import { MonthlyEnergyProfileGraph } from "./monthlyEnergyProfileGraph"

const avgCost = (meteredKwh: number, products: Product[]) => {
  const cost = products.reduce((sum, p) => sum + Number(p.cost), 0)
  return cost / CENTS_PER_DOLLAR / (meteredKwh / KWH_PER_MWH)
}

const getMaxVolumeDomain = (graphData: GraphData[]): number => {
  if (graphData.length === 0) {
    return NaN
  }

  const totalKwhs = graphData.map((graphDatum) =>
    Math.max(
      Math.abs(graphDatum.lineValue) || 0,
      Math.abs(graphDatum.displayKwh)
    )
  )
  return max(totalKwhs) * 1.1
}

const getGraphDataByMonth = (
  monthSummaries: MonthSummary[] | undefined,
  month: string,
  i18nService: i18n
): GraphData => {
  const monthSummary: MonthSummary | undefined = monthSummaries?.filter(
    (monthlySummary) =>
      moment(monthlySummary.startDate).format("YYYY-MM") === month
  )[0]
  const product = new Product({
    id: monthSummary?.id,
    kwh: monthSummary?.meteredKwh,
    taggedKwh: monthSummary?.meteredKwh,
    co2eLbs: parseFloat(monthSummary?.co2eLbs),
    price: monthSummary?.meteredKwh,
    cost: Number(monthSummary?.costDollars) * CENTS_PER_DOLLAR,
    imbalanceKwh: monthSummary?.meteredKwh,
    priceRange: { min: 0, max: monthSummary?.meteredKwh },
    order: 0,
    electricityType: ElectricityType.DirectAccess,
  })
  product.dailyAvgKwh = monthSummary?.avgKwhPerDay
  product.confirmName = "Utility Product"
  product.productType = ProductType.MarketConfirm
  const graphDatum: GraphData = {
    barLabel: translateDate(i18nService, moment(month), {
      month: "numeric",
      year: "numeric",
    }),
    barLabelColor: gray500,
    hour: moment(month),
    hoverLabel: moment(month).format(sharedDropdownFormat),
    lineValue: monthSummary?.meteredKwh,
    id: 0,
    hours: [moment(month)],
    avgCost: avgCost(monthSummary?.meteredKwh, [product]),
    meteredKwh: calculateMeteredKwhByProduct(product),
    co2eLbs: Number.parseFloat(monthSummary?.co2eLbs),
    displayKwh: monthSummary?.meteredKwh,
    products: [product],
    serviceHourIds: [],
    carbonIntensity: monthSummary
      ? Number.parseFloat(monthSummary.co2eLbs) / monthSummary.meteredKwh
      : 0,
  }
  return graphDatum
}

const monthSummariesToGraphData = (
  timeRange: DateRangeWithType,
  monthSummaries: MonthSummary[] | undefined,
  i18nService: i18n
): GraphData[] => {
  const graphdata: GraphData[] = []
  if (monthSummaries?.length > 0) {
    for (
      let month = timeRange.startDate.clone();
      month.diff(timeRange.endDate.startOf("month"), "months") <= 0;
      month.add(1, "months")
    ) {
      graphdata.push(
        getGraphDataByMonth(
          monthSummaries,
          month.format("YYYY-MM"),
          i18nService
        )
      )
    }
  }
  return graphdata
}

const getMaxAvgCostDomain = (graphData: GraphData[]): number => {
  if (graphData.length === 0) {
    return NaN
  }

  const avgCosts = graphData.map((datum) => datum.avgCost)
  return (max(avgCosts) || 0) * 2
}

const getMaxAvgCarbonIntensityDomain = (graphData: GraphData[]): number => {
  if (graphData.length === 0) {
    return NaN
  }

  const avgCarbonIntensities = graphData.map((datum) => datum.carbonIntensity)
  return (max(avgCarbonIntensities) || 0) * 2
}

interface MonthlyEnergyProfileProps {
  axisUnit
  isBundledEnergyCustomer: boolean
  isLoading: boolean
  monthlySummary: MonthSummary[] | undefined
  timeRange: {
    endDate: moment.Moment
    startDate: moment.Moment
    timeRange: TIME_RANGES
  }
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
}

export const MonthlyEnergyProfileGraphContainer = ({
  axisUnit,
  isLoading,
  monthlySummary,
  timeRange,
  wattUnit,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isBundledEnergyCustomer,
}: MonthlyEnergyProfileProps) => {
  const { i18n: i18nService } = useTranslation()
  const navigate = useNavigate()
  const { ref: containerRef, width: pageWidth } = useMeasure()
  const [hoverXPos, setHoverXPos] = useState<number | null>(null)
  const [hoverYPos, setHoverYPos] = useState<number | null>(null)
  const [activeHour, setActiveHour] = useState<number | null>(null)
  const [graphDetailOrientation, setGraphDetailOrientation] =
    useState<GRAPH_DETAIL_MODAL_ORIENTATION>(
      GRAPH_DETAIL_MODAL_ORIENTATION.RIGHT
    )
  const [showMeterData, setShowMeterData] = useState(true)

  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const isEpgCostCarbonLineEnabled = isFeatureEnabled(
    FeatureFlags.EPG_COST_CARBON_LINE,
    organization
  )

  const [showCostOrCarbonIntensity, setShowCostOrCarbonIntensity] =
    useState<GRAPH_COST_OR_CARBON_INTENSITY>(
      isEpgCostCarbonLineEnabled
        ? GRAPH_COST_OR_CARBON_INTENSITY.NONE
        : GRAPH_COST_OR_CARBON_INTENSITY.COST
    )
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showAvgCostLine, setShowAvgCostLine] = useState(true)
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tooltipConfig, setTooltipConfig] = useState<Config>(emptyConfig)
  const location = useLocation()

  const graphData: GraphData[] = monthSummariesToGraphData(
    timeRange,
    monthlySummary,
    i18nService
  )

  const graphDataGroups: GraphData[][] = Object.values(
    groupBy(graphData, () => "")
  )
  graphDataGroups.sort((a, b) => a[0].hour.unix() - b[0].hour.unix())
  const maxVolumeDomain = getMaxVolumeDomain(graphData)
  const maxAvgCostDomain = getMaxAvgCostDomain(graphData)
  const maxAvgCarbonIntensityDomain = getMaxAvgCarbonIntensityDomain(graphData)

  const onBarClick = (selectedGraphData: GraphData) => {
    goToTimeRange({
      timeRange: {
        startDate: selectedGraphData.hour,
        endDate: selectedGraphData.hour.clone().endOf("month"),
        timeRange: timeRange.timeRange,
      },
      navigate,
      location,
    })
  }

  const handleShowCostOrCarbonIntensityChange = (
    value: GRAPH_COST_OR_CARBON_INTENSITY
  ) => {
    setShowCostOrCarbonIntensity(value)
  }

  const onHover = ({
    xPos,
    yPos,
    orientation,
    timestamp,
  }: {
    orientation: GRAPH_DETAIL_MODAL_ORIENTATION
    timestamp: number
    xPos: number | null
    yPos: number
  }) => {
    setHoverXPos(xPos)
    setHoverYPos(yPos)
    setActiveHour(timestamp)
    setGraphDetailOrientation(orientation)
  }

  const headers: JSX.Element[] = [
    <>Daily Avg.</>,
    <>Total Volume</>,
    <>Total Cost</>,
  ]

  return (
    <div className="dashboard-data" style={{ marginBottom: 0 }}>
      <div ref={containerRef} className="dashboard-data__visualization">
        {isLoading && (
          <LoadingSpinner className="dashboard-data__loading">
            Loading graph...
          </LoadingSpinner>
        )}

        <MonthlyEnergyProfileGraph
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          axisUnit={axisUnit}
          graphDataGroups={graphDataGroups}
          isFlyoutActive={!!hoverXPos}
          isLoading={isLoading}
          maxAvgCarbonIntensityDomain={maxAvgCarbonIntensityDomain}
          maxAvgCostDomain={maxAvgCostDomain}
          maxVolumeDomain={maxVolumeDomain}
          onBarClick={onBarClick}
          onHover={onHover}
          pageWidth={pageWidth}
          showCostOrCarbonIntensity={showCostOrCarbonIntensity}
          showMeterData={showMeterData}
          timeRange={timeRange.timeRange}
          wattUnit={wattUnit}
        />
        {tooltipConfig.content.length > 0 && <Tooltip {...tooltipConfig} />}
        {graphData
          .filter((graphDatum) => graphDatum.hour.unix() === activeHour)
          .map((graphDatum, idx) => (
            <GraphFlyout
              key={idx}
              dateRangeWithType={timeRange}
              graphDatum={graphDatum}
              headers={headers}
              orientation={graphDetailOrientation}
              wattUnit={wattUnit}
              x={hoverXPos}
              y={hoverYPos}
            />
          ))}
        <GraphLegend
          graphIsLoading={isLoading}
          showCostOrCarbonIntensityLine={showCostOrCarbonIntensity}
          showMeterData={showMeterData}
          toggleShowCostOrCarbonIntensityLine={
            handleShowCostOrCarbonIntensityChange
          }
          toggleShowMeterData={() => {
            setShowMeterData(!showMeterData)
          }}
          wattUnit={wattUnit}
        />
      </div>
    </div>
  )
}
