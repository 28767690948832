import React from "react"
import type { FC } from "react"
import { ErrorBoundary } from "react-error-boundary"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"

import OrganizationDashboard from "../organizationDashboard"
import OrganizationDashboardResolver from "../organizationDashboard.resolver"

export const OrganizationDashboardIndexRoute: FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={logError}>
      <OrganizationDashboardResolver>
        <OrganizationDashboard />
      </OrganizationDashboardResolver>
    </ErrorBoundary>
  )
}
