import { useMemo } from "react"

import { ApiQueryName } from "@/models/api"
import type { IRange } from "@/models/range"
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { UseQueryResult } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"

import type {
  GresbData,
  GresbEnergy,
  GresbGhg,
  GresbReport,
  GresbWater,
} from "../../models/gresb"
import {
  GresbReportName,
  gresbEnergyReportColumns,
  gresbGhgReportColumns,
  gresbWaterReportColumns,
} from "../../models/gresb"
import { getReportFileName, getReportRows } from "../../util/util"
import { fetchGresbReport } from "./useGresbReportUtils"

/**
 * A service that returns a GRESB report
 *
 * @returns The report
 * @example
 * const { gresbReport, isGresbReportFetched, isGresbReportLoading } = useGresbReport()
 */
export const useGresbReport = (
  orgId: string,
  dateRange: IRange<Moment>,
  isDataReady: boolean
) => {
  const queryKey = useQueryKey(ApiQueryName.GresbReport, "getOne", null, {
    orgId,
    start: dateRange?.start?.format("YYYY-MM-DD"),
    end: dateRange?.end?.format("YYYY-MM-DD"),
  })
  const queryResult = useQuery<GresbData>({
    enabled: !!orgId && !!dateRange?.start && !!dateRange?.end,
    queryFn: () => fetchGresbReport(orgId, dateRange),
    queryKey,
  })

  const gresbReport: GresbReport = useMemo(() => {
    if (queryResult.data) {
      return {
        energy: {
          name: getReportFileName(
            GresbReportName.Energy,
            dateRange,
            isDataReady
          ),
          data: {
            columns: gresbEnergyReportColumns,
            rows: getReportRows<GresbEnergy>(queryResult.data.energy),
          },
        },
        ghg: {
          name: getReportFileName(GresbReportName.Ghg, dateRange, isDataReady),
          data: {
            columns: gresbGhgReportColumns,
            rows: getReportRows<GresbGhg>(queryResult.data.ghg),
          },
        },
        water: {
          name: getReportFileName(
            GresbReportName.Water,
            dateRange,
            isDataReady
          ),
          data: {
            columns: gresbWaterReportColumns,
            rows: getReportRows<GresbWater>(queryResult.data.water),
          },
        },
      }
    }

    return null
  }, [queryResult.data, dateRange, isDataReady])

  const modifiedQueryResult = {
    ...queryResult,
    data: gresbReport,
  } as UseQueryResult<GresbReport>

  return useRenamedQueryResult(queryKey, modifiedQueryResult)
}
