import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import type { RouteObject } from "react-router-dom"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { UnauthenticatedRoute } from "@/components/route/unauthenticated-route/unauthenticated-route"
import { RootPath } from "@/models/route"

import { AboutIndex } from "./pages/about-index"

export const aboutRoutes: RouteObject[] = [
  {
    path: RootPath.About,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}
      >
        <UnauthenticatedRoute />
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <AboutIndex />,
      },
    ],
  },
]
