import axios from "axios"
import type { AxiosInstance } from "axios"

import { initCaseResponseInterceptor } from "./interceptors"

export interface SuccessData {
  success: boolean
}

export interface ErrorData extends SuccessData {
  error: string
  success: boolean
}

export const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL ?? "",
})

// Set up interceptors on the Axios instance
initCaseResponseInterceptor(httpClient)

export const parseFloatMetaStatSum = (metaStatSum: string | undefined) =>
  metaStatSum ? parseFloat(metaStatSum) : 0
