import type { FC } from "react"
import React, { useState } from "react"
import type { SubmitHandler } from "react-hook-form"

import { PasswordTextField } from "@/components/form/password-text-field"
import { passwordRequirementsMessage } from "@/schemas"

import { Box, Typography } from "@mui/material"

import { Form, SubmitButton, useForm } from "../../../nzds"
import type { ResetPasswordSchema } from "./reset-password-form.schema"
import { resetPasswordSchema } from "./reset-password-form.schema"
import type { ResetPasswordFormProps } from "./reset-password-form.types"

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  onSubmit,
  user,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const defaultFormValues: ResetPasswordSchema = {
    confirmPassword: "",
    password: "",
  }

  const form = useForm({
    schema: resetPasswordSchema,
    defaultValues: defaultFormValues,
  })

  const handlePasswordVisibleClick = (): void => {
    setIsPasswordVisible((isVisible) => !isVisible)
  }

  const handleSubmit: SubmitHandler<ResetPasswordSchema> = (value): void => {
    onSubmit(value)
  }

  return (
    <>
      <Typography mb={4}>
        {`Enter a new password for ${user?.email}`}
      </Typography>

      <Form<ResetPasswordSchema> form={form} onSubmit={handleSubmit}>
        <Box mb={1.5}>
          <PasswordTextField<ResetPasswordSchema>
            helperText={passwordRequirementsMessage}
            id="password"
            isPasswordVisible={isPasswordVisible}
            label="New Password"
            onPasswordVisibleClick={handlePasswordVisibleClick}
          />
          <PasswordTextField<ResetPasswordSchema>
            id="confirmPassword"
            isPasswordVisible={isPasswordVisible}
            label="Confirm New Password"
            onPasswordVisibleClick={handlePasswordVisibleClick}
          />
        </Box>

        <SubmitButton fullWidth>Reset Password</SubmitButton>
      </Form>
    </>
  )
}
