// @ts-strict-ignore
import { ApiQueryName } from "@/models/api"
import type { IRange } from "@/models/range"
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"

import { fetchYtdMeteredKwh } from "./useYtdMeteredKwhUtils"

/**
 * Fetches YTD metered kWh data for a site
 *
 * @example
 * const { ytdMeteredKwh, isYtdMeteredKwhLoading, isYtdMeteredKwhError } =
 * useYtdMeteredKwh({moment(), moment()}, "8")
 */
export const useYtdMeteredKwh = (
  timeWindow: IRange<Moment>,
  siteId: string
) => {
  const queryKey = useQueryKey(ApiQueryName.YtdMeteredKwh, "getOne", null, {
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    start: timeWindow?.start?.format("YYYY-MM-DD"),
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    end: timeWindow?.end?.format("YYYY-MM-DD"),
    siteId,
    ignoreCurrency: true,
  })
  const queryResult = useQuery<{ meteredKwh: number }>({
    enabled:
      !!siteId &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!timeWindow?.start?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!timeWindow?.end?.isValid(),
    queryFn: () => fetchYtdMeteredKwh({ timeWindow, siteId }),
    queryKey,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
