import type { FC } from "react"
import React from "react"

import { HStack } from "@/nzds/layout"

import { AppBar, Box, Button, Link, Toolbar } from "@mui/material"

import { NzeroLogo } from "../../../../components/logo/nzeroLogo"
import { RootPath } from "../../../../models/route"
import TamAdminUserMenu from "../tamAdminUserMenu/tamAdminUserMenu"

export const TamAdminAppBar: FC = () => (
  <AppBar position="absolute">
    <Toolbar sx={{ justifyContent: "space-between", px: 3, py: 2 }}>
      <Box color="text.primary">
        <NzeroLogo width={64} />
      </Box>
      <HStack alignItems="flex-start" gap={2}>
        <Button
          component={Link}
          href={RootPath.DeveloperAdmin}
          size="small"
          variant="outlined"
        >
          Back to DAT
        </Button>
        <TamAdminUserMenu />
      </HStack>
    </Toolbar>
  </AppBar>
)
