import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import {
  ComplianceDates,
  ComplianceEmissionFactorName,
  ComplianceEmissionFactorValue,
  ComplianceFines,
  ComplianceReduction,
  ComplianceStandards,
  ComplianceStatus,
  ComplianceStatusReduced,
  ComplianceTargetUsageIntensity,
  ComplianceTotalEmissions,
  ComplianceUsageIntensity,
} from "@/components/compliance"
import { useOrganizationContext } from "@/contexts"
import { useCurrencySymbol } from "@/hooks/useCurrencySymbol/useCurrencySymbol"
import { UnitName } from "@/models/unit"
import {
  DataGridColumnHeader,
  baseColumnDef,
  baseNumberColumnDef,
} from "@/nzds/data-grid"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useCommonSiteDataGridColumns } from "@/utils/site/use-common-site-data-grid-columns"
import { translateUnit } from "@/utils/unit"

import { Stack, Typography } from "@mui/material"
import type {
  GridColDef,
  GridColumnVisibilityModel,
  GridPinnedColumns,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridSortModel,
} from "@mui/x-data-grid-pro"

import type { SiteRowData } from "../../../../services/use-get-site-explorer"
import { useCommonGridColumns } from "../../../utils/use-common-grid-columns/use-common-grid-columns"

export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
  electricity_ef: false, // Electricity EF Value
  electricity_source: false, // Electricity EF
  natural_gas_ef: false, // Natural Gas EF Value
  natural_gas_source: false, // Natural Gas EF
  sq_ft: false, // Building Area
  total_emissions: false, // Combined Emissions
}

export const defaultPinnedColumns: GridPinnedColumns = {
  left: ["rowNumber", "site_name"],
  right: ["isPlanned"],
}

export const defaultSortModel: GridSortModel = [
  {
    field: "site_name",
    sort: "desc",
  },
]

interface UseComplianceGridColumnsProps {
  buildingTypes: string[] | undefined
  handleRowEditCancelClick: (id: GridRowId) => void
  handleRowEditClick: (id: GridRowId) => void
  handleRowEditSaveClick: (id: GridRowId) => void
  rowModesModel: GridRowModesModel
}

export const useComplianceGridColumns = (
  props: UseComplianceGridColumnsProps
): GridColDef<SiteRowData>[] => {
  const { i18n } = useTranslation()
  const currencySymbol = useCurrencySymbol()

  const commonGridColumns = useCommonGridColumns(props)
  const commonSiteDataGridColumns = useCommonSiteDataGridColumns<SiteRowData>()

  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const columns: GridColDef<SiteRowData>[] = useMemo(() => {
    return [
      {
        ...commonGridColumns.rowNumber,
      },
      {
        ...commonGridColumns.siteName,
      },
      {
        ...baseColumnDef,
        field: "in_compliance",
        headerName: "Compliance Status",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceStatusReduced regulations={params.row.regulations} />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="in_compliance"
            headerName="Compliance Status"
          />
        ),
        sortable: false,
        width: 160,
      },
      {
        ...baseColumnDef,
        field: "regulation_name",
        headerName: "Compliance Standard",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceStandards regulations={params.row.regulations} />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="regulation_name"
            headerName="Compliance Standard"
            tooltipContent={
              <>
                <Typography mb={1.5} variant="body2">
                  Indicates the estimated extent to which each site aligns with
                  state and federal regulations or standards.
                </Typography>
                <Stack mb={1} ml={0.5} spacing={0.5}>
                  <ComplianceStatus isCompliant={true} />
                  <ComplianceStatus isCompliant={false} />
                </Stack>
              </>
            }
          />
        ),
        sortable: false,
      },
      {
        ...baseColumnDef,
        field: "effective_date",
        headerName: "Compliance Period",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceDates regulations={params.row.regulations} />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="effective_date"
            headerName="Compliance Period"
          />
        ),
        sortable: false,
        width: 160,
      },
      {
        ...baseNumberColumnDef,
        field: "compliance_penalties",
        headerName: "Potential Fines",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceFines regulations={params.row.regulations} />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="compliance_penalities"
            headerName="Potential Fines"
            unit={currencySymbol}
          />
        ),
        sortable: false,
        width: 140,
      },
      {
        ...baseNumberColumnDef,
        field: "reduction_required",
        headerName: "Reduction Required",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceReduction regulations={params.row.regulations} />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="reduction_required"
            headerName="Reduction Required"
            unit="%"
          />
        ),
        sortable: false,
        width: 180,
      },
      // Combined Emissions is unique to Compliance
      // The value is calculated based on the regulation emission factor
      {
        ...baseNumberColumnDef,
        field: "total_emissions",
        headerName: "Combined Emissions",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceTotalEmissions regulations={params.row.regulations} />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="total_emissions"
            headerName="Combined Emissions"
            unit={translateUnit(
              i18n.language,
              UnitName.MetricTonsOfCarbonDioxideEquivalent
            )}
          />
        ),
        sortable: false,
        width: 160,
      },
      {
        ...baseNumberColumnDef,
        field: "calculated_eui",
        headerName: "Energy Usage Intensity",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceUsageIntensity
            regulations={params.row.regulations}
            usageIntensityType="eui"
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="calculated_eui"
            headerName="Energy Usage Intensity"
            unit="kBtu / sq ft" // Purposely not translated per locale
          />
        ),
        sortable: false,
      },
      {
        ...baseNumberColumnDef,
        field: "target_eui",
        headerName: "Target EUI",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceTargetUsageIntensity
            regulations={params.row.regulations}
            usageIntensityType="eui"
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="target_eui"
            headerName="Target EUI"
            unit="kBtu / sq ft" // Purposely not translated per locale
          />
        ),
        sortable: false,
        width: 120,
      },
      {
        ...baseNumberColumnDef,
        field: "calculated_cui",
        headerName: "Carbon Usage Intensity",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceUsageIntensity
            regulations={params.row.regulations}
            usageIntensityType="cui"
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="calculated_cui"
            headerName="Carbon Usage Intensity"
            unit="kg CO2e / sq ft" // Purposely not translated per locale
          />
        ),
        sortable: false,
      },
      {
        ...baseNumberColumnDef,
        field: "target_cui",
        headerName: "Target CUI",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceTargetUsageIntensity
            regulations={params.row.regulations}
            usageIntensityType="cui"
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="target_cui"
            headerName="Target CUI"
            unit="kg CO2e / sq ft" // Purposely not translated per locale
          />
        ),
        sortable: false,
        width: 120,
      },
      // Combined Energy Usage is unique to Compliance
      // The value is calculated based on the regulation emission factor
      {
        ...commonSiteDataGridColumns.combinedEnergyUsage,
        sortable: false,
      },
      {
        ...baseColumnDef,
        field: "electricity_source",
        headerName: "Electricity EF",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceEmissionFactorName
            emissionFactorType="electricity"
            regulations={params.row.regulations}
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="electricity_source"
            headerName="Electricity EF"
          />
        ),
        sortable: false,
        width: 120,
      },
      {
        ...baseNumberColumnDef,
        field: "electricity_ef",
        headerName: "Electricity EF Value",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceEmissionFactorValue
            emissionFactorType="electricity"
            regulations={params.row.regulations}
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="electricity_ef"
            headerName="Electricity EF Value"
            unit="kg CO2e / kWh" // Purposely not translated per locale
          />
        ),
        sortable: false,
        width: 160,
      },
      {
        ...baseColumnDef,
        field: "natural_gas_source",
        headerName: "Natural Gas EF",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceEmissionFactorName
            emissionFactorType="natural_gas"
            regulations={params.row.regulations}
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="natural_gas_source"
            headerName="Natural Gas EF"
          />
        ),
        sortable: false,
        width: 132,
      },
      {
        ...baseNumberColumnDef,
        field: "natural_gas_ef",
        headerName: "Natural Gas EF Value",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <ComplianceEmissionFactorValue
            emissionFactorType="natural_gas"
            regulations={params.row.regulations}
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="natural_gas_ef"
            headerName="Natural Gas EF Value"
            unit="kg CO2e / kBtu" // Purposely not translated per locale
          />
        ),
        sortable: false,
        width: 172,
      },
      {
        ...commonGridColumns.buildingArea,
      },
      ...(isFeatureEnabled(FeatureFlags.PLANNING, organization)
        ? [{ ...commonGridColumns.planning }]
        : []),
    ]
  }, [
    commonGridColumns.buildingArea,
    commonGridColumns.combinedEnergyUsage,
    commonGridColumns.planning,
    commonGridColumns.rowNumber,
    commonGridColumns.siteName,
    currencySymbol,
    isFeatureEnabled,
    organization,
  ])

  return columns
}
