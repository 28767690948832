import { ApiQueryName } from "@/models/api"
import { useQuery } from "@tanstack/react-query"
import type { UseQueryOptions } from "@tanstack/react-query"

import { client } from "../client"
import type { Paths } from "../types"
import { useQueryKey, useRenameQueryResult } from "../utils"

const getBuildingTypes = async (
  params: Paths["/api/v1/recommendations/building_types"]["get"]["parameters"]
): Promise<string[]> => {
  const { data, error } = await client.GET(
    "/api/v1/recommendations/building_types",
    {
      params,
    }
  )

  if (data) {
    return data
  }

  if (typeof error === "object" && error.detail?.length) {
    throw new Error(error.detail[0].msg)
  }

  throw new Error(error as string)
}

interface UseGetBuildingTypesOptions {
  parameters?: Paths["/api/v1/recommendations/building_types"]["get"]["parameters"]
  queryOptions?: Omit<UseQueryOptions<string[]>, "queryKey" | "queryFn">
}

const defaultUseGetBuildingTypesOptions: UseGetBuildingTypesOptions = {
  parameters: {},
  queryOptions: {},
}

/**
 * Returns a list of building types
 *
 * @example
 * const {
 *   buildingTypesData,
 *   buildingTypesError,
 *   buildingTypesIsError
 *   buildingTypesIsLoading,
 * } = useGetBuildingTypes({
 *   parameters: { query: { dataset: "CBECS" } },
 *   queryOptions // react query options
 * })
 */
export const useGetBuildingTypes = ({
  parameters,
  queryOptions,
}: UseGetBuildingTypesOptions = defaultUseGetBuildingTypesOptions) => {
  // Ensure consumer can omit optional properties
  const options = {
    ...defaultUseGetBuildingTypesOptions,
    parameters,
    queryOptions,
  }

  const queryKey = useQueryKey(
    ApiQueryName.BuildingTypes,
    "getMany",
    null,
    parameters
  )

  const queryFn = async (): Promise<string[]> => {
    return getBuildingTypes(parameters)
  }

  const queryResult = useQuery<string[]>({
    queryKey,
    queryFn,
    ...options.queryOptions,
  })

  return useRenameQueryResult(queryResult, ApiQueryName.BuildingTypes)
}
