import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import type { Context, FC, ReactNode, SyntheticEvent } from "react"

import { Alert, Snackbar } from "@mui/material"
import type { SnackbarCloseReason } from "@mui/material"

export interface Notification {
  duration?: number | null
  message: string
  type: "success" | "error" | "info" | "warning"
}

interface NotificationContextValue {
  setNotification: (config: Notification) => void
}

interface NotificationProviderProps {
  children: ReactNode
}

const NotificationContext: Context<NotificationContextValue> = createContext({
  setNotification: undefined,
})

/**
 * Provides the notification service
 *
 * @example
 * <NotificationProvider>
 *   <Component />
 * </NotificationProvider>
 */
export const NotificationProvider: FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notification, setNotification] = useState<Notification | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClose = (
    event?: SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return
    }

    setIsOpen(false)
  }

  // Public notification setter to handle additional behavior
  const setNotificationPublic = useCallback(
    (newNotification: Notification): void => {
      setNotification(newNotification)
      setIsOpen(true)
    },
    [setNotification]
  )

  const value: NotificationContextValue = useMemo(
    () => ({
      setNotification: setNotificationPublic,
    }),
    [setNotificationPublic]
  )

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Snackbar
        autoHideDuration={
          notification?.duration === undefined ? 15_000 : notification.duration
        }
        key={notification?.message}
        onClose={handleClose}
        open={isOpen}
      >
        <Alert
          onClose={handleClose}
          severity={notification?.type ?? "info"}
          sx={{
            boxShadow: 4,
            width: "100%",
          }}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  )
}

/**
 * A service for creating notifications
 *
 * @example
 * const { setNotification } = useNotification()
 * setNotification({
 *   message: "Test notification",
 *   type: "success"
 * })
 */
export const useNotification = () => useContext(NotificationContext)
