import { ApiQueryName } from "@/models/api"
import { useQuery } from "@tanstack/react-query"
import type { UseQueryResult } from "@tanstack/react-query"

import { useAuthentication } from "../authentication"
import { useQueryKey, useRenameQueryResult } from "../utils"
import {
  allConsultantsWorkspaceName,
  consultantWorkspaceNames,
} from "./sigma.types"
import type { SigmaPaginatedResponse, SigmaWorkbook } from "./sigma.types"
import { useSigmaApi } from "./useSigmaApi"

const placeholderWorkbook: SigmaWorkbook = {
  createdAt: "",
  createdBy: "",
  id: "",
  latestVersion: 1,
  name: "",
  ownerId: "",
  path: allConsultantsWorkspaceName,
  updatedAt: "",
  updatedBy: "",
  url: "",
  workbookId: "",
  workbookUrlId: "",
}

const placeholderWorkbook1 = {
  ...placeholderWorkbook,
  ...({ id: "1", name: "1", workbookId: "1" } as SigmaWorkbook),
}
const placeholderWorkbook2 = {
  ...placeholderWorkbook,
  ...({ id: "2", name: "2", workbookId: "2" } as SigmaWorkbook),
}
const placeholderWorkbook3 = {
  ...placeholderWorkbook,
  ...({ id: "3", name: "3", workbookId: "3" } as SigmaWorkbook),
}
const placeholderWorkbooks: SigmaWorkbook[] = [
  placeholderWorkbook1,
  placeholderWorkbook2,
  placeholderWorkbook3,
]

const placeholderData: SigmaPaginatedResponse<SigmaWorkbook> = {
  entries: placeholderWorkbooks,
  hasMore: false,
  nextPage: null,
  total: placeholderWorkbooks.length,
}

// Filtering is necessary because the user associated with the API secret
// has access to all org teams/workspaces
const filterWorkbooks = (
  workbooks: SigmaWorkbook[],
  workspaceNames: UseSigmaWorkbooksOptions["workspaceNames"]
): SigmaWorkbook[] => {
  const accessibleWorkbooks = workbooks.filter((workbook) =>
    workspaceNames
      .map((name) => name.toLowerCase())
      .includes(workbook.path.toLowerCase())
  )

  return accessibleWorkbooks
}

interface UseSigmaWorkbooksOptions {
  workspaceNames: SigmaWorkbook["path"][]
}

/**
 * Gets the Sigma workbooks for the requested workspaces
 *
 * @returns The paginated Sigma workbooks
 *
 * @example
 * const {
 *   sigmaWorkbooksData,
 *   sigmaWorkbooksIsPlaceholderData,
 * } = useSigmaWorkbooks({
 *   workspaceNames: [organization?.name],
 * })
 *
 * @example
 * const {
 *   sigmaWorkbooksData,
 *   sigmaWorkbooksIsPlaceholderData,
 * } = useSigmaWorkbooks({
 *   workspaceNames: ["All Consultants"],
 * })
 */
export const useSigmaWorkbooks = (options: UseSigmaWorkbooksOptions) => {
  const { isConsultantUser } = useAuthentication()
  const { fetchSigmaWorkbooks, sigmaAccessTokenIsFetched } = useSigmaApi()

  const enabled = sigmaAccessTokenIsFetched && options.workspaceNames.length > 0

  const queryKey = useQueryKey(
    ApiQueryName.SigmaWorkbooks,
    "getMany",
    null,
    options
  )

  // Filter out certain workspace names if not a consultant user
  const workspaceNames: string[] = [
    ...(isConsultantUser
      ? options.workspaceNames
      : options.workspaceNames.filter(
          (workspaceName) => !consultantWorkspaceNames.includes(workspaceName)
        )),
  ]

  const queryResult: UseQueryResult<SigmaPaginatedResponse<SigmaWorkbook>> =
    useQuery<SigmaPaginatedResponse<SigmaWorkbook>>({
      enabled,
      placeholderData,
      queryFn: fetchSigmaWorkbooks,
      queryKey: [queryKey],
      select: (data) => ({
        ...data,
        entries: filterWorkbooks(data?.entries ?? [], workspaceNames),
      }),
    })

  return useRenameQueryResult(queryResult, ApiQueryName.SigmaWorkbooks)
}
