import type { FC } from "react"
import React from "react"
import { Link } from "react-router-dom"

import { HStack } from "@/nzds/layout"
import { useUrl } from "@/utils/url"

import { Box, Chip, Popover, Typography } from "@mui/material"

import { useFormatPercentage } from "../../../../formatters/useFormatPercentage"
import { NZeroLogoIcon } from "../../../../nzds"
import type { PlanNameCellProps } from "./plan-name-cell.types"

export const PlanNameCell: FC<PlanNameCellProps> = ({
  planId,
  name,
  isRecommended,
  siteId,
}) => {
  const { buildRecommendationsPlanDetailsPath } = useUrl()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const planHref = buildRecommendationsPlanDetailsPath(planId, siteId)

  const { format: formatPercentage } = useFormatPercentage()

  const open = Boolean(anchorEl)
  const id = open ? `${planId}` : undefined

  const nameItems = name
  const displayItems = nameItems.slice(0, 2)
  const displayItemsString = displayItems
    .map(
      (intervention) =>
        `${intervention.name}: ${
          intervention.percentage
            ? formatPercentage(intervention.percentage)
            : ""
        }`
    )
    .join(" | ")
  const additionalItemsCount = nameItems.length - displayItems.length
  return (
    <HStack
      alignItems="center"
      gap={0.5}
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      {isRecommended ? (
        <NZeroLogoIcon color="primary" fontSize="small" />
      ) : null}
      <Typography component={Link} noWrap to={planHref} variant="body2">
        {displayItemsString}
      </Typography>
      {additionalItemsCount > 0 ? (
        <>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            disableRestoreFocus
            id={id}
            onClose={handlePopoverClose}
            open={open}
            PaperProps={{
              sx: {
                mt: 1,
              },
            }}
            sx={{
              pointerEvents: "none",
            }}
          >
            <Box p={2}>
              {nameItems.map((item) => (
                <Typography key={item.name} variant="body2">
                  {item.name}:{" "}
                  {item.percentage ? formatPercentage(item.percentage) : null}
                </Typography>
              ))}
            </Box>
          </Popover>
          <Chip
            aria-describedby={id}
            component="button"
            label={`+${additionalItemsCount}`}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            size="small"
            variant="outlined"
          />
        </>
      ) : null}
    </HStack>
  )
}
