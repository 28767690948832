// @ts-strict-ignore
import { useMemo } from "react"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { UseQueryResult } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"
import moment from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import type Site from "../../../../models/site"
import { fetchServiceHourMinMaxDates } from "../../util/api/api"

interface UseServiceHourMinMaxDatesValue {
  isServiceHourMinMaxDatesFetched: boolean
  isServiceHourMinMaxDatesInitialLoading: boolean
  isServiceHourMinMaxDatesLoading: boolean
  serviceHourMinMaxDates: IRange<Moment>
}

export const useServiceHourMinMaxDates = (
  site: Site | null
): UseServiceHourMinMaxDatesValue => {
  const queryKey = useQueryKey(
    ApiQueryName.ServiceHourMinMaxDates,
    "getOne",
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    site?.id
  )
  const queryResult = useQuery<{
    fetchedMaxDate: string
    fetchedMinDate: string
  }>({
    queryKey,
    queryFn: () => fetchServiceHourMinMaxDates(site),
    enabled: !!site,
  })

  const { data } = queryResult

  const modifiedData = useMemo(
    () => ({
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      start: data?.fetchedMinDate ? moment(data.fetchedMinDate) : null,
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      end: data?.fetchedMaxDate ? moment(data.fetchedMaxDate) : null,
    }),
    [data]
  )

  const modifiedQueryResult = {
    ...queryResult,
    data: modifiedData,
  } as UseQueryResult<IRange<Moment>>

  return useRenamedQueryResult(queryKey, modifiedQueryResult)
}
