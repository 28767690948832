import React from "react"
import type { FC } from "react"
import { Navigate, useSearchParams } from "react-router-dom"

import { OrganizationResolver } from "@/components/organizationResolver"
import { useOrganizationContext } from "@/contexts"
import { useAuthentication } from "@/services/authentication"
import { useData } from "@/services/data"
import { useUrl } from "@/utils/url"

import { If } from "../if/if"
import { Login } from "../login"

export const SignIn: FC = () => {
  const { isAuthenticated, isConsultantUser } = useAuthentication()
  const { organization } = useOrganizationContext()
  const activeOrganizationId = organization?.id
  const { dashboardPath, dataPath, reportingPath } = useUrl()
  const { dataHasData, dataIsFetched } = useData()

  const [searchParams] = useSearchParams()

  let landingPage = dashboardPath

  if (isConsultantUser) {
    landingPage = dataHasData ? reportingPath : dataPath
  }

  const redirectPath: string | undefined = searchParams.get("from") ?? undefined

  const landingPath: string = redirectPath ?? landingPage

  return (
    <>
      <If condition={!isAuthenticated}>
        <Login />
      </If>
      <If condition={isAuthenticated}>
        <If condition={!activeOrganizationId}>
          <OrganizationResolver />
        </If>
        <If condition={activeOrganizationId && dataIsFetched}>
          <Navigate to={landingPath} />
        </If>
      </If>
    </>
  )
}
