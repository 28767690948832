import { useQuery } from "@tanstack/react-query"
import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import { fetchDepartmentCounts } from "../../models/department"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useDepartmentCounts = (
  departmentId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const queryKey = useQueryKey(
    ApiQueryName.DepartmentCounts,
    "getOne",
    departmentId
  )

  const queryResult = useQuery({
    queryKey,
    queryFn: () => fetchDepartmentCounts(departmentId),
    enabled: !isNil(departmentId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type DepartmentCounts = InferredQueryServiceValue<
  typeof useDepartmentCounts,
  ApiQueryName.DepartmentCounts
>
