import React from "react"
import type { FC } from "react"
import { Link, Navigate, Outlet, useMatch } from "react-router-dom"

import { PageHeader } from "@/components/nav/page-header"
import { DataPath, RootPath } from "@/models/route"
import { useActiveOrganizationId } from "@/services/organization"

import { Tab, Tabs } from "@mui/material"

export const DataIndexRoute: FC = () => {
  const { activeOrganizationId } = useActiveOrganizationId()

  const indexMatch = useMatch(`${activeOrganizationId}/${RootPath.Data}`)
  const uploadMatch = useMatch(
    `${activeOrganizationId}/${RootPath.Data}/${DataPath.Upload}`
  )
  const qaMatch = useMatch(
    `${activeOrganizationId}/${RootPath.Data}/${DataPath.QA}`
  )

  if (indexMatch) {
    return <Navigate to={DataPath.Upload} replace={true} />
  }

  let activeTabPath = ""

  if (uploadMatch) {
    activeTabPath = DataPath.Upload
  } else if (qaMatch) {
    activeTabPath = DataPath.QA
  }

  return (
    <>
      <PageHeader
        navigation={
          <Tabs
            aria-label="Data Manager navigation"
            role="navigation"
            sx={{ px: 3 }}
            value={activeTabPath}
          >
            <Tab
              component={Link}
              label="Upload"
              to={DataPath.Upload}
              value={DataPath.Upload}
            />
            <Tab
              component={Link}
              label="QA"
              to={DataPath.QA}
              value={DataPath.QA}
            />
          </Tabs>
        }
        title="Data Manager"
      />
      <Outlet />
    </>
  )
}
