import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { UseQueryResult } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { EmissionsDayStats } from "./useEmissionsStatsUtils"
import {
  fetchEmissionDays,
  filterEmissionStats,
} from "./useEmissionsStatsUtils"

/**
 * The useEmissionsStats service/hook returns the direct, indirect, and water emissions stats
 * for a given organization, site ids, and date range.
 *
 * @returns The useEmissionsStatsHook
 * @example
 * const {
 *   useEmissionsStats,
 *   isUseEmissionsStatsLoading
 * } = useEmissionsStats(8, [23, 24], { start: moment(), end: moment() }, [1, 3])
 */
export const useEmissionsStats = (
  organizationId: string,
  siteIds: string[],
  dateRange: {
    end: Moment
    start: Moment
  },
  scopes: number[]
) => {
  const rangeStart = dateRange?.start?.format("YYYY-MM-DD")
  const rangeEnd = dateRange?.end?.format("YYYY-MM-DD")
  const queryKey = useQueryKey(ApiQueryName.EmissionsStats, "getOne", null, {
    organizationId,
    siteIds,
    rangeStart,
    rangeEnd,
    scopes,
  })

  const queryResult = useQuery({
    queryKey,
    queryFn: () =>
      fetchEmissionDays({
        organizationId,
        siteIds,
        rangeStart,
        rangeEnd,
        scopes,
      }),
    enabled:
      !!organizationId &&
      !!siteIds?.length &&
      !!dateRange?.start?.isValid() &&
      !!dateRange?.end?.isValid() &&
      !!scopes?.length,
  })

  // TODO: Remove the type conversion when we have this properly typed
  const filteredQueryResult = {
    ...queryResult,
    data: filterEmissionStats(queryResult.data),
  } as UseQueryResult<EmissionsDayStats>

  return useRenamedQueryResult(queryKey, filteredQueryResult)
}
