import { useQuery } from "@tanstack/react-query"
import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import MeterModel from "../../models/meter"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useMeter = (
  meterId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const { getOne } = useSpraypaintModel(MeterModel)

  const queryKey = useQueryKey(ApiQueryName.Meter, "getOne", meterId)

  const queryResult = useQuery({
    queryKey,
    queryFn: () =>
      getOne(meterId, {
        include: ["meterSourceType"],
        extra: ["energyAllocatorName"],
      }),
    enabled: !isNil(meterId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type Meter = InferredQueryServiceValue<
  typeof useMeter,
  ApiQueryName.Meter
>
