import React, { useEffect } from "react"
import type { FC } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { OrganizationResolver } from "@/components/organizationResolver"
import { SelectedDateRangeProvider } from "@/contexts"
import { RootPath } from "@/models/route"
import { UserSettingsProvider } from "@/modules/settings"
import { useAuthentication } from "@/services/authentication"
import { useData } from "@/services/data"
import { useDatadogRumSetUser } from "@/services/datadog-rum"
import { useGtagSetUser } from "@/services/gtag"
import { useActiveOrganizationId } from "@/services/organization"
import { usePendoInit } from "@/services/pendo"
import { getCurrentAppUrl } from "@/utils"
import { LogoutProvider } from "@/utils/logout-context"

export const AuthenticatedRoute: FC = () => {
  const { isAuthenticated } = useAuthentication()
  useDatadogRumSetUser()
  // Set the gtag user_id here if user loads a page and is already authenticated
  useGtagSetUser()
  usePendoInit()
  const navigate = useNavigate()
  const { activeOrganizationId } = useActiveOrganizationId()
  const { dataIsFetched } = useData()

  // Redirect to sign in if not authenticated
  useEffect(() => {
    const currentUrl = getCurrentAppUrl()
    const encodedCurrentUrl = encodeURIComponent(currentUrl)

    if (!isAuthenticated) {
      navigate(`${RootPath.Root}?from=${encodedCurrentUrl}`)
    }
  }, [isAuthenticated, navigate])

  return (
    isAuthenticated && (
      <LogoutProvider>
        <UserSettingsProvider>
          <SelectedDateRangeProvider>
            <OrganizationResolver>
              {activeOrganizationId && dataIsFetched ? <Outlet /> : null}
            </OrganizationResolver>
          </SelectedDateRangeProvider>
        </UserSettingsProvider>
      </LogoutProvider>
    )
  )
}
