import { useOrganizationContext } from "@/contexts"
import { ApiQueryName } from "@/models/api"
import type { Paths, Schemas } from "@/services/types"
import { useQueryKey } from "@/services/utils/useQueryKey"
import type { UseQueryOptions } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"

import { getGoals } from "./get-goals"

interface UseGetGoalsOptions {
  query: Paths["/api/v1/recommendations/goals"]["get"]["parameters"]["query"]
  useQueryOptions?: Omit<
    UseQueryOptions<Schemas["GoalResponse"][]>,
    "queryKey" | "queryFn"
  >
}

/**
 * Returns Goals matching a subject id and subject type
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetGoals({
 *   query: { subject_id: 123, subject_type: 'Site'},
 *   queryOptions // react query options
 * });
 */
export const useGetGoals = ({
  query,
  useQueryOptions = {},
}: UseGetGoalsOptions) => {
  const { organization } = useOrganizationContext()

  const queryKeyValues = {
    orgId: organization?.id,
    siteId: query?.site_id,
  }

  const queryKey = useQueryKey(
    ApiQueryName.RecommendationsGoals,
    "getMany",
    null,
    { ...queryKeyValues }
  )

  const queryOptions = {
    queryKey,
    queryFn: () =>
      getGoals({
        query: {
          ...query,
          organization_id: organization?.id,
        },
      }),
    ...useQueryOptions,
  }

  const queryResult = useQuery<Schemas["GoalResponse"][]>(queryOptions)

  return queryResult
}
