import { useMemo } from "react"

import { ApiQueryName } from "@/models/api"
import type { IRange } from "@/models/range"
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { UseQueryResult } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"

import type {
  Gri302,
  Gri303,
  Gri305,
  Gri306,
  GriData,
  GriReport,
} from "../../models/gri"
import {
  GriReportName,
  gri302ReportColumns,
  gri303ReportColumns,
  gri305ReportColumns,
  gri306ReportColumns,
} from "../../models/gri"
import { getReportFileName, getReportRows } from "../../util/util"
import { fetchGriReport } from "./useGriReportUtils"

/**
 * The useGriReport service/hook returns a GRI Report
 *
 * @returns The report
 * @example
 * const { griReport, isGriReportFetched, isGriReportLoading } = useGriReport()
 */
export const useGriReport = (
  orgId: string,
  dateRange: IRange<Moment>,
  isDataReady: boolean
) => {
  const queryKey = useQueryKey(ApiQueryName.GriReport, "getOne", null, {
    orgId,
    start: dateRange?.start?.format("YYYY-MM-DD"),
    end: dateRange?.end?.format("YYYY-MM-DD"),
  })
  const queryResult = useQuery<GriData>({
    enabled: !!orgId && !!dateRange?.start && !!dateRange?.end,
    queryFn: () => fetchGriReport(orgId, dateRange),
    queryKey,
  })

  const griReport: GriReport = useMemo(() => {
    if (queryResult.data) {
      return {
        gri302: {
          name: getReportFileName(GriReportName.Gri302, dateRange, isDataReady),
          data: {
            columns: gri302ReportColumns,
            rows: getReportRows<Gri302>(queryResult.data.gri302),
          },
        },
        gri303: {
          name: getReportFileName(GriReportName.Gri303, dateRange, isDataReady),
          data: {
            columns: gri303ReportColumns,
            rows: queryResult.data
              ? getReportRows<Gri303>(queryResult.data.gri303)
              : [],
          },
        },
        gri305: {
          name: getReportFileName(GriReportName.Gri305, dateRange, isDataReady),
          data: {
            columns: gri305ReportColumns,
            rows: queryResult.data
              ? getReportRows<Gri305>(queryResult.data.gri305)
              : [],
          },
        },
        gri306: {
          name: getReportFileName(GriReportName.Gri306, dateRange, isDataReady),
          data: {
            columns: gri306ReportColumns,
            rows: queryResult.data
              ? getReportRows<Gri306>(queryResult.data.gri306)
              : [],
          },
        },
      }
    }

    return null
  }, [queryResult.data, dateRange, isDataReady])

  const modifiedQueryResult = {
    ...queryResult,
    data: griReport,
  } as UseQueryResult<GriReport>

  return useRenamedQueryResult(queryKey, modifiedQueryResult)
}
