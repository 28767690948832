import { useMemo } from "react"
import type { TFunction } from "react-i18next"

import useMonthlySiteData from "@/services/useMonthlySiteData/useMonthlySiteData"
import type { Moment } from "moment"

import type { CurrencyCode } from "../../../../models/currencyCode"
import type Department from "../../../../models/department"
import type { LanguageRegion } from "../../../../models/i18n"
import type Organization from "../../../../models/organization"
import type OrganizationalUnit from "../../../../models/organizationalUnit"
import type { IRange } from "../../../../models/range"
import type { Order } from "../../../../models/sort"
import type { GhgWeightUnit } from "../../../../models/unit"
import type {
  ResourceSummaryRecord,
  ResourceSummaryReport,
  ResourceSummaryReportTableColumn,
} from "../../models/resourceSummary"
import { ResourceSummaryReportName } from "../../models/resourceSummary"
import {
  getReportFileName,
  getReportRows,
  isOrgLevelSite,
  isUniqueSite,
} from "../../util/util"
import {
  getFormattedColumns,
  getFormattedRecords,
  isSiteEmpty,
  sortReportData,
} from "./useResourceSummaryReportUtils"

interface UseResourceSummaryReportValue {
  isResourceSummaryReportFetched: boolean
  isResourceSummaryReportInitialLoading: boolean
  isResourceSummaryReportLoading: boolean
  resourceSummaryReport: ResourceSummaryReport | null
}

interface UseResourceSummaryReportOptions {
  columns: ResourceSummaryReportTableColumn[]
  currency: CurrencyCode
  departments?: Department["id"][]
  electricityEmissionsFactor: string
  groupIds?: OrganizationalUnit["id"][]
  language: LanguageRegion
  order: Order
  orderBy: keyof ResourceSummaryRecord
  translateFn: TFunction
  weightUnit: GhgWeightUnit
}

/**
 * The useResourceSummaryReport service/hook returns a Resource Summary Report
 *
 * @returns The report
 * @example
 * const {
 *   resourceSummaryReport,
 *   isResourceSummaryReportFetched,
 *   isResourceSummaryReportLoading
 * } = useResourceSummaryReport("1", {
 *     start: moment("2022-01-01"),
 *     end: moment("2022-02-28"),
 *   }, {
 *     columns: [],
 *     currency: CurrencyCode.USD,
 *     departments?: [1, 2, 3],
 *     groupIds?: [1, 2, 3],
 *     language: "en-GB",
 *     order: "asc",
 *     orderBy: "siteName",
 *     translateFn: t,
 *     weightUnit: "mt",
 *   })
 */
export const useResourceSummaryReport = (
  orgId: Organization["id"],
  dateRange: IRange<Moment>,
  {
    columns,
    currency,
    departments,
    groupIds,
    language,
    order,
    orderBy,
    translateFn,
    weightUnit,
    electricityEmissionsFactor,
  }: UseResourceSummaryReportOptions
): UseResourceSummaryReportValue => {
  // TODO: remove departments when flexible hierarchy feature flag is on
  // Sort department/group ids so array order is the same for query caching
  departments.sort()
  groupIds.sort()

  const {
    monthlySiteData: data,
    isMonthlySiteDataInitialLoading: isResourceSummaryReportInitialLoading,
    isMonthlySiteDataLoading: isResourceSummaryReportLoading,
    isMonthlySiteDataFetched: isResourceSummaryReportFetched,
  } = useMonthlySiteData(orgId, dateRange, { departments, groupIds })

  const resourceSummaryReport: ResourceSummaryReport = useMemo(() => {
    // Format the records
    const formattedRecords = getFormattedRecords(
      data ?? [],
      language,
      weightUnit,
      electricityEmissionsFactor
    )

    // Create the report
    const filteredReportData = formattedRecords.filter(
      (site) =>
        (isOrgLevelSite(site.siteId) && !isSiteEmpty(site, columns)) ||
        (isUniqueSite(site) && !isSiteEmpty(site, columns)) ||
        (!isOrgLevelSite(site.siteId) && !isUniqueSite(site))
    )

    const sortedReportData = sortReportData(filteredReportData, order, orderBy)

    const sortedReportColumns = [...columns].sort(
      (columnA, columnB) => columnA.order - columnB.order
    )

    return {
      data: {
        columns: getFormattedColumns(
          sortedReportColumns,
          translateFn,
          language,
          currency,
          weightUnit
        ),
        rows: getReportRows<ResourceSummaryRecord>(sortedReportData),
      },
      name: getReportFileName(
        ResourceSummaryReportName.Summary,
        dateRange,
        true
      ),
    }
  }, [
    columns,
    currency,
    data,
    dateRange,
    electricityEmissionsFactor,
    language,
    order,
    orderBy,
    translateFn,
    weightUnit,
  ])

  return useMemo(
    () => ({
      resourceSummaryReport,
      isResourceSummaryReportFetched,
      isResourceSummaryReportInitialLoading,
      isResourceSummaryReportLoading,
    }),
    [
      resourceSummaryReport,
      isResourceSummaryReportFetched,
      isResourceSummaryReportInitialLoading,
      isResourceSummaryReportLoading,
    ]
  )
}
