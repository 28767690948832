/* eslint-disable react/require-default-props */
import React from "react"

import { Box, Typography } from "@mui/material"

import { MAX_MONTHS_IN_RANGE } from "../../../../utils/constants"
import emptyCity from "../../assets/city street-gray.svg"

interface EmptyChartProps {
  header?: string
  subHeader?: string
}

export const DATE_PICKER_ERROR_MESSAGE = `Must be within ${MAX_MONTHS_IN_RANGE} months`
export const EMPTY_HEADER = "There is no activity data available for this site."
export const DATE_RANGE_ERROR_SUBHEADER =
  "Please select a different date range."
export const DEFAULT_HEADER = "Data Unavailable"

const EmptyChart: React.FC<EmptyChartProps> = ({ header, subHeader }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <Typography mb={1} sx={{ color: "grey.700" }} variant="h2">
      {header ?? DEFAULT_HEADER}
    </Typography>
    {subHeader && (
      <Typography
        component="span"
        mb={1}
        sx={{ color: "grey.700", fontStyle: "italic" }}
        variant="h4"
      >
        {subHeader}
      </Typography>
    )}
    <img
      alt="A gray illustration of a city street full of buildings, trees, and power lines."
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      src={emptyCity}
    />
  </Box>
)

export default EmptyChart
