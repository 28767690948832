import React, { useEffect } from "react"
import type { FC, ReactNode } from "react"
import { useParams } from "react-router-dom"

import { useOrganizationContext } from "@/contexts"
import type { Organization } from "@/models/organization"
import { useAuthorizedOrganizations } from "@/services/organization"

import { LoadingSpinner } from "../../loadingSpinner"
import Page404 from "../../nav/page404/page404"

interface OrgGuardProps {
  children: ReactNode
}

/**
 *
 * @deprecated Migrate to org-based routing using <OrganizationGuard> at the authenticated routes root
 */
export const OrgGuard: FC<OrgGuardProps> = ({ children }) => {
  const params = useParams()
  const { organization, setOrganization } = useOrganizationContext()
  const {
    data: authorizedOrganizations,
    isFetched: isAuthorizedOrganizationsFetched,
  } = useAuthorizedOrganizations()

  const urlOrganization: Organization | undefined =
    authorizedOrganizations?.find((org) => org.id === params.orgId)

  useEffect(() => {
    if (urlOrganization && urlOrganization.id !== organization?.id) {
      setOrganization(urlOrganization)
    }
  }, [organization?.id, setOrganization, urlOrganization])

  if (!isAuthorizedOrganizationsFetched) {
    return (
      <LoadingSpinner className="authorized-organization__loading-spinner" />
    )
  }
  if (!urlOrganization) {
    return <Page404 />
  }
  if (organization && organization.id === urlOrganization.id) {
    return <>{children}</>
  }

  // Don't rendering anything by default
  return null
}
