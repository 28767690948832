import React from "react"

import { HStack } from "@/nzds/layout"

import { Box, Stack, Typography } from "@mui/material"

import type { MetricProps } from "./metric.types"

/**
 * Wrapper for displaying a metric with a title and a value.
 *
 * ```tsx
 * import { Metric } from 'nzds'
 *
 * <Metric
 *   dataE2e="cost-value"
 *   title="Total Cost"
 * >
 *  <Typography>
 *   <CurrencyWithPlaceholder
 *    format={{ precision: 0 }}
 *    value={totalCost}
 *   />
 *  </Typography>
 * </Metric>
 * ```
 */
const Metric: React.FC<MetricProps> = ({
  title,
  children,
  "data-e2e": dataE2e,
}) => (
  <Box bgcolor="grey.25" borderRadius={2} px={1.5} py={1}>
    <Stack direction="column" spacing={0.5}>
      <Typography color="neutral.dark" variant="caption">
        {title}
      </Typography>
      <HStack
        alignItems="baseline"
        color="neutral.dark"
        data-e2e={dataE2e}
        spacing={0.5}
      >
        {children}
      </HStack>
    </Stack>
  </Box>
)

export default Metric
