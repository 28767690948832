// @ts-strict-ignore
import React, { useCallback, useMemo } from "react"

import { HStack } from "@/nzds/layout"
import { debounce } from "lodash-es"
import type { Moment } from "moment"

import { Box, useTheme } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment"

import type { MonthRangePickerProps } from "./month-range-picker.types"

export const MonthRangePicker = ({
  availableMaxMinMonths,
  value,
  onChange,
  disabled,
  error,
  errorMessage,
  fullWidth,
}: MonthRangePickerProps): JSX.Element => {
  const theme = useTheme()

  // TODO: refactor to use useDebounce hook
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(debounce(onChange, 1000), [onChange])
  const isDisabled: boolean = useMemo(
    () =>
      (disabled ?? !availableMaxMinMonths?.start?.isValid()) ||
      !availableMaxMinMonths.end?.isValid(),
    [availableMaxMinMonths, disabled]
  )

  const onChangeStart = useCallback(
    (
      date: Moment,
      context: Parameters<Parameters<typeof DatePicker>[0]["onChange"]>[1]
    ) =>
      debouncedOnChange(
        {
          ...value,
          start: date.clone().startOf("month"),
        },
        context
      ),
    [debouncedOnChange, value]
  )

  const onChangeEnd = useCallback(
    (
      date: Moment,
      context: Parameters<Parameters<typeof DatePicker>[0]["onChange"]>[1]
    ) =>
      debouncedOnChange(
        { ...value, end: date.clone().endOf("month") },
        context
      ),
    [debouncedOnChange, value]
  )

  return (
    <HStack
      alignItems="baseline"
      data-e2e="month-range-picker"
      justifyContent="space-between"
      minWidth={theme.typography.pxToRem(408)}
      width={fullWidth ? "100%" : theme.typography.pxToRem(408)}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disabled={isDisabled}
          maxDate={value?.end ?? availableMaxMinMonths?.end}
          minDate={availableMaxMinMonths?.start}
          onChange={onChangeStart}
          slotProps={{
            textField: {
              size: "small",
              error,
              inputProps: { "aria-label": "Start month" },
            },
            openPickerButton: { size: "small" },
            inputAdornment: { position: "end" },
          }}
          sx={{ width: theme.typography.pxToRem(184) }}
          value={value?.start}
          views={["year", "month"]}
        />
        <Box component="span" mx={1}>
          &ndash;
        </Box>
        <DatePicker
          disabled={isDisabled}
          maxDate={availableMaxMinMonths?.end}
          minDate={value?.start ?? availableMaxMinMonths?.start}
          onChange={onChangeEnd}
          slotProps={{
            openPickerButton: { size: "small" },
            inputAdornment: { position: "end" },
            textField: {
              error,
              size: "small",
              inputProps: { "aria-label": "End month" },
              helperText:
                error && errorMessage && !isDisabled ? errorMessage : "",
            },
          }}
          sx={{ width: theme.typography.pxToRem(184) }}
          value={value?.end}
          views={["year", "month"]}
        />
      </LocalizationProvider>
    </HStack>
  )
}

MonthRangePicker.defaultProps = {
  fullWidth: false,
}
