import React from "react"
import type { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

import { HStack } from "@/nzds/layout"

import { Room } from "@mui/icons-material"
import { Link, Typography } from "@mui/material"

import { RootPath, TamAdminPath } from "../../../../../models/route"
import type { SiteDisplayCellProps } from "./site-display-cell.types"

export const SiteDisplayCell: FC<SiteDisplayCellProps> = ({
  orgId,
  parentId,
  siteId,
  name,
}) => (
  <HStack alignItems="center" spacing={2}>
    <Room color="neutral" />
    <Link
      component={RouterLink}
      to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites/${siteId}`}
    >
      {name}
    </Link>
    {!parentId ? (
      <Typography fontStyle="italic" variant="body2">
        Unassigned
      </Typography>
    ) : null}
  </HStack>
)
