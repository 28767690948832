// @ts-strict-ignore
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import type { ScaleLinear } from "d3"
import { selectAll } from "d3"
import { isNull } from "lodash-es"

import { useTheme } from "@mui/material"

import { BarGraphBackground } from "../../../../../../../components/graph/barBackgroundComponent"
import { BarGraph } from "../../../../../../../components/graph/barGraphComponent"
import {
  clearYAxis,
  renderSecondaryYAxis,
  scaleY,
} from "../../../../../../../components/graph/secondaryYAxis"
import { renderYAxis } from "../../../../../../../components/graph/yAxis"
import type EnergyAllocation from "../../../../../../../models/energyAllocation"
import type { UnitName } from "../../../../../../../models/unit"
import {
  GRAPH_COST_OR_CARBON_INTENSITY,
  TIME_RANGES,
} from "../../../../../../../utils/constants"
import { useUserSettings } from "../../../../../../settings/contexts/userSettings.provider"

export const EnergyProfileGraph = ({
  onHover,
  pageWidth,
  graphDataGroups,
  timeRange,
  axisUnit,
  onHourSelect,
  wattUnit,
  showMeterData,
  showCostOrCarbonIntensity,
  maxVolumeDomain,
  maxAvgCostDomain,
  maxAvgCarbonIntensityDomain,
  energyAllocations,
  isLoading,
  isFlyoutActive,
}: {
  axisUnit: string
  energyAllocations
  graphDataGroups
  isFlyoutActive: boolean
  isLoading: boolean
  maxAvgCarbonIntensityDomain
  maxAvgCostDomain
  maxVolumeDomain
  onHourSelect
  onHover
  pageWidth: number
  showCostOrCarbonIntensity: GRAPH_COST_OR_CARBON_INTENSITY
  showMeterData
  timeRange
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const theme = useTheme()
  const { i18n } = useTranslation()
  const userSettings = useUserSettings()
  const [displayedEnergyAllocations, setDisplayedEnergyAllocations] =
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    useState<EnergyAllocation[]>(energyAllocations)
  const [primaryYLineScale, setPrimaryYLineScale] = useState<{
    yFunc: ScaleLinear<number, number>
  }>(null)
  const [secondaryYLineScale, setSecondaryYLineScale] = useState<{
    yFunc: ScaleLinear<number, number>
  }>(null)
  const [secondaryYRenderer, setSecondaryYRenderer] = useState<{
    args: {
      height
      maxDomainValue
      wattUnit
      width
    }
    renderFunc: ({
      height,
      maxDomainValue,
      wattUnit,
      width,
    }: {
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      height: any
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      maxDomainValue: any
      wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      width: any
    }) => void
  }>(null)
  const graphRef = React.useRef(null)

  const height = 400
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const baseWidth = pageWidth / graphDataGroups.length
  const isSingleDay =
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    timeRange === TIME_RANGES.DAY && graphDataGroups.length === 1
  const isMonth =
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    timeRange === TIME_RANGES.MONTH && graphDataGroups.length === 1
  const outerPadding = 100
  const middlePadding = 20
  const graphWidth =
    isSingleDay || isMonth
      ? // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (pageWidth - outerPadding * 2) / graphDataGroups.length
      : // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (pageWidth - outerPadding * 2 - middlePadding) / graphDataGroups.length
  const firstGraph = (idx) => idx === 0

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const onClick = onHourSelect

  useEffect(() => {
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setDisplayedEnergyAllocations(energyAllocations)
  }, [energyAllocations])

  useEffect(() => {
    const yFunc = renderYAxis({
      axisUnit,
      height,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      maxDomainValue: maxVolumeDomain,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      timeRange,
      wattUnit,
      width: pageWidth,
      outerPadding,
      i18nService: i18n,
      userSettings,
    })

    const yFunc2 = scaleY({
      height,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      maxDomainValue:
        showCostOrCarbonIntensity === GRAPH_COST_OR_CARBON_INTENSITY.COST
          ? maxAvgCostDomain
          : maxAvgCarbonIntensityDomain,
      wattUnit,
      axisType: showCostOrCarbonIntensity,
      i18nService: i18n,
      userSettings,
    })
    const renderYAxis2 = {
      renderFunc: renderSecondaryYAxis,
      args: {
        height,
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        maxDomainValue:
          showCostOrCarbonIntensity === GRAPH_COST_OR_CARBON_INTENSITY.COST
            ? maxAvgCostDomain
            : maxAvgCarbonIntensityDomain,
        axisType: showCostOrCarbonIntensity,
        wattUnit,
        width: pageWidth,
        i18nService: i18n,
        userSettings,
      },
    }

    setPrimaryYLineScale({ yFunc })
    setSecondaryYLineScale({ yFunc: yFunc2 })
    setSecondaryYRenderer({ ...renderYAxis2 })

    return () => {
      selectAll("g.yaxis").remove()
    }
    // migrate directories to strict mode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayedEnergyAllocations,
    pageWidth,
    axisUnit,
    wattUnit,
    pageWidth,
    i18n,
    showCostOrCarbonIntensity,
  ])

  useEffect(() => {
    if (
      showCostOrCarbonIntensity !== GRAPH_COST_OR_CARBON_INTENSITY.NONE &&
      !isNull(secondaryYRenderer)
    ) {
      secondaryYRenderer.renderFunc(secondaryYRenderer.args)
    } else if (
      showCostOrCarbonIntensity === GRAPH_COST_OR_CARBON_INTENSITY.NONE
    ) {
      clearYAxis("g.secondary-y-axis-container")
    }
  }, [showCostOrCarbonIntensity, secondaryYRenderer])

  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (graphDataGroups.length < 1) {
    return (
      !isLoading && (
        <div className="site-overview" style={{ margin: "auto 0" }}>
          <div className="title-with-note">
            <h2 className="title-with-note__large-title">
              Electricity Data Unavailable.
            </h2>
          </div>
          <div className="site-overview__site-graph-subtitle">
            Please select a different date range.
          </div>
        </div>
      )
    )
  }

  return (
    <svg
      ref={graphRef}
      className="dashboard-data__graph"
      style={{ padding: 0 }}
    >
      <defs>
        <pattern
          height="4px"
          id="missingData"
          patternTransform="rotate(45)"
          patternUnits="userSpaceOnUse"
          width="4px"
        >
          <circle cx="2" cy="2" id="circle" opacity="0.25" r="0.5" />
        </pattern>
      </defs>
      {/* migrate directories to strict mode */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {(!graphRef.current || isLoading) && (
        <rect fill="rgba(255, 255, 255, 0.75" height="100%" width="100%" />
      )}
      {/* migrate directories to strict mode */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {graphRef.current &&
        !isLoading &&
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        primaryYLineScale &&
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        graphDataGroups.map((graphDataGroup, idx) => (
          // migrate directories to strict mode
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`meteredState-${idx}`}>
            <rect
              // migrate directories to strict mode
              // eslint-disable-next-line react/no-array-index-key
              key={`bg-pre-${idx}`}
              className="bg-active-state-pre"
              fill={theme.palette.common.white}
              height={height}
              width={firstGraph(idx) ? outerPadding : middlePadding}
              x={firstGraph(idx) ? 0 : graphWidth * idx + outerPadding}
              y={0}
            />
            <BarGraphBackground
              // migrate directories to strict mode
              // eslint-disable-next-line react/no-array-index-key
              key={`graph-bg-${idx}`}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              graphDataGroup={graphDataGroup}
              graphWidth={graphWidth}
              // migration to strict mode batch disable
              // Mass lint disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              groupSize={graphDataGroups.length}
              height={height}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              idx={idx}
              middlePadding={middlePadding}
              outerPadding={outerPadding}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              showMeterData={showMeterData}
            />
            <rect
              // migrate directories to strict mode
              // eslint-disable-next-line react/no-array-index-key
              key={`bg-post-${idx}`}
              className="bg-active-state-post"
              fill={theme.palette.common.white}
              height={height}
              width={isMonth || isSingleDay ? outerPadding : middlePadding}
              x={
                firstGraph(idx)
                  ? graphWidth + outerPadding
                  : graphWidth * idx + graphWidth + outerPadding + middlePadding
              }
              y={0}
            />
          </React.Fragment>
        ))}
      <g className="y-axis-container" />
      {/* migrate directories to strict mode */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {graphRef.current &&
        !isLoading &&
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        primaryYLineScale &&
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        graphDataGroups.map((graphDataGroup, idx) => (
          <BarGraph
            // migrate directories to strict mode
            // eslint-disable-next-line react/no-array-index-key
            key={`graph-${idx}`}
            baseWidth={baseWidth}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            graphDataGroup={graphDataGroup}
            graphWidth={graphWidth}
            // migration to strict mode batch disable
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            groupSize={graphDataGroups.length}
            height={height}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            idx={idx}
            isFlyoutActive={isFlyoutActive}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            maxVolumeDomain={maxVolumeDomain}
            middlePadding={middlePadding}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            onClick={onClick}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            onHover={onHover}
            outerPadding={outerPadding}
            primaryYLineScale={primaryYLineScale.yFunc}
            secondaryYLineScale={secondaryYLineScale.yFunc}
            showCostOrCarbonIntensity={showCostOrCarbonIntensity}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            showMeterData={showMeterData}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            timeRange={timeRange}
          />
        ))}
      <g className="secondary-y-axis-container" />
    </svg>
  )
}
