import type { FC } from "react"
import React from "react"

import { Box, Paper } from "@mui/material"

import { SectionHeader } from "../section-header/section-header"
import type { PageCardProps } from "./page-card.types"

export const PageCard: FC<PageCardProps> = ({
  title,
  children,
  sx,
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Paper {...rest} sx={{ p: 3, ...sx }}>
    {title ? (
      <Box mb={2}>
        <SectionHeader title={title} />
      </Box>
    ) : null}
    {children}
  </Paper>
)
