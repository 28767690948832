import React, { useCallback } from "react"
import type { MouseEvent } from "react"

import { FormTextField } from "@/nzds/forms"

import { Visibility, VisibilityOff } from "@mui/icons-material"
import { IconButton, InputAdornment } from "@mui/material"

import type { PasswordTextFieldProps } from "./password-text-field.types"

export const PasswordTextField = <TSchema,>({
  id,
  isPasswordVisible,
  label,
  onPasswordVisibleClick,
  ...props
}: PasswordTextFieldProps<TSchema>) => {
  const handlePasswordVisibleClick = useCallback(
    (event: MouseEvent): void => {
      onPasswordVisibleClick(event)
    },
    [onPasswordVisibleClick]
  )

  const handlePasswordVisibleMouseDown = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault()
    },
    []
  )

  return (
    <FormTextField<TSchema>
      fullWidth
      id={id}
      inputProps={{
        autoComplete: "new-password",
        type: isPasswordVisible ? "text" : "password",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handlePasswordVisibleClick}
              onMouseDown={handlePasswordVisibleMouseDown}
              edge="end"
            >
              {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={label}
      name={id}
      variant="outlined"
      {...props}
    />
  )
}
