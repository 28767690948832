import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import type { RouteObject } from "react-router-dom"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { OrganizationDashboardIndexRoute } from "@/components/organizationDashboard/organization-dashboard-index-route"
import { RoleGuard } from "@/components/route/role-guard"
import { RootPath } from "@/models/route"

export const dashboardRoutes: RouteObject[] = [
  {
    path: RootPath.Dashboard,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}
      >
        <RoleGuard disallowedRoles={["consultant_user"]}>
          <OrganizationDashboardIndexRoute />
        </RoleGuard>
      </ErrorBoundary>
    ),
  },
]
