import React, { useState } from "react"

import { useOrganizationContext } from "@/contexts"
import { HStack } from "@/nzds/layout"
import { FeatureFlags, useFeature } from "@/services/feature"

import ToggleOffIcon from "@mui/icons-material/ToggleOff"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"

import type { UnitName } from "../models/unit"
import { darkGray, gray500 } from "../utils/colors"
import { GRAPH_COST_OR_CARBON_INTENSITY } from "../utils/constants"
import { Unit } from "./unit/unit"

/**
 * @deprecated
 */
export const GraphLegend = ({
  showMeterData,
  showCostOrCarbonIntensityLine,
  toggleShowMeterData,
  toggleShowCostOrCarbonIntensityLine,
  graphIsLoading,
  wattUnit,
}: {
  graphIsLoading: boolean
  showCostOrCarbonIntensityLine: GRAPH_COST_OR_CARBON_INTENSITY
  showMeterData: boolean
  toggleShowCostOrCarbonIntensityLine: (GRAPH_COST_OR_CARBON_INTENSITY) => void
  toggleShowMeterData: () => void
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const [costCarbonSelected, setCostCarbonSelected] =
    useState<GRAPH_COST_OR_CARBON_INTENSITY>(showCostOrCarbonIntensityLine)

  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const isEpgCostCarbonLineEnabled = isFeatureEnabled(
    FeatureFlags.EPG_COST_CARBON_LINE,
    organization
  )

  const MeterLineSample = () => (
    <svg
      className="meter-toggle"
      height="2"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        stroke={darkGray.toString()}
        strokeDasharray="3,3"
        strokeWidth="4"
        x1="0"
        x2="40"
        y1="0"
        y2="0"
      />
    </svg>
  )

  const AvgCostLineSample = () => (
    <svg
      className="avg-cost-toggle"
      height="6"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line stroke={darkGray.toString()} x1="0" x2="40" y1="3" y2="3" />
      <circle cx="7" cy="3" fill={darkGray.toString()} r="2.5" />
      <circle cx="20" cy="3" fill={darkGray.toString()} r="2.5" />
      <circle cx="33" cy="3" fill={darkGray.toString()} r="2.5" />
    </svg>
  )

  const handleCostCarbonSelected = (
    event: React.MouseEvent<HTMLElement>,
    value: GRAPH_COST_OR_CARBON_INTENSITY | null
  ) => {
    if (value === null) {
      setCostCarbonSelected(GRAPH_COST_OR_CARBON_INTENSITY.NONE)
      toggleShowCostOrCarbonIntensityLine(GRAPH_COST_OR_CARBON_INTENSITY.NONE)
    } else {
      setCostCarbonSelected(value)
      toggleShowCostOrCarbonIntensityLine(value)
    }
  }

  const handleLegacyCostSwitchToggled = () => {
    // Switch between cost and None for costCarbonSelected
    if (costCarbonSelected === GRAPH_COST_OR_CARBON_INTENSITY.COST) {
      setCostCarbonSelected(GRAPH_COST_OR_CARBON_INTENSITY.NONE)
      toggleShowCostOrCarbonIntensityLine(GRAPH_COST_OR_CARBON_INTENSITY.NONE)
    } else {
      setCostCarbonSelected(GRAPH_COST_OR_CARBON_INTENSITY.COST)
      toggleShowCostOrCarbonIntensityLine(GRAPH_COST_OR_CARBON_INTENSITY.COST)
    }
  }

  const LegacyCostToggle = () => (
    <HStack alignItems="center" spacing={1}>
      <AvgCostLineSample />
      <Typography variant="caption">
        Cost / <Unit unit={wattUnit} />
      </Typography>
      <Box
        className="avg-cost-toggle__toggle"
        data-toggle={
          costCarbonSelected === GRAPH_COST_OR_CARBON_INTENSITY.COST
            ? "on"
            : "off"
        }
        onClick={() => {
          handleLegacyCostSwitchToggled()
        }}
        sx={{
          display: "flex",
          color:
            costCarbonSelected === GRAPH_COST_OR_CARBON_INTENSITY.COST
              ? darkGray.toString()
              : gray500.toString(),
          "& .MuiSvgIcon-root": {
            fontSize: "2em",
          },
        }}
      >
        {costCarbonSelected === GRAPH_COST_OR_CARBON_INTENSITY.COST ? (
          <ToggleOnIcon />
        ) : (
          <ToggleOffIcon />
        )}
      </Box>
    </HStack>
  )

  const CostCarbonToggle = () => (
    <div className="legend__toggle avg-cost-toggle">
      <AvgCostLineSample />

      <ToggleButtonGroup
        exclusive
        onChange={handleCostCarbonSelected}
        sx={{ ml: 1 }}
        value={costCarbonSelected}
      >
        {/* TODO: Work with design and handle toggle button styling in theme, remove inline styling */}
        <ToggleButton
          size="small"
          sx={(theme) => ({
            color: theme.palette.grey[800],
            border: `1px solid ${theme.palette.grey[200]}`,
            "&.Mui-selected": {
              backgroundColor: theme.palette.grey[100],
              // Overwrite transparent left border
              border: `1px solid ${theme.palette.grey[500]} !important`,
            },
          })}
          value={GRAPH_COST_OR_CARBON_INTENSITY.COST}
        >
          Cost / <Unit unit={wattUnit} />
        </ToggleButton>
        <ToggleButton
          size="small"
          sx={(theme) => ({
            color: theme.palette.grey[800],
            border: `1px solid ${theme.palette.grey[200]}`,
            "&.Mui-selected": {
              backgroundColor: theme.palette.grey[100],
              // Overwrite transparent left border
              border: `1px solid ${theme.palette.grey[500]} !important`,
            },
          })}
          value={GRAPH_COST_OR_CARBON_INTENSITY.CARBON_INTENSITY}
        >
          Carbon Intensity
        </ToggleButton>
        <ToggleButton
          size="small"
          sx={(theme) => ({
            color: theme.palette.grey[800],
            border: `1px solid ${theme.palette.grey[200]}`,
            "&.Mui-selected": {
              backgroundColor: theme.palette.grey[100],
              // Overwrite transparent left border
              border: `1px solid ${theme.palette.grey[500]} !important`,
            },
          })}
          value={GRAPH_COST_OR_CARBON_INTENSITY.NONE}
        >
          None
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  )

  if (graphIsLoading) {
    return <div />
  }

  const MeterToggle = () => (
    <HStack alignItems="center" spacing={1}>
      <MeterLineSample />
      <Typography variant="caption">Meter</Typography>
      <Box
        onClick={() => {
          toggleShowMeterData()
        }}
        sx={{
          color: showMeterData ? darkGray.toString() : gray500.toString(),
          display: "flex",
          "& .MuiSvgIcon-root": {
            fontSize: "2em",
          },
        }}
      >
        {showMeterData ? <ToggleOnIcon /> : <ToggleOffIcon />}
      </Box>
    </HStack>
  )

  return (
    <>
      {isEpgCostCarbonLineEnabled ? (
        <HStack justifyContent="space-between" sx={{ width: "100%" }}>
          <MeterToggle />
          <CostCarbonToggle />
        </HStack>
      ) : (
        <HStack justifyContent="flex-start" spacing={2} sx={{ width: "100%" }}>
          <LegacyCostToggle />
          <MeterToggle />
        </HStack>
      )}
    </>
  )
}
