import type { ContactDialogType } from "@/components/contact-dialog"
import { useMutation } from "@tanstack/react-query"
import type { UseMutationOptions } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { httpClient } from "../../utils/api"
import type { SuccessData } from "../../utils/api"

interface SendContactEmailParams {
  message: string
  type: ContactDialogType
}

const sendContactEmail = async ({
  message,
  type,
}: SendContactEmailParams): Promise<AxiosResponse<SuccessData>> => {
  const response: AxiosResponse<SuccessData> = await httpClient.post(
    "/api/v1/contact_us",
    { text: message, type }
  )

  return response
}

export const useSendContactEmail = (
  options?: UseMutationOptions<
    AxiosResponse<SuccessData>,
    AxiosError,
    SendContactEmailParams
  >
) => {
  const useMutationOptions = {
    mutationFn: async (emailParams: SendContactEmailParams) =>
      sendContactEmail(emailParams),
    ...options,
  }

  const { data, isError, isLoading, isSuccess, mutateAsync } =
    useMutation(useMutationOptions)

  return {
    sendContactEmail: mutateAsync,
    sendContactEmailData: data,
    sendContactEmailIsError: isError,
    sendContactEmailIsLoading: isLoading,
    sendContactEmailIsSuccess: isSuccess,
  }
}
