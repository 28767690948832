import React from "react"

import { HStack } from "@/nzds/layout"

import { Grid, Typography } from "@mui/material"

import { CurrencyWithPlaceholder } from "../../../../../../components/currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { UsageRateDecimal } from "../../../../../../components/decimal/usageRateDecimal/usageRateDecimal"
import Metric from "../../../../../../components/metric/metric"
import { Unit } from "../../../../../../components/unit/unit"
import { UnitName } from "../../../../../../models/unit"
import {
  KWH_PER_MWH,
  LBS_PER_METRIC_TON,
} from "../../../../../../utils/constants"
import type { IFetchEnergyAllocationStats } from "../../../../../../utils/spraypaintApi"

interface EnergyMetricsProps {
  energyAllocationStats: IFetchEnergyAllocationStats
  wattUnit: UnitName
}

const EnergyMetrics: React.FC<EnergyMetricsProps> = ({
  wattUnit,
  energyAllocationStats,
}) => {
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const totalCarbon: number = energyAllocationStats?.totalCarbon
  const carbonIntensity: number =
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    energyAllocationStats?.totalMWH > 0
      ? energyAllocationStats.totalCarbon / energyAllocationStats.totalMWH
      : 0
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const totalCost: number = energyAllocationStats?.totalCost
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const totalMWH: number = energyAllocationStats?.totalMWH

  const unitizedTotalCarbon: number = Number(totalCarbon) / LBS_PER_METRIC_TON
  const unitizedCarbonIntensity: number =
    Number(carbonIntensity) / LBS_PER_METRIC_TON

  const isMwhSelected: boolean = wattUnit === UnitName.MegawattHour

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} xs={6}>
        <Metric title="Total Usage">
          <HStack alignItems="baseline" spacing={0.5}>
            <Typography>
              <UsageRateDecimal
                value={
                  isMwhSelected ? totalMWH : Number(totalMWH) * KWH_PER_MWH
                }
              />
            </Typography>
            <Typography variant="caption">
              <Unit
                unit={
                  isMwhSelected ? UnitName.MegawattHour : UnitName.KilowattHour
                }
              />
            </Typography>
          </HStack>
        </Metric>
      </Grid>
      <Grid item lg={3} xs={6}>
        <Metric title="Total Cost">
          <Typography>
            <CurrencyWithPlaceholder value={totalCost} />
          </Typography>
        </Metric>
      </Grid>
      <Grid item lg={3} xs={6}>
        <Metric title="Total Emissions">
          <HStack alignItems="baseline" spacing={0.5}>
            <Typography>
              <UsageRateDecimal value={unitizedTotalCarbon} />
            </Typography>
            <Typography variant="caption">
              <Unit unit={UnitName.MetricTonsOfCarbonDioxideEquivalent} />
            </Typography>
          </HStack>
        </Metric>
      </Grid>
      <Grid item lg={3} xs={6}>
        <Metric title="Carbon Intensity">
          <HStack alignItems="baseline" spacing={0.5}>
            <Typography>
              <UsageRateDecimal
                value={
                  isMwhSelected
                    ? unitizedCarbonIntensity
                    : unitizedCarbonIntensity / KWH_PER_MWH
                }
              />
            </Typography>
            <Typography variant="caption">
              <Unit
                unit={
                  isMwhSelected
                    ? UnitName.MetricTonsOfCarbonDioxideEquivalentPerMegawattHour
                    : UnitName.MetricTonsOfCarbonDioxideEquivalentPerKilowattHour
                }
              />
            </Typography>
          </HStack>
        </Metric>
      </Grid>
    </Grid>
  )
}

export default EnergyMetrics
