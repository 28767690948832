// @ts-strict-ignore
import { ApiQueryName } from "@/models/api"
import type { Params } from "@/services/fetch-client/client"
import { useQueryKey, useRenameQueryResult } from "@/services/utils"
import type { UseInfiniteQueryOptions } from "@tanstack/react-query"
import { useInfiniteQuery } from "@tanstack/react-query"

import { getSiteExplorer } from "./get-site-explorer"
import type { SiteExplorerPaginatedData } from "./site-explorer.types"

export const DEFAULT_PAGE_SIZE = 50

interface UseGetSiteExplorerOptions {
  initialPage?: number
  parameters: Params
  queryOptions?: Omit<UseInfiniteQueryOptions, "queryKey" | "queryFn">
}

/**
 * Returns a list of site explorer entries
 *
 * @example
 * const {
 *   siteExplorerData,
 *   siteExplorerError,
 *   siteExplorerIsError
 *   siteExplorerIsLoading,
 * } = useGetSiteExplorer({
 *   parameters: { query: { org_id: 123 } },
 *   queryOptions // react query options
 * })
 */
export const useGetSiteExplorer = ({
  initialPage,
  parameters,
  queryOptions,
}: UseGetSiteExplorerOptions) => {
  const queryKey = useQueryKey(
    ApiQueryName.SiteExplorer,
    "getMany",
    null,
    parameters
  )

  const queryFn = async ({ pageParam = initialPage ?? 1 }) => {
    const newParameters = {
      ...parameters,
    }
    newParameters.query["page[number]"] = pageParam
    return getSiteExplorer(newParameters)
  }

  let queryParams = {
    queryKey,
    queryFn,
  }

  if (queryOptions) {
    queryParams = {
      ...queryOptions,
      ...queryParams,
    }
  }

  const queryResult = useInfiniteQuery<SiteExplorerPaginatedData>({
    ...queryParams,
    getNextPageParam: (lastPage) => {
      if (lastPage.page === lastPage.pages) {
        return undefined
      }
      return lastPage.page + 1
    },
    getPreviousPageParam: (firstPage) => {
      if (firstPage.page === 1) {
        return undefined
      }
      return firstPage.page - 1
    },
    enabled: queryOptions.enabled,
  })

  return useRenameQueryResult(queryResult, ApiQueryName.SiteExplorer)
}
