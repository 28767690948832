import { useActiveOrganizationId } from "../organization"
import { useSitePreviews } from "../useSitePreviews/useSitePreviews"

interface UseEntitiesValue {
  entitiesHasData: boolean
  entitiesIsFetched: boolean
}

/**
 * Determines if the active organization has entities
 *
 * @returns The entities status
 */
export const useEntities = (): UseEntitiesValue => {
  const { activeOrganizationId } = useActiveOrganizationId()
  const { sitePreviewsData, sitePreviewsIsFetched } =
    useSitePreviews(activeOrganizationId)

  return {
    entitiesIsFetched: sitePreviewsIsFetched,
    entitiesHasData: sitePreviewsData?.length > 0,
  }
}
