import React from "react"
import type { FC } from "react"

import Page404 from "@/components/nav/page404/page404"
import { useAuthentication } from "@/services/authentication"

import type { RoleGuardProps } from "./role-guard.types"

/**
 * A guard that displays a 404 UI when the user is not one of the allowed roles
 *
 * @example
 * <RoleGuard allowedRoles={["building_manager"]}>
 *
 * @example
 * <RoleGuard disallowedRoles={["consultant_user"]}>
 */
export const RoleGuard: FC<RoleGuardProps> = ({
  allowedRoles,
  children,
  disallowedRoles,
}) => {
  const { authenticatedUser } = useAuthentication()

  if (!authenticatedUser) {
    return null
  }

  const isAllowed = allowedRoles
    ? allowedRoles.includes(authenticatedUser?.role)
    : !disallowedRoles?.includes(authenticatedUser?.role)

  return isAllowed ? <>{children}</> : <Page404 />
}
