import { useMutation } from "@tanstack/react-query"

import { ApiQueryName } from "../../models/api"
import Meter from "../../models/meter"
import type { QueryMutation, UseDeleteEntityOptions } from "../types"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useDeleteMeter = ({
  onSuccess,
  onError,
}: UseDeleteEntityOptions) => {
  const mutationKey = useQueryKey(ApiQueryName.Meter, "deleteOne")
  const { delete: deleteMeter } = useSpraypaintModel(Meter)
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      serviceEntityKey,
    }: Parameters<QueryMutation<"deleteOne", ModelAttributes<Meter>>>[0]) => {
      await deleteMeter(serviceEntityKey)
    },
    onSuccess: (_, { serviceEntityKey }) => {
      onSuccess()
      invalidateQueries([
        createQueryKey(ApiQueryName.Meter, "getOne", serviceEntityKey),
        createQueryKey(ApiQueryName.Meters, "getMany"),
        createQueryKey(ApiQueryName.BillingGroupCounts, "getOne"),
        createQueryKey(ApiQueryName.OrganizationCounts, "getOne"),
      ])
    },
    onError: () => {
      onError?.()
    },
  })

  return useMutationReturnHandle(mutationKey, mutationResult)
}
