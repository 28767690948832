import { ApiQueryName } from "@/models/api"
import { useQueryKey } from "@/services/utils/useQueryKey"
import type { UseQueryOptions } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"

import { getSite } from "./get-site"

interface UseGetSiteOptions {
  queryOptions?: Omit<UseQueryOptions, "queryKey" | "queryFn">
}

/**
 * Returns a site by id
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetSite({ queryOptions }); // react query options
 */
export const useGetSite = (siteId: string, options: UseGetSiteOptions = {}) => {
  const queryKey = useQueryKey(ApiQueryName.Site, "getOne", siteId)

  let queryParams = {
    queryKey,
    queryFn: () => getSite(siteId),
  }

  if (options.queryOptions) {
    queryParams = {
      ...options.queryOptions,
      ...queryParams,
    }
  }

  const queryResult = useQuery(queryParams)

  return queryResult
}
