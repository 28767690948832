import { useEffect } from "react"

import { useOrganizationContext } from "@/contexts"
import { datadogRum } from "@datadog/browser-rum"

import { useAuthentication } from "../authentication"

export const useDatadogRumSetUser = () => {
  const { authenticatedUser } = useAuthentication()
  const { organization } = useOrganizationContext()

  // Set Datadog RUM user
  useEffect(() => {
    if (authenticatedUser) {
      datadogRum.setUser({
        email: authenticatedUser.email,
        name: authenticatedUser.name,
        organization: organization?.name ?? "",
        role: authenticatedUser.role,
      })
    }
  }, [authenticatedUser, organization?.name])
}
