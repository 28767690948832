import React from "react"
import type { FC } from "react"

import Page404 from "@/components/nav/page404/page404"
import { useOrganizationContext } from "@/contexts"
import { useAuthentication } from "@/services/authentication"
import { FeatureFlags, useFeature } from "@/services/feature"

import { RecommendationsIndexRoute } from "../recommendations-index-route/recommendations-index-route"
import { RecommendationsTeaserRoute } from "../recommendations-teaser-route"

export const RecommendationsIndexRouteGuard: FC = () => {
  const { isConsultantUser } = useAuthentication()
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  if (
    isConsultantUser &&
    !isFeatureEnabled(FeatureFlags.PLANNING, organization)
  ) {
    return <RecommendationsTeaserRoute />
  }

  return isFeatureEnabled(FeatureFlags.PLANNING, organization) ? (
    <RecommendationsIndexRoute />
  ) : (
    <Page404 />
  )
}
