import { useEffect } from "react"

import { ApiQueryName } from "@/models/api"
import type { Schemas } from "@/services/types"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { UseMutationOptions } from "@tanstack/react-query"

import type { UseUploadMutationVariables } from "../services.types"

type UseUploadEntitiesOptions = Omit<
  UseMutationOptions<unknown, unknown, UseUploadMutationVariables>,
  "mutationFn"
>

const uploadEntities = async ({ file }: UseUploadMutationVariables) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.warn("Upload entities not implemented: ", file)

      if (Math.random() < 0.5) {
        reject([
          {
            column: "C",
            description: "Invalid zip code",
            row: 1,
            value: "1234a",
          },
        ])
      } else {
        resolve({ success: true })
      }
    }, 1000)
  })
}

/**
 * Uploads a CSV file of entities data
 *
 * @example
 * const {
 *   error,
 *   isError,
 *   isLoading,
 *   mutateAsync
 * } = useUploadEntities(options)
 *
 * await mutateAsync(file)
 */
export const useUploadEntities = (options: UseUploadEntitiesOptions = {}) => {
  const mutationOptions = options ?? {}

  const queryClient = useQueryClient()

  const mutation = useMutation<
    unknown,
    Schemas["EmissionProfileUploadError"][],
    UseUploadMutationVariables
  >(uploadEntities, mutationOptions)

  useEffect(() => {
    if (mutation.isSuccess) {
      void queryClient.invalidateQueries([ApiQueryName.SitePreviews])
    }
  }, [mutation.isSuccess, queryClient])

  return mutation
}
