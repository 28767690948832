import React, { useState } from "react"
import type { FC } from "react"
import { useNavigate } from "react-router-dom"

import { useOrganizationContext } from "@/contexts"
import type { ISitePreview } from "@/models/site"
import { useSitePreviews } from "@/services/useSitePreviews/useSitePreviews"
import { useUrl } from "@/utils/url"

import SearchIcon from "@mui/icons-material/Search"
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material"

interface SiteOption {
  id: string
  label: string
}

const createSiteOptions = (sites?: ISitePreview[]): SiteOption[] => {
  const siteOptions: SiteOption[] = []

  if (sites) {
    for (const site of sites) {
      siteOptions.push({
        id: site.id,
        label: site.name,
      })
    }
  }

  return siteOptions
}

export const SiteSearch: FC = () => {
  const navigate = useNavigate()
  const { sitesPath } = useUrl()
  const { organization } = useOrganizationContext()
  const { sitePreviewsData } = useSitePreviews(organization?.id)
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)

  const handleSearchChange = (_, selectedSite: SiteOption) => {
    navigate(`${sitesPath}/${selectedSite.id}`)
  }

  const toggleSearchInputVisibility = () => {
    setIsSearchOpen((prev) => !prev)
  }

  return (
    <Box>
      {isSearchOpen ? (
        <Autocomplete
          onBlur={toggleSearchInputVisibility}
          onChange={handleSearchChange}
          openOnFocus
          options={createSiteOptions(sitePreviewsData)}
          noOptionsText="No sites found"
          renderInput={(params) => (
            <TextField
              {...params}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              fullWidth
              InputProps={{
                ...params.InputProps,
                inputProps: {
                  ...params.inputProps,
                  "aria-label": "Search Sites",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Sites"
              variant="outlined"
            />
          )}
          sx={{
            width: 208,
          }}
        />
      ) : (
        <IconButton
          aria-label="Search Sites"
          onClick={toggleSearchInputVisibility}
        >
          <SearchIcon />
        </IconButton>
      )}
    </Box>
  )
}
