import React from "react"
import type { FC, ReactNode } from "react"

import { useUserAccess } from "@/services/user"

import Page404 from "../../nav/page404/page404"

interface LimitedAccessUserGuardProps {
  children: ReactNode
}

/**
 * A guard that displays a 404 UI when the user is a limited access user
 */
export const LimitedAccessUserGuard: FC<LimitedAccessUserGuardProps> = ({
  children,
}) => {
  const { isLimitedAccessUser } = useUserAccess()

  if (isLimitedAccessUser === undefined) {
    return null
  }

  return isLimitedAccessUser ? <Page404 /> : children
}
