import React from "react"

import type { PaperProps } from "@mui/material"
import { CircularProgress, Paper, Typography } from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../models/typography"

export const LoadingSpinner = ({
  text = true,
  size,
  color,
  classes,
  dataE2e,
  ...rest
}: PaperProps & {
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classes?: Record<string, any>
  color?: string
  dataE2e?: string
  size?: number
  text?: boolean
}) => (
  <Paper
    className={rest.className ?? classes?.root ?? null}
    data-e2e={dataE2e}
    elevation={rest.elevation ? rest.elevation : 0}
    sx={{
      backgroundColor: "unset",
      alignItems: "center",
      display: "flex",
      flexFlow: "column",
      height: "100%",
      justifyContent: "center",
      margin: "auto",
    }}
    style={rest.style}
  >
    <CircularProgress size={size || 50} style={color ? { color } : {}} />
    {text && (
      <Typography sx={{ marginTop: "1rem" }} variant={TYPOGRAPHY_VARIANT.h2}>
        {rest.children ? rest.children : "Loading data..."}
      </Typography>
    )}
  </Paper>
)
