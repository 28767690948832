import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"

import { ApiQueryName } from "../../models/api"
import type { IRange } from "../../models/range"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"
import { fetchScopeThreeEmissions } from "./useScopeThreeEmissionsUtils"

/**
 * This hook fetches and provides the scope three emissions for a given organization
 *
 * @param orgId - The id of the organization whose dates to get
 * @param dateRange - The date range to get the report dates for
 * @returns The scope three emissions of the top-most user accessible group for the given organization
 *
 * @example
 * ```tsx
 * import { useScopeThreeEmissions } from '../services'
 *
 * const {
 *   scopeThreeData,
 *   isScopeThreeDataFetched,
 *   isScopeThreeDataLoading
 * } = useScopeThreeEmissions(orgId, { start: moment(), end: moment() })
 * ```
 */
export const useScopeThreeEmissions = (
  orgId: string,
  dateRange: IRange<Moment>
) => {
  const startMonth = dateRange?.start?.format("M")
  const endMonth = dateRange?.end?.format("M")
  const year = dateRange?.start?.format("YYYY")
  const queryKey = useQueryKey(
    ApiQueryName.ScopeThreeEmissions,
    "getMany",
    orgId,
    {
      startMonth,
      endMonth,
      year,
    }
  )

  const queryFn = () =>
    fetchScopeThreeEmissions({
      organizationId: Number(orgId),
      startMonth,
      endMonth,
      year,
    })

  const queryResult = useQuery({
    queryKey,
    queryFn,
    // Disable caching so that our custom d3 scope three chart rerenders when the data changes
    // Using recharts would allow us to turn caching back on
    cacheTime: 0,
    enabled:
      !!orgId && !!dateRange?.start?.isValid() && !!dateRange?.end?.isValid(),
    keepPreviousData: true, // Prevent flash of previous data on date range change
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
