import { useQuery } from "@tanstack/react-query"
import type { WhereClause } from "spraypaint"

import { ApiQueryName } from "../../models/api"
import type { IFetchApplicationRecordOptions } from "../../models/applicationRecord/applicationRecord"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"
import { fetchSites } from "./utils"

export const useSites = (
  organizationId: string,
  options?: {
    clause?: WhereClause
    enabled?: boolean
    fetchOptions?: Partial<IFetchApplicationRecordOptions>
  }
) => {
  const queryKey = useQueryKey(ApiQueryName.Sites, "getMany", null, {
    organizationId,
    ...options,
  })

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => fetchSites(organizationId, options),
    enabled: options?.enabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
