// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from "react"

import { Page, PageHeaderActionBar } from "@/components"
import { HStack } from "@/nzds/layout"
import { useAvailableReportDates } from "@/services"
import type { Moment } from "moment"

import {
  Box,
  Collapse,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material"

import { useDashboardContext } from "../../../../components/dashboard/useDashboardContext"
import { EmissionDecimal } from "../../../../components/decimal/emissionDecimal/emissionDecimal"
import { UsageRateDecimal } from "../../../../components/decimal/usageRateDecimal/usageRateDecimal"
import { MonthlyStackedAreaGraph } from "../../../../components/graph/monthly-stacked-area-graph/monthly-stacked-area-graph"
import Metric from "../../../../components/metric/metric"
import { NaturalGasPanel } from "../../../../components/naturalGasPanel"
import { Unit } from "../../../../components/unit/unit"
import useFilters from "../../../../hooks/useFilters/useFilters"
import { MetricType } from "../../../../models/decimal/decimal"
import DateFilter from "../../../../models/filter/dateFilter"
import type { IRange } from "../../../../models/range"
import { UnitName } from "../../../../models/unit"
import { MonthRangePicker } from "../../../../nzds/inputs/month-range-picker"
import { MAX_MONTHS_IN_RANGE } from "../../../../utils/constants"
import {
  clampDateRange,
  getMonthsInRange,
  isValidCrossYearDateRange,
} from "../../../../utils/date"
import { useMonthlySiteNaturalGasEmissionsData } from "../../services/useMonthlySiteNaturalGasEmissionsData/useMonthlySiteNaturalGasEmissionsData"
import { useNaturalGasDateRange } from "../../services/useNaturalGasDateRange/useNaturalGasDateRange"
import EmptyChart, {
  DATE_PICKER_ERROR_MESSAGE,
  DATE_RANGE_ERROR_SUBHEADER,
  DEFAULT_HEADER,
} from "../site/emptyChart"
import type { SiteFilters } from "../site/site.types"

const naturalGasTooltipLabelsMap = {
  naturalGasThms: {
    label: "Natural Gas",
    metricFormattingComponentId: MetricType.Emission,
  },
}

const NaturalGas: React.FC = () => {
  const theme = useTheme()
  const { naturalGasDateRange, setNaturalGasDateRange } =
    useNaturalGasDateRange()

  const { filters, setFilters } = useFilters<SiteFilters>({
    start: DateFilter,
    end: DateFilter,
  })
  const [datePickerError, setDatePickerError] = useState<boolean>(false)
  const { site, orgId, isSiteInfoOpen, siteInfoButton } = useDashboardContext()
  const { availableReportDatesData } = useAvailableReportDates(orgId)

  const colors: string[] = [theme.palette.spectrum.purple[500]]

  const naturalGasOverviewTitle = "Natural Gas Overview"

  const {
    graphData,
    isMonthlySiteNaturalGasEmissionsDataLoading,
    totalVolumeNaturalGasGasThm,
    dailyAvgNaturalGasThm,
    totalEmissionsNaturalGasCo2eLbs,
  } = useMonthlySiteNaturalGasEmissionsData(
    orgId,
    {
      start: filters.start.value,
      end: filters.end.value,
    },
    { siteId: site.id }
  )

  const handleDateChange = useCallback(
    (value: IRange<Moment>): void => {
      setFilters({
        start: new DateFilter(value.start),
        end: new DateFilter(value.end),
      })
      setNaturalGasDateRange(value)
    },
    [setFilters, setNaturalGasDateRange]
  )

  // Ensure query params are valid values
  useEffect(() => {
    if (
      availableReportDatesData?.start.isValid() &&
      availableReportDatesData.end.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !naturalGasDateRange?.start?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !naturalGasDateRange?.end?.isValid()
    ) {
      // Clamp the start and end dates to the availableReportDates
      if (
        !isValidCrossYearDateRange(
          {
            start: filters.start.value,
            end: filters.end.value,
          },
          availableReportDatesData
        )
      ) {
        let dateRange = {
          start: availableReportDatesData.end.clone().startOf("year"),
          end: availableReportDatesData.end.clone().endOf("month"),
        }

        // Clamp date range if start date is before service hour min date
        dateRange = clampDateRange(dateRange, {
          start: availableReportDatesData.start.clone().startOf("month"),
          end: availableReportDatesData.end.clone().endOf("month"),
        })

        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const startDate = dateRange.start
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const endDate = dateRange.end

        setFilters(
          {
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            start: new DateFilter(startDate),
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            end: new DateFilter(endDate),
          },
          { replace: true }
        )
        setNaturalGasDateRange({
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          start: startDate,
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          end: endDate,
        })
      }
      // Set the naturalGasDateRange to the query params when they are valid
      else {
        setNaturalGasDateRange({
          start: filters.start.value,
          end: filters.end.value,
        })
      }
    } else if (
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      naturalGasDateRange?.start?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      naturalGasDateRange?.end?.isValid()
    ) {
      const monthsInRange = getMonthsInRange(
        naturalGasDateRange.start,
        naturalGasDateRange.end
      )
      if (monthsInRange >= MAX_MONTHS_IN_RANGE) {
        setDatePickerError(true)
      } else {
        setDatePickerError(false)
      }
    }
    // migrate directories to strict mode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableReportDatesData,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    naturalGasDateRange?.end,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    naturalGasDateRange?.start,
    filters.end.value,
    filters.start.value,
    setNaturalGasDateRange,
    setFilters,
  ])

  // Set start and end date if they are not set in the url
  useEffect(() => {
    if (
      (!filters.start.value || !filters.end.value) &&
      availableReportDatesData?.end.isValid()
    ) {
      // Set filters to default if not stored in our naturalGasDateRange service
      if (
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !naturalGasDateRange?.start?.isValid() ||
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !naturalGasDateRange?.end?.isValid() ||
        !isValidCrossYearDateRange(
          {
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            start: naturalGasDateRange?.start,
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            end: naturalGasDateRange?.end,
          },
          availableReportDatesData
        )
      ) {
        const defaultStart = availableReportDatesData.end
          .clone()
          .startOf("year")
        const defaultEnd = availableReportDatesData.end.clone().endOf("month")
        setFilters(
          {
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            start: new DateFilter(defaultStart),
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            end: new DateFilter(defaultEnd),
          },
          { replace: true }
        )
        setNaturalGasDateRange({
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          start: defaultStart,
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          end: defaultEnd,
        })
      }
      // Set filters to stored naturalGasDateRange values
      else {
        setFilters(
          {
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            start: new DateFilter(naturalGasDateRange?.start),
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            end: new DateFilter(naturalGasDateRange?.end),
          },
          { replace: true }
        )
      }
    }
  }, [
    availableReportDatesData,
    availableReportDatesData?.end,
    availableReportDatesData?.start,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    naturalGasDateRange?.end,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    naturalGasDateRange?.start,
    filters.end.value,
    filters.start.value,
    setNaturalGasDateRange,
    setFilters,
  ])

  return (
    <>
      <PageHeaderActionBar>
        <HStack alignItems="center" justifyContent="space-between">
          <HStack alignItems="center" spacing={1}>
            <MonthRangePicker
              availableMaxMinMonths={availableReportDatesData}
              error={datePickerError}
              errorMessage={DATE_PICKER_ERROR_MESSAGE}
              onChange={handleDateChange}
              value={{
                start: filters.start.value,
                end: filters.end.value,
              }}
            />
            <Typography
              component="span"
              sx={{ color: theme.palette.grey[400] }}
              variant="caption"
            >
              {MAX_MONTHS_IN_RANGE} months max
            </Typography>
          </HStack>

          <HStack>
            {siteInfoButton}
            <Collapse in={isSiteInfoOpen} orientation="horizontal">
              <Box width={theme.typography.pxToRem(320)} />
            </Collapse>
          </HStack>
        </HStack>
      </PageHeaderActionBar>
      <Page>
        <Paper sx={{ p: 3 }}>
          {datePickerError ? (
            <EmptyChart
              header={`Natural Gas ${DEFAULT_HEADER}`}
              subHeader={DATE_RANGE_ERROR_SUBHEADER}
            />
          ) : (
            <>
              <Typography
                data-e2e="natural-gas-overview-title"
                mb={2}
                variant="h2"
              >
                {naturalGasOverviewTitle}
              </Typography>
              <Grid container mb={2} spacing={2}>
                <Grid item md={4} xs={12}>
                  <Metric title="Total Volume">
                    <div>
                      <UsageRateDecimal value={totalVolumeNaturalGasGasThm} />
                    </div>
                    <Typography variant="caption">
                      <Unit unit={UnitName.Therm} />
                    </Typography>
                  </Metric>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Metric title="Average Daily Use">
                    <div>
                      <UsageRateDecimal value={dailyAvgNaturalGasThm} />
                    </div>
                    <Typography variant="caption">
                      <Unit unit={UnitName.Therm} />
                    </Typography>
                  </Metric>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Metric title="Total Emissions">
                    <div data-e2e="total-emissions-natural-gas-tab">
                      <EmissionDecimal
                        value={totalEmissionsNaturalGasCo2eLbs}
                      />
                    </div>
                    <Typography variant="caption">
                      <Unit unit={UnitName.PoundsOfCarbonDioxideEquivalent} />
                    </Typography>
                  </Metric>
                </Grid>
              </Grid>

              <Box
                data-e2e="natural-gas-area-chart"
                height={theme.typography.pxToRem(288)}
              >
                {/* migrate directories to strict mode */}
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                {graphData?.length > 0 &&
                  !isMonthlySiteNaturalGasEmissionsDataLoading && (
                    <MonthlyStackedAreaGraph
                      colors={colors}
                      isTotalHidden
                      keys={["naturalGasThms"]}
                      rawData={graphData}
                      title={naturalGasOverviewTitle}
                      tooltipLabelsMap={naturalGasTooltipLabelsMap}
                      unit={UnitName.Therm}
                    />
                  )}
              </Box>

              <Divider sx={{ my: 2 }} />
              <NaturalGasPanel site={site} />
            </>
          )}
        </Paper>
      </Page>
    </>
  )
}

export default NaturalGas
