// @ts-strict-ignore
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import { ElectricityType } from "../../../../models/energyAllocation"
import type { IRange } from "../../../../models/range"
import type Site from "../../../../models/site"
import { fetchDirectEmissionsAndEnergyVolume } from "./useDirectEmissionsAndEnergyVolumeUtils"

export const useDirectEmissionsAndEnergyVolume = (
  site: Site,
  { start, end }: IRange<Moment>
) => {
  const queryKey = useQueryKey(
    ApiQueryName.DirectEmissionsAndEnergyVolume,
    "getOne",
    null,
    {
      site,
      start,
      end,
    }
  )

  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison, @typescript-eslint/no-unnecessary-condition
  const isDirect = site?.electricityType === ElectricityType.DirectAccess

  const queryResult = useQuery({
    queryKey,
    queryFn: async () =>
      fetchDirectEmissionsAndEnergyVolume({
        site,
        yearStart: start.format(),
        dayStart: end.format(),
      }),
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    enabled: !!site && isDirect && !!start && !!end && !!start?.isBefore(end),
    keepPreviousData: true,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
