import { ApiQueryName } from "@/models/api"
import { useQuery } from "@tanstack/react-query"

import { useRenameQueryResult } from "../utils"
import type { GtagData } from "./use-gtag.types"

const initGtag = (tagId: string): void => {
  window.dataLayer = window.dataLayer || []

  window.gtag = function () {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments)
  }

  window.gtag("js", new Date())
  window.gtag("config", tagId)
}

const loadGtag = (): Promise<GtagData> => {
  return new Promise((resolve) => {
    const handleGtagLoad = (tagId: string) => {
      initGtag(tagId)
      resolve({
        gtag: window.gtag,
      })
    }

    // Add script element
    const tagId = "G-ZXY1XWP5YD"
    const scriptTagName = "script"
    const scriptElement = document.createElement("script")
    const allScriptElements: HTMLScriptElement | undefined =
      document.getElementsByTagName(scriptTagName)[0]

    scriptElement.async = true
    scriptElement.src = "https://www.googletagmanager.com/gtag/js?id=" + tagId
    scriptElement.onload = () => {
      handleGtagLoad(tagId)
    }

    allScriptElements?.parentNode.insertBefore(scriptElement, allScriptElements)
  })
}

/**
 * The useGtag service/hook loads and provides gtag
 *
 * @returns GtagData
 * @example
 * const {
 *   gtagData,
 *   gtagisFetched,
 * } = useGtag()
 */
export const useGtag = () => {
  const query = useQuery({
    // Loading gtag (a third-party script) only needs to be done once
    cacheTime: Infinity,
    queryFn: loadGtag,
    queryKey: [ApiQueryName.Gtag],
    staleTime: Infinity,
  })

  return useRenameQueryResult(query, ApiQueryName.Gtag)
}
