import { useQuery } from "@tanstack/react-query"
import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import OrganizationModel from "../../models/organization"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useOrganization = (
  organizationId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const { getOne } = useSpraypaintModel(OrganizationModel)

  const queryKey = useQueryKey(
    ApiQueryName.Organization,
    "getOne",
    organizationId
  )

  const queryResult = useQuery({
    queryKey,
    queryFn: () => getOne(organizationId, { include: ["locale"] }),
    enabled: !isNil(organizationId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type Organization = InferredQueryServiceValue<
  typeof useOrganization,
  ApiQueryName.Organization
>
