import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Outlet } from "react-router-dom"
import type { RouteObject } from "react-router-dom"

import { DataGuard } from "@/components/data-guard"
import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { ConsultantDataGuard } from "@/components/route/consultant-data-guard"
import { FeatureGuard } from "@/components/route/featureGuard/featureGuard"
import { LimitedAccessUserGuard } from "@/components/route/limited-access-user-guard"
import { RoleGuard } from "@/components/route/role-guard"
import { ReportPath, RootPath } from "@/models/route"
import { FeatureFlags } from "@/services/feature"

import ActivityEmissions from "./components/activityEmissions/activityEmissions"
import ActivityEmissionsResolver from "./components/activityEmissionsResolver/activityEmissionsResolver"
import { CustomReport } from "./components/customReports/customReport/customReport"
import CustomReports from "./components/customReports/customReports"
import Gresb from "./components/gresb/gresb"
import GresbResolver from "./components/gresbResolver/gresbResolver"
import Gri from "./components/gri/gri"
import GriResolver from "./components/griResolver/griResolver"
import MonthlySiteData from "./components/monthlySiteData/monthlySiteData"
import MonthlySiteDataResolver from "./components/monthlySiteDataResolver/monthlySiteDataResolver"
import ReportSummary from "./components/reportSummary/reportSummary"
import { Reporting } from "./components/reporting/reporting"
import ScopeThree from "./components/scopeThree/scopeThree"
import ScopeThreeResolver from "./components/scopeThreeResolver/scopeThreeResolver"
import { SigmaReportRoute } from "./pages/sigma-report-route"

export const reportRoutes: RouteObject[] = [
  {
    path: RootPath.Report,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}
      >
        <ConsultantDataGuard>
          <DataGuard>
            <Outlet />
          </DataGuard>
        </ConsultantDataGuard>
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <Reporting />,
      },
      {
        path: `${ReportPath.CustomReports}`,
        element: (
          <FeatureGuard feature={FeatureFlags.REPORT_CUSTOM_REPORTS}>
            <RoleGuard disallowedRoles={["consultant_user"]}>
              <LimitedAccessUserGuard>
                <CustomReports />
              </LimitedAccessUserGuard>
            </RoleGuard>
          </FeatureGuard>
        ),
      },
      {
        path: `${ReportPath.CustomReports}/${ReportPath.Workbook}`,
        element: (
          <FeatureGuard feature={FeatureFlags.REPORT_CUSTOM_REPORTS}>
            <RoleGuard disallowedRoles={["consultant_user"]}>
              <LimitedAccessUserGuard>
                <CustomReport />
              </LimitedAccessUserGuard>
            </RoleGuard>
          </FeatureGuard>
        ),
      },
      {
        path: `${ReportPath.EmissionsSources}`,
        element: (
          <FeatureGuard feature={FeatureFlags.REPORT_ACTIVITY_EMISSIONS}>
            <RoleGuard disallowedRoles={["consultant_user"]}>
              <LimitedAccessUserGuard>
                <ActivityEmissionsResolver>
                  <ActivityEmissions />
                </ActivityEmissionsResolver>
              </LimitedAccessUserGuard>
            </RoleGuard>
          </FeatureGuard>
        ),
      },
      {
        path: `${ReportPath.Gresb}`,
        element: (
          <FeatureGuard feature={FeatureFlags.REPORT_GRESB}>
            <LimitedAccessUserGuard>
              <GresbResolver>
                <Gresb />
              </GresbResolver>
            </LimitedAccessUserGuard>
          </FeatureGuard>
        ),
      },
      {
        path: `${ReportPath.Gri}`,
        element: (
          <FeatureGuard feature={FeatureFlags.REPORT_GRI}>
            <LimitedAccessUserGuard>
              <GriResolver>
                <Gri />
              </GriResolver>
            </LimitedAccessUserGuard>
          </FeatureGuard>
        ),
      },
      {
        path: `${ReportPath.MonthlySiteData}`,
        element: (
          <FeatureGuard feature={FeatureFlags.REPORT_MONTHLY_SITE_DATA}>
            <RoleGuard disallowedRoles={["consultant_user"]}>
              <MonthlySiteDataResolver>
                <MonthlySiteData />
              </MonthlySiteDataResolver>
            </RoleGuard>
          </FeatureGuard>
        ),
      },
      {
        path: `${ReportPath.ResourceSummary}`,
        element: (
          <RoleGuard disallowedRoles={["consultant_user"]}>
            <ReportSummary />
          </RoleGuard>
        ),
      },
      {
        path: `${ReportPath.ScopeThree}`,
        element: (
          <RoleGuard disallowedRoles={["consultant_user"]}>
            <LimitedAccessUserGuard>
              <ScopeThreeResolver>
                <ScopeThree />
              </ScopeThreeResolver>
            </LimitedAccessUserGuard>
          </RoleGuard>
        ),
      },
      {
        path: `${ReportPath.Workbook}`,
        element: (
          <RoleGuard allowedRoles={["consultant_user"]}>
            <SigmaReportRoute />
          </RoleGuard>
        ),
      },
    ],
  },
]
