import { useQuery } from "@tanstack/react-query"
import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import BillingGroupModel from "../../models/billingGroup"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useBillingGroup = (
  billingGroupId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const { getOne } = useSpraypaintModel(BillingGroupModel)

  const queryKey = useQueryKey(
    ApiQueryName.BillingGroup,
    "getOne",
    billingGroupId
  )

  const queryResult = useQuery({
    queryKey,
    queryFn: () => getOne(billingGroupId),
    enabled: !isNil(billingGroupId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type BillingGroup = InferredQueryServiceValue<
  typeof useBillingGroup,
  ApiQueryName.BillingGroup
>
