import type { Paths } from "@/services/types"
import type { UseMutationOptions } from "@tanstack/react-query"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { ApiQueryName } from "../../../../models/api"
import type { UpdateGoalParams } from "./update-goal"
import { updateGoal } from "./update-goal"

interface UseUpdateGoalOptions {
  mutationOptions?: Omit<
    UseMutationOptions<
      Paths["/api/v1/recommendations/goals/{goal_id}"]["put"]["responses"]["200"]["content"]["application/json"],
      Paths["/api/v1/recommendations/goals/{goal_id}"]["put"]["responses"]["422"]["content"]["application/json"],
      UpdateGoalParams
    >,
    "mutationFn"
  >
}

/**
 * Updates a goal
 *
 * @example
 * const { mutateAsync, data, isLoading, error, isError } =
 * useUpdateGoal({
 *   mutationOptions // react query options
 * });
 *
 */
export const useUpdateGoal = (options?: UseUpdateGoalOptions) => {
  const mutationOptions = options?.mutationOptions
  const queryClient = useQueryClient()

  const mutation = useMutation(updateGoal, {
    ...(mutationOptions ?? {}),
    onSuccess: (data, variables, context) => {
      if (mutationOptions?.onSuccess) {
        void mutationOptions.onSuccess(data, variables, context)
      }

      void queryClient.invalidateQueries([
        ApiQueryName.RecommendationsGoals,
        "getMany",
      ])
    },
  })
  return mutation
}
