import React from "react"
import type { FC } from "react"

import { DialogTitle } from "@/nzds/feedback/dialog-title/dialog-title"
import { HStack } from "@/nzds/layout"

import WarningRoundedIcon from "@mui/icons-material/WarningRounded"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material"

import type { ErrorDialogProps } from "./error-dialog.types"

export const ErrorDialog: FC<ErrorDialogProps> = ({
  confirmText,
  details,
  message,
  id,
  isOpen,
  onClose,
  onConfirm,
  title,
}) => (
  <Dialog
    aria-describedby={`${id}-dialog-description`}
    aria-labelledby={`${id}-dialog-title`}
    fullWidth
    maxWidth="sm"
    onClose={onClose}
    open={isOpen}
  >
    <DialogTitle id={`${id}-dialog-title`} onClose={onClose}>
      {title}
    </DialogTitle>
    <DialogContent id={`${id}-dialog-description`}>
      <HStack gap={1} mb={2}>
        <WarningRoundedIcon
          sx={{ color: (theme) => theme.palette.error.dark }}
        />
        {message}
      </HStack>
      <Box ml={1}>{details}</Box>
    </DialogContent>
    <DialogActions sx={{ justifyContent: "flex-end" }}>
      <Button
        color="neutral"
        onClick={() => {
          onClose()
          onConfirm?.()
        }}
        variant="outlined"
      >
        {confirmText ?? "Ok"}
      </Button>
    </DialogActions>
  </Dialog>
)
