import type { FC } from "react"
import React, { useCallback, useEffect, useMemo } from "react"
import { Link as RRLink, generatePath } from "react-router-dom"

import { DataGridColumnHeader } from "@/nzds/data-grid"
import { useUrl } from "@/utils/url"

import SearchRoundedIcon from "@mui/icons-material/SearchRounded"
import { InputAdornment, Link, Stack } from "@mui/material"
import type {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro"
import {
  DataGridPro,
  GridLogicOperator,
  useGridApiRef,
} from "@mui/x-data-grid-pro"

import { PageCard } from "../../../../components/page-card/page-card"
import { SectionHeader } from "../../../../components/section-header/section-header"
import { useUnit } from "../../../../components/unit/use-unit"
import { useFormatDecimal } from "../../../../formatters/useFormatDecimal"
import { useFormatMoney } from "../../../../formatters/useFormatMoney"
import { useFormatPercentage } from "../../../../formatters/useFormatPercentage"
import { useCurrencySymbol } from "../../../../hooks/useCurrencySymbol/useCurrencySymbol"
import { UnitName } from "../../../../models/unit"
import { TextField } from "../../../../nzds"
import { renderRowNumber } from "../../../../utils/render-row-number"
import { emptyDataPlaceholder } from "../../constants"
import { RecommendationsPath } from "../../enums"
import { PlanningSteps } from "../planning-steps/planning-steps"
import type {
  PotentialSitesGridProps,
  RowData,
} from "./potential-sites-grid.types"

const baseColumnDef: Partial<GridColDef> = {
  disableColumnMenu: true,
  hideable: false,
  align: "left",
  disableReorder: true,
  resizable: false,
}

export const PotentialSitesGrid: FC<PotentialSitesGridProps> = ({ sites }) => {
  const { recommendationsPath } = useUrl()

  const { format: formatPercentage } = useFormatPercentage({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const { format: formatDecimal } = useFormatDecimal({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const { format: formatMoney } = useFormatMoney({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const apiRef = useGridApiRef()

  const [quickFilter, setQuickFilter] = React.useState("")

  useEffect(() => {
    apiRef.current.setQuickFilterValues(quickFilter.split(" "))
  }, [apiRef, quickFilter])

  const getGoalsHref = useCallback(
    (id: number) =>
      generatePath(`${recommendationsPath}/${RecommendationsPath.SiteGoals}`, {
        siteId: `${id}`,
      }),
    [recommendationsPath]
  )

  const currencySymbol = useCurrencySymbol()

  const co2eUnit = useUnit(UnitName.MetricTonsOfCarbonDioxideEquivalent)

  const gjSqFtUnit = useUnit(UnitName.GigaJoulePerSquareFoot)

  const rows: RowData[] = useMemo(
    () =>
      (sites ?? []).map((site) => ({
        base_total_emissions: site.base_total_emissions,
        base_total_usage: site.base_total_usage,
        eui: site.eui,
        portfolio_impact: site.portfolio_impact,
        id: site.site_id,
        site_name: site.site_name,
        total_cost: site.total_cost,
      })),
    [sites]
  )

  const columnDefinitions = useMemo(() => {
    const columns: GridColDef[] = [
      {
        ...baseColumnDef,
        field: "number",
        align: "center",
        headerAlign: "center",
        sortable: false,
        headerName: "#",
        width: 44,
        renderCell: renderRowNumber,
      },
      {
        ...baseColumnDef,
        field: "site_name",
        flex: 1,
        headerName: "Site",
        minWidth: 200,
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Link component={RRLink} noWrap to={getGoalsHref(params.row.id)}>
            {params.value}
          </Link>
        ),
      },
      {
        ...baseColumnDef,
        field: "portfolio_impact",
        align: "right",
        headerAlign: "right",
        headerName: "Portfolio Emissions (%)",
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description="Portfolio Emissions (%)"
            field={params.field}
            headerName="Portfolio Emissions"
            unit="%"
          />
        ),
        flex: 1,
        valueFormatter: (
          params: GridValueFormatterParams<RowData["portfolio_impact"]>
        ) => formatPercentage(params.value),
      },
      {
        ...baseColumnDef,
        field: "base_total_emissions",
        align: "right",
        headerAlign: "right",
        headerName: `Annual Emissions (${co2eUnit})`,
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description={`Annual Emissions (${co2eUnit})`}
            field={params.field}
            headerName="Annual Emissions"
            unit={co2eUnit}
          />
        ),
        flex: 1,
        valueFormatter: (
          params: GridValueFormatterParams<RowData["base_total_emissions"]>
        ) => formatDecimal(params.value),
      },
      {
        ...baseColumnDef,
        field: "base_total_usage",
        align: "right",
        headerAlign: "right",
        headerName: `Annual Volume (${gjSqFtUnit})`,
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description={`Annual Volume (${gjSqFtUnit})`}
            field={params.field}
            headerName="Annual Volume"
            unit={gjSqFtUnit}
          />
        ),
        flex: 1,
        valueFormatter: (
          params: GridValueFormatterParams<RowData["base_total_usage"]>
        ) =>
          formatDecimal(params.value, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
      },
      {
        ...baseColumnDef,
        field: "eui",
        align: "right",
        headerAlign: "right",
        headerName: `EUI  (${gjSqFtUnit})`,
        resizable: true,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description={`EUI  (${gjSqFtUnit})`}
            field={params.field}
            headerName="EUI"
            tooltipContent="Energy use intensity (EUI) helps assess a sites performance and efficiency by dividing total usage by the size (sq ft) of the site"
            unit={gjSqFtUnit}
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<RowData["eui"]>) =>
          params.value
            ? formatDecimal(params.value, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : emptyDataPlaceholder,
      },
      {
        ...baseColumnDef,
        field: "total_cost",
        align: "right",
        headerAlign: "right",
        headerName: `Operational Cost (${currencySymbol})`,
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description={`Operational Cost (${currencySymbol})`}
            field={params.field}
            headerName="Operational Cost"
            unit={currencySymbol}
          />
        ),
        flex: 1,
        valueFormatter: (
          params: GridValueFormatterParams<RowData["total_cost"]>
        ) => formatMoney(params.value),
      },
    ]

    return columns
  }, [
    co2eUnit,
    currencySymbol,
    formatDecimal,
    formatMoney,
    formatPercentage,
    getGoalsHref,
    gjSqFtUnit,
  ])

  return (
    <PageCard>
      <Stack gap={3}>
        <PlanningSteps activeStep={0} />
        <SectionHeader
          actions={
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setQuickFilter(e.target.value)
              }}
              size="small"
            />
          }
          title="Forecasted Site Performance"
        />

        <DataGridPro
          apiRef={apiRef}
          columnHeaderHeight={62}
          columns={columnDefinitions}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          disableVirtualization
          initialState={{
            sorting: {
              sortModel: [{ field: "carbon_removed_lbs", sort: "desc" }],
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLogicOperator.Or,
              },
            },
          }}
          rowHeight={48}
          rows={rows}
        />
      </Stack>
    </PageCard>
  )
}
