import "moment-timezone"

import { UnitName } from "../models/unit"
import {
  cometBlue,
  darkGray,
  darkSlateBlue,
  ghostWhite,
  lightSlateBlue,
  linkWater,
} from "./colors"

export const appCompanyName = "NZero"
export const KILO = 1000
export const BTU_PER_GJ = 947817
export const UTC_TIMEZONE = "Etc/UTC"
export const MAX_CUSTOMER_BILLING_PERIOD_DAYS = 35
export const MAX_MONTHS_IN_RANGE = 36
export const DEFAULT_FORM_FIELD_EMPTY_PLACEHOLDER = "--"
export const EMPTY_DATA_PLACEHOLDER = "--"
export const TOOLTIP_WIDTH_RIGHT_EXTENSION = 36
export const ORGANIZATION_DASHBOARD_TICK_TEXT_LIMIT = 8
export const DAYS_IN_WEEK = 7
export const MAX_DAYS_IN_MONTH = 31
export const HOURS_IN_DAY = 24
export const KWH_PER_MWH = 1_000.0
export const METERED_LINE = 0.01
export const CENTS_PER_DOLLAR = 100
export const LBS_PER_METRIC_TON = 2_204.62
export const LBS_PER_KG = LBS_PER_METRIC_TON / 1_000
export const GJ_PER_KWH = 0.0036
export const GJ_PER_THERM = 0.1054804
export const CUSTOMER_MTD_OFFSET_DAYS = 7
export const KG_PER_METRIC_TON = 1_000.0
export const CUBIC_METERS_PER_US_LIQUID_GALLON = 264.1721
export const FOOT_PER_METER = 3.28084
export const SQUARE_FOOT_PER_SQUARE_METER = FOOT_PER_METER * FOOT_PER_METER
export const GRID_CO2E_RATE = 803.341
export const GRID_CO2E_RATE_MAP = new Map<string, number>()
export const KBTU_PER_KWH = 3.412142
export const KBTU_PER_THERM = 99.976
export const THERMS_PER_MMBTU = 10
export const LEFT_NAV_COLLAPSED_WIDTH = 56
export const LEFT_NAV_EXPANDED_WIDTH = 214
export const APPNAME = "NZero"
export const SOLAR_TO_GRID = "Solar to Grid"
export const DATE_FORMAT = "MM/DD/YYYY"
export const CSV_LIMITER = ","
export const CSV_NEW_LINE = "\r\n"
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
export const MAX_API_PAGE_SIZE = 250

GRID_CO2E_RATE_MAP.set("CISO", 504.837)
GRID_CO2E_RATE_MAP.set("PSCO", 1217.372)
GRID_CO2E_RATE_MAP.set("PJM", 810.87)
GRID_CO2E_RATE_MAP.set("NEVP", 803.341)

export const enum EnergyUnit {
  TOKEN = "TOKEN",
  WATTAGE = "WATTAGE",
}

export enum GRAPH_DETAIL_MODAL_ORIENTATION {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum GRAPH_COST_OR_CARBON_INTENSITY {
  CARBON_INTENSITY = "CARBON_INTENSITY",
  COST = "COST",
  NONE = "NONE",
}

export enum TIME_RANGES {
  DAY,
  MONTH,
  YEAR,
  RANGE,
}

export const yAxisText = (timeRange: TIME_RANGES) => {
  switch (timeRange) {
    case TIME_RANGES.DAY:
      return "HOUR END"
    case TIME_RANGES.YEAR:
      return "MONTH"
    default:
      return "DAY"
  }
}

export const bundledCustomerCarbonDelimiters = new Map([
  [
    "lowest",
    {
      name: "lowest",
      ratio: 0.25,
      color: ghostWhite,
      stroke: false,
      fill: "",
    },
  ],
  [
    "low",
    {
      name: "low",
      ratio: 0.125,
      color: linkWater,
      stroke: false,
      fill: "",
    },
  ],
  [
    "medium low",
    {
      name: "medium low",
      ratio: 0.125,
      color: lightSlateBlue,
      stroke: false,
      fill: "",
    },
  ],
  [
    "medium high",
    {
      name: "medium high",
      ratio: 0.125,
      color: cometBlue,
      stroke: false,
      fill: "",
    },
  ],
  [
    "high",
    {
      name: "high",
      ratio: 0.125,
      color: darkSlateBlue,
      stroke: false,
      fill: "",
    },
  ],
  [
    "highest",
    {
      name: "highest",
      ratio: 0.25,
      color: darkGray,
      stroke: false,
      fill: "",
    },
  ],
])

const svgRotationOffset = -Math.PI
const θs: [number, number] = [
  Math.PI / 4 + svgRotationOffset,
  (7 * Math.PI) / 4 + svgRotationOffset,
]

export const CarbonIntensityGraphDefaults: {
  annulusThicknessRatio: number
  data: typeof bundledCustomerCarbonDelimiters
  domain: [number, number]
  intervals: number
  outlineThickness: number
  overlayData: {
    name: string
    units: UnitName
  }
  range: [number, number]
} = {
  range: θs,
  domain: [0, 0.8],
  intervals: 9,
  outlineThickness: 1,
  annulusThicknessRatio: 5,
  data: bundledCustomerCarbonDelimiters,
  overlayData: {
    name: "Carbon Intensity",
    units: UnitName.MetricTonsOfCarbonDioxideEquivalentPerMegawattHour,
  },
}
