import { useMutation } from "@tanstack/react-query"
import type { AxiosResponse } from "axios"

import { httpClient } from "../../utils"
import type { SuccessData } from "../../utils"

const validateAccount = async (
  token: string
): Promise<AxiosResponse<SuccessData>> => {
  return httpClient
    .get<SuccessData>("/users/confirmation", {
      params: { confirmation_token: token },
    })
    .then((response) => {
      return response
    })
}

export const useValidateAccount = () => {
  const { data, isError, isLoading, isSuccess, mutateAsync } = useMutation({
    mutationFn: (token: string) => validateAccount(token),
  })

  return {
    validateAccount: mutateAsync,
    validateAccountData: data,
    validateAccountIsError: isError,
    validateAccountIsLoading: isLoading,
    validateAccountIsSuccess: isSuccess,
  }
}
