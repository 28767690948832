import { useQuery } from "@tanstack/react-query"
import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import SiteModel from "../../models/site"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useSite = (
  siteId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const { getOne } = useSpraypaintModel(SiteModel)

  const queryKey = useQueryKey(ApiQueryName.Site, "getOne", siteId)

  const queryResult = useQuery({
    queryKey,
    queryFn: () =>
      getOne(siteId, {
        include: [
          "address",
          "currency",
          "location",
          "nodes",
          "organization",
          "sink",
        ],
        extra: [
          "billingGroupNames",
          "buildingType",
          "departmentName",
          "loadAggregationPointId",
          "locationName",
          "numberOfFloors",
          "sqFt",
        ],
      }),
    enabled: !isNil(siteId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type Site = InferredQueryServiceValue<typeof useSite, ApiQueryName.Site>
