// @ts-strict-ignore
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"

import { ApiQueryName } from "../../../../models/api"
import EnergyAllocation from "../../../../models/energyAllocation"
import type Site from "../../../../models/site"
import {
  allocationStatsFromData,
  allocationsWithCombinedStat,
} from "./useSiteEnergyAllocationUtils"

export const useSiteEnergyAllocation = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  site: Site,
  options: { isDisabled?: boolean } = {}
) => {
  const queryKey = useQueryKey(
    ApiQueryName.SiteEnergyAllocation,
    "getOne",
    null,
    {
      startDate,
      endDate,
      site,
    }
  )

  const queryResult = useQuery({
    keepPreviousData: true,
    queryKey,
    queryFn: async () =>
      allocationsWithCombinedStat(
        EnergyAllocation.fetchInPages({
          clause: {
            startTime: startDate.format(),
            endTime: endDate.clone().endOf("day").format(),
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            siteId: site?.id,
          },
        }).then(({ data }) =>
          allocationStatsFromData({ data, site, startDate, endDate })
        )
      ),
    enabled:
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!startDate?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!endDate?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      startDate?.isSameOrBefore(endDate) &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!site &&
      !options.isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
