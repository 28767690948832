import { useOrganizationContext } from "@/contexts"
import { ApiQueryName } from "@/models/api"
import type { Operations } from "@/services/types"
import { createQueryKey } from "@/services/utils/createQueryKey"
import { useQueryKey } from "@/services/utils/useQueryKey"
import type { UseMutationOptions } from "@tanstack/react-query"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { AxiosError } from "axios"

import { createSitePlan } from "./create-site-plan"
import type { CreateSitePlanProps } from "./create-site-plan"

interface UseCreateSitePlanOptions {
  queryOptions?: Omit<
    UseMutationOptions<
      Operations["create_site_plan_api_v1_plans_post"]["responses"]["200"],
      AxiosError<
        Operations["create_site_plan_api_v1_plans_post"]["responses"]["422"]["content"]["application/json"]
      >,
      CreateSitePlanProps
    >,
    "mutationFn"
  >
}

/**
 * returns a mutation to save a plan id to a site
 * will automatically invalidate the queries for getting planned sites
 * and for getting a plan for a site
 */
export const useCreateSitePlan = (options?: UseCreateSitePlanOptions) => {
  const queryClient = useQueryClient()
  const { organization } = useOrganizationContext()
  const query = {
    organization_id: Number(organization?.id),
  }
  const plansQueryKey = useQueryKey(
    ApiQueryName.RecommendationsPlans,
    "getMany",
    null,
    {
      ...query,
    }
  )
  const mutation = useMutation(createSitePlan, {
    ...(options?.queryOptions ?? {}),
    onSuccess: (data, variables, context) => {
      if (options?.queryOptions?.onSuccess) {
        void options.queryOptions.onSuccess(data, variables, context)
      }

      void queryClient.invalidateQueries(plansQueryKey)
      void queryClient.invalidateQueries(
        createQueryKey(ApiQueryName.RecommendationSite, "getOne")
      )
    },
  })
  return mutation
}
