import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"
import type { WhereClause } from "spraypaint"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import { fetchSiteTotals } from "./useSiteTotalsUtils"

/**
 * The useSiteTotals service/hook returns the site totals.
 * @deprecated This service will be deleted when we remove the use flexibleHierarchy flag
 *
 * @returns The useSiteTotals service/hook return object
 * @example
 * const {
 *   siteTotals,
 *   isSiteTotalsLoading
 * } = useSiteTotals({department_id: 3}, { start: moment(), end: moment() }, {isDisabled: false})
 */
export const useSiteTotals = (
  where: WhereClause,
  dateRange: IRange<Moment>,
  options?: { isDisabled?: boolean }
) => {
  const rangeStart = dateRange?.start?.format("YYYY-MM-DD")
  const rangeEnd = dateRange?.end?.clone().endOf("month").format("YYYY-MM-DD")
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const whereClause = {
    ...where,
    start_date: rangeStart,
    end_date: rangeEnd,
  }
  const queryKey = useQueryKey(
    ApiQueryName.SiteTotals,
    "getMany",
    null,
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    whereClause
  )

  const queryResult = useQuery<
    Awaited<ReturnType<typeof fetchSiteTotals>> | undefined
  >({
    queryKey,
    queryFn: () => fetchSiteTotals(whereClause),
    enabled:
      !options?.isDisabled &&
      !!dateRange?.start?.isValid() &&
      !!dateRange?.end?.isValid(),
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
