import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import type { RouteObject } from "react-router-dom"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { LimitedAccessUserGuard } from "@/components/route/limited-access-user-guard"
import { RoleGuard } from "@/components/route/role-guard"
import { DataPath, RootPath } from "@/models/route"

import { DataIndexRoute } from "./pages/data-index-route"
import { DataQARoute } from "./pages/data-qa-route"
import { DataUploadRoute } from "./pages/data-upload-route"

export const dataRoutes: RouteObject[] = [
  {
    path: RootPath.Data,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}
      >
        <RoleGuard allowedRoles={["consultant_user"]}>
          <LimitedAccessUserGuard>
            <DataIndexRoute />
          </LimitedAccessUserGuard>
        </RoleGuard>
      </ErrorBoundary>
    ),
    children: [
      {
        path: DataPath.Upload,
        element: <DataUploadRoute />,
      },
      {
        path: DataPath.QA,
        element: <DataQARoute />,
      },
    ],
  },
]
