import { useQuery } from "@tanstack/react-query"
import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import { fetchMeterCounts } from "../../models/meter"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useMeterCounts = (
  meterId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const queryKey = useQueryKey(ApiQueryName.MeterCounts, "getOne", meterId)

  const queryResult = useQuery({
    queryKey,
    queryFn: () => fetchMeterCounts(meterId),
    enabled: !isNil(meterId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type MeterCounts = InferredQueryServiceValue<
  typeof useMeterCounts,
  ApiQueryName.MeterCounts
>
