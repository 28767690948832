import type { Context, Dispatch, SetStateAction } from "react"
import React, { createContext, useContext, useMemo, useState } from "react"

import type { IRange } from "@/models/range"
import type { Moment } from "moment"

interface ISelectedDateRange {
  selectedDateRange: IRange<Moment | undefined>
  setSelectedDateRange: Dispatch<SetStateAction<IRange<Moment>>>
}

// Mass lint disable
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const selectedDateRangeContext: Context<ISelectedDateRange> =
  createContext(undefined)

/**
 * @deprecated This provider is unnecessary. Refactor code to use url and React Query to remove it.
 */
export const SelectedDateRangeProvider = ({ children }) => {
  const [selectedDateRange, setSelectedDateRange] = useState<
    IRange<Moment | undefined>
  >({
    start: undefined,
    end: undefined,
  })

  const value: ISelectedDateRange = useMemo(
    () => ({
      selectedDateRange,
      setSelectedDateRange,
    }),
    [selectedDateRange]
  )

  return (
    <selectedDateRangeContext.Provider value={value}>
      {children}
    </selectedDateRangeContext.Provider>
  )
}

/**
 * @deprecated This hook is unnecessary. Refactor code to use url and React Query to remove it.
 */
export const useSelectedDateRange = () => {
  const context = useContext(selectedDateRangeContext)

  if (context === undefined) {
    throw new Error(
      "useSelectedDateRange must be used within a SelectedDateRangeProvider"
    )
  }

  return context
}
