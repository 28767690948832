import type { FC, MouseEvent } from "react"
import React, { useEffect, useRef, useState } from "react"

import { HStack } from "@/nzds/layout"

import { MoreVert } from "@mui/icons-material"
import { IconButton, Menu, Portal } from "@mui/material"
import {
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"

import type { DataGridToolbarProps } from "./data-grid-toolbar.types"

/**
 * Custom toolbar for the DataGrid component. Must be used in the toolbar slot of the DataGrid component.
 */
export const DataGridToolbar: FC<DataGridToolbarProps> = ({
  columnsButtonEl,
  endActions,
  id,
  moreMenuItems,
  setColumnsButtonEl,
  showQuickFilter = false,
  startActions,
}) => {
  const [moreMenuAnchor, setMoreMenuAnchor] = useState<null | HTMLElement>(null)
  const open = Boolean(moreMenuAnchor)

  const columnsButtonRef = useRef<HTMLButtonElement>(null)

  const handleMoreMenuButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMoreMenuAnchor(event.currentTarget)
  }

  const closeMoreMenu = (): void => {
    setMoreMenuAnchor(null)
  }

  const handleMoreMenuClick = (): void => {
    closeMoreMenu()
  }

  const handleMoreMenuClose = (): void => {
    closeMoreMenu()
  }

  useEffect(() => {
    if (!columnsButtonEl && columnsButtonRef.current) {
      // Set the columns button element so it can be triggered
      // by either the button or the MUI header menu item
      setColumnsButtonEl(columnsButtonRef.current)
    }
    // Must have ref.current as a dependency to trigger the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsButtonEl, columnsButtonRef.current, setColumnsButtonEl])

  return (
    <Portal
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container={() => document.getElementById(id ?? "datagrid-toolbar")!}
    >
      <HStack justifyContent="space-between" sx={{ flexGrow: 1 }}>
        <HStack spacing={2}>
          {startActions ?? null}
          <GridToolbarColumnsButton
            ref={columnsButtonRef}
            color="neutral"
            variant="outlined"
          />
          {endActions ?? null}
        </HStack>
        <HStack spacing={2}>
          {showQuickFilter ? (
            <GridToolbarQuickFilter size="small" sx={{ pb: 0, ml: "auto" }} />
          ) : null}
          {moreMenuItems ? (
            <>
              <IconButton
                aria-controls={open ? "more-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                aria-label="more actions"
                id="more-button"
                onClick={handleMoreMenuButtonClick}
                size="small"
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="more-menu"
                anchorEl={moreMenuAnchor}
                open={open}
                onClick={handleMoreMenuClick}
                onClose={handleMoreMenuClose}
                MenuListProps={{
                  "aria-labelledby": "more-button",
                }}
              >
                {moreMenuItems}
              </Menu>
            </>
          ) : null}
        </HStack>
      </HStack>
    </Portal>
  )
}
