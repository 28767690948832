// @ts-strict-ignore
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"

import { ApiQueryName } from "../../../../models/api"
import type { AdditionalMeterData } from "./useElectricityMeterUtils"
import { fetchElectricityMeter } from "./useElectricityMeterUtils"

/**
 * Fetches electricity meter data for a site
 *
 * @example
 * const { electricityMeter, isElectricityMeterLoading, isElectricityMeterError } =
 * useElectricityMeter("8")
 */
export const useElectricityMeter = (siteId: string) => {
  const queryKey = useQueryKey(ApiQueryName.ElectricityMeter, "getOne", siteId)
  const queryResult = useQuery<{
    meterCount: number
    parentMeterAndChildData: AdditionalMeterData[]
  }>({
    queryKey,
    queryFn: () => fetchElectricityMeter(siteId),
    enabled: !!siteId,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
