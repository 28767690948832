import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import { fetchScopeOneEmissions } from "./useScopeOneEmissionsUtils"

/**
 * This hook fetches and provides the scope one emissions for a given organization
 *
 * @param orgId - The id of the organization whose dates to get
 * @param dateRange - The date range to get the report dates for
 * @returns The scope one emissions of the top-most user accessible group for the given organization
 *
 * @example
 * ```tsx
 * import { useScopeOneEmissions } from '../services'
 *
 * const {
 *   scopeOneData,
 *   isScopeOneDataFetched,
 *   isScopeOneDataLoading
 * } = useScopeOneEmissions(orgId, { start: moment(), end: moment() })
 * ```
 */
export const useScopeOneEmissions = (
  orgId: string,
  dateRange: IRange<Moment>
) => {
  const startMonth = dateRange?.start?.format("M")
  const endMonth = dateRange?.end?.format("M")
  const year = dateRange?.start?.format("YYYY")
  const queryKey = useQueryKey(
    ApiQueryName.ScopeOneEmissions,
    "getMany",
    orgId,
    {
      startMonth,
      endMonth,
      year,
    }
  )

  const queryFn = () =>
    fetchScopeOneEmissions({
      organizationId: Number(orgId),
      startMonth,
      endMonth,
      year,
    })

  const queryResult = useQuery({
    queryKey,
    queryFn,
    enabled:
      !!orgId && !!dateRange?.start?.isValid() && !!dateRange?.end?.isValid(),
    keepPreviousData: true, // Prevent flash of previous data on date range change
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
