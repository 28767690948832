import { baseStringSchema, emailSchema, requiredMessage } from "@/schemas"
import z from "zod"

export const loginSchema = z
  .object({
    email: emailSchema,
    password: baseStringSchema.min(1, { message: requiredMessage }),
  })
  // Why partial? To allow the confirmPassword validation to display without having to complete the entire form.
  // See https://github.com/colinhacks/zod/issues/479#issuecomment-854822386
  .partial()

export type LoginSchema = z.infer<typeof loginSchema>
