// @ts-strict-ignore
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"
import type moment from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { LanguageRegion } from "../../../../models/i18n"
import { MeasurementSystem } from "../../../../models/measurementSystem"
import { getMeasurementSystem } from "../../../../utils"
import { fetchForecastDays } from "./useForecastDaysUtils"

/**
 *
 * @param startDate the start date used for filters.
 * @param endDate the end date used for filters.
 * @param locationId the location id used for filters.
 * @param languageRegion the language region used for filters.
 * @param options can be used to disable the query.
 * @returns An array of ForecastDay objects.
 *
 * @example
 * const {
 *  forecastDays,
 *  isForecastDaysLoading,
 *  isForecastDaysFetching,
 *  isForecastDaysFetched,
 * } = useForecastDays(
 *  {start: moment("2023-09-01"), end: moment("2023-09-01").endOf("month"), locationId: "4", languageRegion: "en-US"}
 * )
 */
export const useForecastDays = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  locationId: string,
  languageRegion: LanguageRegion,
  options?: { isDisabled?: boolean }
) => {
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const formattedStartDate = startDate?.format("YYYY-MM-DD")
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const formattedEndDate = endDate?.clone().endOf("day").format("YYYY-MM-DD")

  const queryKey = useQueryKey(ApiQueryName.ForecastDays, "getMany", null, {
    formattedStartDate,
    formattedEndDate,
    locationId,
    languageRegion,
  })

  const selectCelsius =
    getMeasurementSystem(languageRegion) === MeasurementSystem.SI

  const selectExtraClause = selectCelsius ? ["high_c", "low_c"] : []

  const whereClause = {
    day: {
      gte: formattedStartDate,
      lte: formattedEndDate,
    },
    location_id: locationId,
  }

  const queryResult = useQuery({
    keepPreviousData: true,
    queryKey,
    queryFn: async () => fetchForecastDays({ whereClause, selectExtraClause }),
    enabled:
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!startDate?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!endDate?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      startDate?.isBefore(endDate) &&
      !!locationId &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!languageRegion &&
      !options.isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
