import { QueryCache, QueryClient } from "@tanstack/react-query"

import type { EnvironmentName } from "../models/environment.types"

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      // Temporarily throw errors vs. silently swallowing them.
      // TODO: Set up error boundaries to handle this.
      throw error
    },
  }),
  defaultOptions: {
    queries: {
      retry:
        // Limit retries on dev to save time.
        process.env.REACT_APP_ENV ===
          ("acceptance" satisfies EnvironmentName) ||
        process.env.REACT_APP_ENV === ("fed" satisfies EnvironmentName) ||
        process.env.REACT_APP_ENV === ("production" satisfies EnvironmentName)
          ? 3
          : 1,
      cacheTime: 1000 * 60 * 5, // milliseconds * seconds * minutes
      staleTime: 1000 * 60 * 5, // milliseconds * seconds * minutes
    },
  },
})
