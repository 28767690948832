import { useQuery } from "@tanstack/react-query"

import { ApiQueryName } from "../../models/api"
import CurrencyModel from "../../models/currency"
import type { ServiceOptions } from "../types"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useCurrencies = ({
  disabled: isDisabled,
}: ServiceOptions<"getMany"> = {}) => {
  const { getMany } = useSpraypaintModel(CurrencyModel)

  const queryKey = useQueryKey(ApiQueryName.Currencies, "getMany", null)

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const result = await getMany()
      return result as Currency[] // TODO: Fix this typing problem.
    },
    enabled: !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type Currency = ModelAttributes<CurrencyModel>
