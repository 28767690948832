import { Attr, BelongsTo, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"
import BillingGroup from "./billingGroup"
import { fetchMeterMeterHourCount } from "./meterHour"
// Cannot yet be imported as a type
// because the class must be imported somewhere for it to be added to the modelRegistry
// See spraypaint.js/src/decorators.js -> modelFactory() -> ModelClass.registerType()
import MeterSourceType from "./meterSourceType"
import Site from "./site"

export interface IMeterCounts {
  meterHours: number
}
@Model()
export class Meter extends ApplicationRecord {
  public static jsonapiType = "meters"

  // Cannot yet be written as a string (e.g. @BelongsTo("meter_source_types"))
  // because the class must be imported somewhere for it to be added to the modelRegistry
  @BelongsTo(MeterSourceType) meterSourceType: MeterSourceType

  @BelongsTo("sites") public site: Site

  @BelongsTo("billing_groups") public billingGroup: BillingGroup

  @Attr() public readonly name: string

  @Attr() public readonly meterCode: string

  @Attr() public readonly meterStatus: string

  @Attr() public readonly meterType: string

  @Attr() public readonly meterProvider: string

  @Attr() public readonly energyAllocatorId: string

  @Attr() public readonly energyAllocatorName: string

  @Attr() public readonly billingGroupId: number

  @Attr() public readonly siteId: number

  @Attr() public readonly dateCreated: string

  @Attr() public readonly dateUpdated: string

  @Attr() public readonly fullMeter: boolean

  @Attr() public readonly probeMeter: boolean

  @Attr() public readonly isOnSiteGeneration: boolean

  @Attr() public readonly isNetMeter: boolean

  @Attr() public readonly isChildMeter: boolean

  @Attr() public readonly startDate: string

  @Attr() public readonly endDate: string

  @Attr() public childMeterNamesAndIds: {
    id: typeof Meter.prototype.id
    meterCode: typeof Meter.prototype.meterCode
    name: typeof Meter.prototype.name
  }[]
}

/**
 * Fetches the meter count for an billing group
 *
 * @param billingGroupId - The billing group id
 * @returns - The meter count
 * @example
 * fetchBillingGroupMeterCount("15")
 */
export const fetchBillingGroupMeterCount = async (
  billingGroupId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await Meter.where({
    billing_group_id: billingGroupId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}

/**
 * Fetches the meter count for an organization
 *
 * @param orgId - The organization id
 * @returns - The meter count
 * @example
 * fetchOrganizationMeterCount("15")
 */
export const fetchOrganizationMeterCount = async (
  orgId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await Meter.where({
    organization_id: orgId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}

export const fetchMeterCounts = async (
  meterId: string
): Promise<IMeterCounts> => {
  const meterHours = await fetchMeterMeterHourCount(meterId)

  return { meterHours }
}

export default Meter
