import { useQuery } from "@tanstack/react-query"
import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import { fetchSiteCounts } from "../../models/site"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useSiteCounts = (
  siteId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const queryKey = useQueryKey(ApiQueryName.SiteCounts, "getOne", siteId)

  const queryResult = useQuery({
    queryKey,
    queryFn: () => fetchSiteCounts(siteId),
    enabled: !isNil(siteId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type SiteCounts = InferredQueryServiceValue<
  typeof useSiteCounts,
  ApiQueryName.SiteCounts
>
