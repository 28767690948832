import type { FC } from "react"
import React from "react"
import { useFieldArray, useWatch } from "react-hook-form"

import { HStack } from "@/nzds/layout"

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded"
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded"
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded"
import { Button, IconButton, Stack, Typography } from "@mui/material"

import { snakeToTitleCase } from "../../utils/snakeToTitleCase"
import { generateShchemaDefaultValues } from "./generate-schema-default-values"
import { SchemaField } from "./schema-field"
import type { SchemaFieldArrayProps } from "./schema-field-array.types"

export const SchemaFieldArray: FC<SchemaFieldArrayProps> = ({
  name,
  schema,
}) => {
  const { fields, append, remove, insert } = useFieldArray({
    name,
  })
  const width = { width: "calc(100% - (1rem * 1 / 2));" }
  const lastPathString = name.split(".").pop()
  const label = schema.title ?? snakeToTitleCase(lastPathString)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const values = useWatch({ name })

  const emptyObjectDefault = generateShchemaDefaultValues(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (schema.items as unknown as any).properties
  )
  return (
    <Stack gap={2} sx={width}>
      <Typography component="h3" fontWeight="fontWeightBold" variant="body1">
        {label}
      </Typography>
      {fields.map((field, index) => (
        <HStack key={field.id} gap={2}>
          {typeof schema.items !== "boolean" &&
          schema.items &&
          !Array.isArray(schema.items) ? (
            <SchemaField name={`${name}.${index}`} schema={schema.items} />
          ) : null}
          <HStack alignItems="center" gap={2} justifyContent="flex-end">
            <IconButton
              aria-label="Duplicate"
              color="neutral"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                insert(index + 1, values[index])
              }}
              size="small"
              sx={{ alignSelf: "flex-end" }}
            >
              <ContentCopyRoundedIcon />
            </IconButton>
            <IconButton
              aria-label="Remove"
              color="neutral"
              onClick={() => {
                remove(index)
              }}
              size="small"
              sx={{ alignSelf: "flex-end" }}
            >
              <DeleteOutlineRoundedIcon />
            </IconButton>
          </HStack>
        </HStack>
      ))}
      <Button
        color="neutral"
        onClick={() => {
          append({ ...emptyObjectDefault })
        }}
        size="small"
        startIcon={<AddCircleOutlineRoundedIcon />}
        sx={{ alignSelf: "flex-start" }}
        variant="outlined"
      >
        Add {label}
      </Button>
    </Stack>
  )
}
