// @ts-strict-ignore
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"
import type moment from "moment"

import { ApiQueryName } from "../../../../models/api"
import { ElectricityType } from "../../../../models/energyAllocation"
import type Site from "../../../../models/site"
import { fetchBundledEmissionsAndEnergyVolume } from "./useBundledEmissionsAndEnergyVolumeUtils"

interface IUseBundledEmissionsAndEnergyVolume {
  endDate: moment.Moment
  site: Site
  startDate: moment.Moment
}

export const useBundledEmissionsAndEnergyVolume = ({
  site,
  startDate,
  endDate,
}: IUseBundledEmissionsAndEnergyVolume) => {
  const queryKey = useQueryKey(
    ApiQueryName.BundledEmissionsAndEnergyVolume,
    "getOne",
    null,
    {
      site,
      startDate,
      endDate,
    }
  )

  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison, @typescript-eslint/no-unnecessary-condition
  const isBundled = site?.electricityType === ElectricityType.Bundled

  const queryResult = useQuery({
    keepPreviousData: true,
    queryKey,
    queryFn: async () =>
      fetchBundledEmissionsAndEnergyVolume({
        site,
        yearStart: startDate.format(),
        dayStart: endDate.format(),
      }),
    enabled:
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!site &&
      isBundled &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!startDate &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!endDate &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!startDate?.isBefore(endDate),
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
