import { ApiQueryName } from "@/models/api"
import type { IRange } from "@/models/range"
import { useQuery } from "@tanstack/react-query"
import type { UseQueryResult } from "@tanstack/react-query"
import type { Moment } from "moment"
import moment from "moment"

import { useRenameQueryResult } from "../utils"
import { useQueryKey } from "../utils/useQueryKey"
import { fetchAvailableReportDates } from "./useAvailableReportDatesUtils"

const defaultAvailableReportDates: IRange<Moment> = {
  start: moment(null),
  end: moment(null),
}

/**
 * The useAvailableReportDates service/hook returns a range of the available report dates
 * for an organization
 *
 * @returns The available report dates
 * @example
 * const {
 *   availableReportDatesData,
 *   availableReportDatesIsFetched,
 *   availableReportDatesIsLoading
 * } = useAvailableReportDates("8")
 */
export const useAvailableReportDates = (orgId: string) => {
  const queryKey = useQueryKey(
    ApiQueryName.AvailableReportDates,
    "getOne",
    orgId,
    {
      ignoreCurrency: true,
    }
  )

  const queryResult = useQuery<IRange<Moment>>({
    enabled: !!orgId,
    queryKey,
    queryFn: () => fetchAvailableReportDates(orgId),
  })

  const modifiedQueryResult = {
    ...queryResult,
    data: queryResult.data ?? defaultAvailableReportDates,
  } as UseQueryResult<IRange<Moment>>

  return useRenameQueryResult(
    modifiedQueryResult,
    ApiQueryName.AvailableReportDates
  )
}
