import { useOrganizationContext } from "@/contexts"
import { useQueryKey } from "@/services/utils/useQueryKey"
import type { UseQueryOptions } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"

import { ApiQueryName } from "../../../../models/api"
import { getPotentialSites } from "./get-potential-sites"

interface UseGetPotentialSitesOptions {
  queryOptions?: Omit<UseQueryOptions, "queryKey" | "queryFn">
}

/**
 * Returns a list of potential sites for a given organization.
 * Relies on organization context
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetPotentialSites({
 *   queryOptions // react query options
 * });
 */
export const useGetPotentialSites = (options?: UseGetPotentialSitesOptions) => {
  const { organization } = useOrganizationContext()
  const hasOrg = Boolean(organization)

  const params = {
    query: { organization_id: Number(organization?.id) },
  }

  const queryKey = useQueryKey(
    ApiQueryName.RecommendationsPotentialSites,
    "getMany",
    null,
    { ...params }
  )

  let queryParams = {
    queryKey,
    queryFn: () => getPotentialSites(params),
    enabled: hasOrg,
  }

  if (options?.queryOptions) {
    queryParams = {
      ...options.queryOptions,
      ...queryParams,
    }
  }

  const query = useQuery(queryParams)

  return query
}
