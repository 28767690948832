import { useMemo } from "react"

import type { Schemas } from "@/services/types"
import type { InfiniteData } from "@tanstack/react-query"

import type {
  SiteExplorerPaginatedData,
  SiteRowData,
} from "../../../services/use-get-site-explorer"

interface UseComputedSiteRowsOptions {
  data?: InfiniteData<SiteExplorerPaginatedData>
  sitesWithPlans?: Record<string, Schemas["PlanResponse"]>
}

export const useComputedSiteRows = ({
  data,
  sitesWithPlans,
}: UseComputedSiteRowsOptions): SiteRowData[] => {
  const computedRows = useMemo(() => {
    const pagesMap: Record<string, SiteRowData[]> = {}

    if (data && data.pages.length > 0) {
      for (const page of data.pages) {
        const rows: SiteRowData[] = []
        page.items.forEach((item, index) => {
          rows.push({
            ...item.attributes,
            id: item.id,
            planId: sitesWithPlans?.[item.attributes.site_id]?.id,
            page: page.page,
            pageSize: page.size,
            pageIndex: index,
          })
        })
        if (page.page) {
          pagesMap[page.page] = rows
        } else {
          pagesMap[1] = rows
        }
      }

      // return the rows in order
      const orderedRows: SiteRowData[] = []

      for (const key of Object.keys(pagesMap).sort((a, b) =>
        a.localeCompare(b)
      )) {
        orderedRows.push(...pagesMap[key])
      }

      return orderedRows
    }

    return []
  }, [data, sitesWithPlans])

  return computedRows
}
