// @ts-strict-ignore
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"

import { ApiQueryName } from "../../../../models/api"
import type Site from "../../../../models/site"
import { fetchSiteFinancialSummaries } from "./useSiteFinancialSummariesUtils"

/**
 * A hook that retrieves financial summaries for a given site within a specified date range.
 *
 * @returns An object containing financial summaries, loading and fetched states.
 *
 * @example
 * const {
 *   financialSummaries,
 *   isFinancialSummariesFetched,
 *   isFinancialSummariesLoading
 * } = useFinancialSummaries(
 *   "123",
 *   moment(),
 *   moment(),
 *   1,
 *   false
 * );
 */
export const useSiteFinancialSummaries = (
  site: Site,
  searchStartDate: moment.Moment,
  searchEndDate: moment.Moment,
  siteMetersCount: number,
  siteIsAmi: boolean,
  options?: { isDisabled: boolean }
) => {
  const queryKey = useQueryKey(
    ApiQueryName.SiteFinancialSummaries,
    "getMany",
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    site?.id,
    {
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      start: searchStartDate?.format("YYYY-MM-DD"),
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      end: searchEndDate?.format("YYYY-MM-DD"),
    }
  )
  const queryResult = useQuery({
    queryKey,
    queryFn: () =>
      fetchSiteFinancialSummaries({
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        siteId: site?.id,
        searchStartDate,
        searchEndDate,
      }),
    enabled:
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!site &&
      siteIsAmi &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!searchStartDate?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!searchEndDate?.isValid() &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!site?.isBundledEnergyCustomer() &&
      !options?.isDisabled &&
      !Number.isNaN(siteMetersCount),
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
