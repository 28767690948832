import React, { useCallback, useState } from "react"
import type { ChangeEvent, FC } from "react"

import { HStack } from "@/nzds/layout"
import { filesize } from "filesize"

import CloseIconRounded from "@mui/icons-material/CloseRounded"
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material"
import { visuallyHidden } from "@mui/utils"

import type { UploadFileProps } from "./upload-file.types"

export const UploadFile: FC<UploadFileProps> = ({
  fileType,
  isLoading,
  onChange,
}) => {
  const [file, setFile] = useState<File | undefined>(undefined)

  const setFileAndNotify = useCallback(
    (newFile: File | undefined): void => {
      setFile(newFile)
      onChange(newFile)
    },
    [onChange, setFile]
  )

  const fileSize: string = file
    ? filesize(file.size, { standard: "jedec" })
    : ""

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const fileInput = event.target.files?.[0]

      if (fileInput) {
        setFileAndNotify(fileInput)
      }
    },
    [setFileAndNotify]
  )

  const handleRemoveFileClick = useCallback((): void => {
    setFileAndNotify(undefined)
  }, [setFileAndNotify])

  return (
    <Card variant="contained">
      <CardContent>
        {file ? (
          <HStack alignItems="center" gap={2} justifyContent="space-between">
            <HStack
              alignItems="center"
              gap={2}
              height="40px"
              sx={{ minWidth: 0 }}
            >
              <Typography
                fontWeight="fontWeightBold"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                variant="body1"
              >
                {file.name}
              </Typography>
              <Typography sx={{ whiteSpace: "nowrap" }} variant="body2">
                {fileSize}
              </Typography>
            </HStack>
            <IconButton
              aria-label={`Remove file ${file.name}`}
              disabled={isLoading}
              onClick={handleRemoveFileClick}
              size="small"
            >
              <CloseIconRounded />
            </IconButton>
          </HStack>
        ) : (
          <>
            <Box
              accept={`.${fileType}`}
              component="input"
              id="contained-button-file"
              onChange={handleFileChange}
              sx={visuallyHidden}
              type="file"
            />
            <HStack alignItems="center" gap={2}>
              <label htmlFor="contained-button-file">
                <Button component="span" variant="contained">
                  Choose file
                </Button>
              </label>
              <Typography variant="body2">No file selected</Typography>
            </HStack>
          </>
        )}
      </CardContent>
    </Card>
  )
}
