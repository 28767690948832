import React, { useCallback, useEffect } from "react"

import { HStack } from "@/nzds/layout"

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Grid, Stack } from "@mui/material"

import type { BillingGroup as BillingGroupModel } from "../../../../../../services"
import {
  useBillingGroup,
  useUpdateBillingGroup,
} from "../../../../../../services"
import {
  TamForm,
  TamResetButton,
  TamSubmitButton,
  useTamForm,
} from "../../../tamForm"
import { TamFormTextField } from "../../../tamForm/formFields"
import type { BillingGroup } from "../../../tamForm/schemas/billingGroup"
import { schema } from "../../../tamForm/schemas/billingGroup"
import OrgViewDetailFooter from "../orgViewDetail/orgViewDetailFooter/orgViewDetailFooter"

interface OrgViewBillingGroupDetailProps {
  billingGroupId: BillingGroupModel["id"]
  linkSection: React.JSX.Element
}

const OrgViewBillingGroupDetail = ({
  billingGroupId,
  linkSection,
}: OrgViewBillingGroupDetailProps) => {
  const { billingGroup, isBillingGroupLoading } =
    useBillingGroup(billingGroupId)

  const {
    updateOneBillingGroup,
    isUpdateOneBillingGroupLoading,
    isUpdateOneBillingGroupSuccess,
  } = useUpdateBillingGroup()

  const handleFormSubmit = useCallback(
    (newAttributes) => {
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      updateOneBillingGroup(billingGroupId, newAttributes)
    },
    [billingGroupId, updateOneBillingGroup]
  )

  const form = useTamForm({ schema, values: billingGroup })

  useEffect(() => {
    if (isUpdateOneBillingGroupSuccess) {
      form.reset({}, { keepValues: true })
    }
  }, [form, isUpdateOneBillingGroupSuccess])

  if (isBillingGroupLoading) {
    return null
  }

  return (
    <TamForm form={form} name="billingGroup" onSubmit={handleFormSubmit}>
      <HStack
        alignItems="flex-start"
        flexGrow={1}
        gap={0.5}
        sx={{
          overflowY: "auto",
          py: 1,
          pr: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<BillingGroup>
                dataE2E="billing-group-name-input"
                label="Billing Group Name"
                name="groupName"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<BillingGroup>
                dataE2E="billing-group-id-input"
                disabled
                label="ID"
                name="id"
                readOnly
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<BillingGroup>
                dataE2E="billing-group-alias-input"
                label="Billing Group Alias"
                name="alias"
              />
            </Grid>
          </Grid>
        </Grid>
      </HStack>
      <OrgViewDetailFooter linkSection={linkSection}>
        <TamResetButton dataE2E="billing-group-reset-button" />
        <TamSubmitButton
          dataE2E="billing-group-submit-button"
          submitting={isUpdateOneBillingGroupLoading}
        />
      </OrgViewDetailFooter>
    </TamForm>
  )
}

export default OrgViewBillingGroupDetail
