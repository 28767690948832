import { useMemo } from "react"

import { useQueryClient } from "@tanstack/react-query"

import type { ServiceQueryKey } from "../types"

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient()
  return useMemo(
    () => ({
      invalidateQueries: (queryKeys: ServiceQueryKey[]) => {
        queryKeys.forEach(([name, action, key]) => {
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          queryClient.invalidateQueries({
            predicate: ({ queryKey: [n, a, k] }) =>
              name === n &&
              (action === null || action === a) &&
              (key === null || key === k),
          })
        })
      },
    }),
    [queryClient]
  )
}
