// @ts-strict-ignore
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import { useQuery } from "@tanstack/react-query"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import type { MeterWithEnergyAllocator } from "../../models/site"
import { fetchSiteProRatedUsageMeters } from "./useSiteProRatedUsageMetersUtils"

export const useSiteProRatedUsageMeters = (
  metersWithEnergyAllocator: MeterWithEnergyAllocator[],
  dateRange: IRange<Moment>,
  hasAmiMeter: boolean,
  isBundled: boolean
) => {
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const startDate: Moment | undefined = dateRange?.start
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const endDate: Moment | undefined = dateRange?.end
  const queryKey = useQueryKey(
    ApiQueryName.SiteProRatedUsageMeters,
    "getMany",
    null,
    {
      startDate: startDate?.format("YYYY-MM-DD"),
      endDate: endDate?.format("YYYY-MM-DD"),
      metersWithEnergyAllocator,
    }
  )

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const meters = metersWithEnergyAllocator.map(
        async ({
          id,
          name,
          meterCode,
          meterType,
          childMeterNamesAndIds,
          energyAllocator,
        }) => {
          const result = fetchSiteProRatedUsageMeters({
            meterId: id,
            meterCode,
            meterName: name,
            meterType,
            energyAllocator,
            childMeterNamesAndIds,
            searchStartDate: startDate,
            searchEndDate: endDate,
          })
          return result
        }
      )
      return Promise.all(meters)
    },
    enabled:
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!metersWithEnergyAllocator &&
      hasAmiMeter &&
      isBundled &&
      !!startDate?.isValid() &&
      !!endDate?.isValid(),
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
