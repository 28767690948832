import { ApiQueryName } from "@/models/api"
import { useQuery } from "@tanstack/react-query"
import type { UseQueryOptions } from "@tanstack/react-query"

import { createQueryKey } from "../../utils/createQueryKey"
import type { SigmaAccessToken } from "../sigma.types"
import { refreshSigmaAccessToken } from "./useSigmaAccessTokenUtils"

/**
 * Service that refreshes a sigma access token
 */
export const useSigmaRefreshAccessToken = (
  refreshToken: string,
  queryOptions: UseQueryOptions<SigmaAccessToken> = {}
) => {
  const queryKey = createQueryKey(
    ApiQueryName.SigmaRefreshAccessToken,
    "getOne"
  )

  const queryResult = useQuery<SigmaAccessToken>({
    ...queryOptions,
    cacheTime: Infinity,
    queryKey,
    queryFn: async (): Promise<SigmaAccessToken> =>
      refreshSigmaAccessToken(refreshToken),
    staleTime: Infinity,
  })

  return queryResult
}
