import { State } from "country-state-city"

interface StateOptions {
  label: string
  value: string
}

/**
 * Returns a list of states for a given country code as options for a select field, where
 * the label is the state name and the value is the state iso code.
 *
 * @example
 * import { useStateOptions } from "@/nzds/utils/useStateOptions"
 * const stateOptions = useStateOptions('US')
 *
 */
export const useStateOptions = (countryCode?: string): StateOptions[] => {
  if (!countryCode) {
    return []
  }
  const states = State.getStatesOfCountry(countryCode).map(
    ({ name, isoCode }) => ({
      label: name,
      value: isoCode,
    })
  )
  const countryIsUSOrCA = ["US", "CA"].includes(countryCode)
  // Filters out minor territories which conveniently have an ISO code of 3 or more characters.
  if (countryIsUSOrCA) {
    return states.filter(({ value }) => value.length === 2)
  }
  return states
}
