import React from "react"

import { HStack } from "@/nzds/layout"

import { Box, Typography } from "@mui/material"

import { pxToRem } from "../../../../utils/unit"
import type { StackedAreaGraphTooltipRowProps } from "./stacked-area-graph-tooltip.types"

const StackedAreaGraphTooltipRow = ({
  rowItem,
}: StackedAreaGraphTooltipRowProps): JSX.Element => (
  <HStack
    key={rowItem.label}
    alignItems="center"
    justifyContent="space-between"
  >
    <HStack alignItems="baseline" whiteSpace="normal">
      {rowItem.color && (
        <Box
          borderRadius={pxToRem(2)}
          flexShrink={0}
          height={pxToRem(8)}
          mr={pxToRem(2)}
          sx={{
            backgroundColor: rowItem.color,
          }}
          width={pxToRem(8)}
        />
      )}
      <Typography variant="dataLabel">{rowItem.label}</Typography>
    </HStack>
    <Typography variant="dataLabel">
      {rowItem.decimalComponent?.({ value: rowItem.value })}
    </Typography>
  </HStack>
)

export default StackedAreaGraphTooltipRow
